import { ReactElement } from 'react';
import ReactDOM, { Root } from 'react-dom/client';
import { ModalProps } from '@components/service/modal/modal.tsx';

import Modal from '@components/service/modal/modal.tsx';

class ModalService {
	private activeModal: ReactElement | undefined;
	private modalContainer: Root | undefined;

	constructor() {
		this.setModalContainer();
	}

	private setModalContainer() {
		if (this.modalContainer) {
			return;
		}

		const modalWrapper = document.getElementById('modal-container');
		if (modalWrapper) {
			this.modalContainer = ReactDOM.createRoot(modalWrapper);
		}
	}

	open(props: ModalProps) {
		this.setModalContainer();

		if (this.activeModal) {
			return;
		}

		const modal = (
			<Modal content={props.content} overflow={props.overflow}></Modal>
		);

		if (!this.modalContainer) {
			return;
		}
		this.modalContainer.render(modal);
		this.activeModal = modal;
	}

	close() {
		if (!this.modalContainer) {
			return;
		}

		this.modalContainer.render(<></>);
		this.activeModal = undefined;
	}
}

const modal = new ModalService();
export default modal;
