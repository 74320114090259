import { NavLink, useNavigate } from 'react-router-dom';
import authStore from '@store/auth-store.ts';
import { observer } from 'mobx-react-lite';
import Icon from '@components/core/icon/icon.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import ExpansionButton from '@components/core/expansion-button/expansion-button.tsx';
import viewStore from '@store/view-store.ts';
import navSidebarStore from '@store/nav-sidebar-store.ts';
import ClassString from '@utils/class-string.ts';
import SlideToggle from '@components/core/slide-toggle/slide-toggle.tsx';
import themeStore from '@store/theme-store.ts';

const Nav = observer(function Nav() {
	const navigate = useNavigate();

	return (
		<div
			className={
				'fixed top-0 z-nav w-screen lg:w-nav drop-shadow bg-white dark:bg-gray-600'
			}
		>
			<div
				className={ClassString({
					static: 'h-nav flex justify-center items-center px-4 lg:px-6 visible',
					dynamic: {
						'hidden h-0': !authStore.isLoggedIn,
						'transition-height': authStore.isLoggedIn,
					},
				})}
			>
				<div
					className={
						'max-w-[80%] flex items-center flex-1 whitespace-nowrap'
					}
				>
					{viewStore.viewInfo && (
						<div className={'flex items-center w-full'}>
							{viewStore.viewInfo.icon && (
								<div
									className={
										'mr-2 flex justify-center items-center'
									}
								>
									<Icon
										icon={viewStore.viewInfo.icon}
										size={
											viewStore.viewInfo.iconSize ??
											'2.5rem'
										}
									/>
								</div>
							)}
							<div
								className={
									'flex justify-center items-center lg:text-xl'
								}
							>
								{viewStore.viewInfo.breadCrumbs?.map(
									(breadcrumb, index) => {
										return (
											<div
												className={
													'flex justify-center items-center'
												}
												key={`nav-bc-${index}`}
											>
												{!!breadcrumb.route && (
													<NavLink
														to={breadcrumb.route}
														draggable="false"
														className={
															'mr-2 lg:mr-3 no-underline text-inherit'
														}
													>
														{breadcrumb.title}
													</NavLink>
												)}
												{!breadcrumb.route && (
													<div className={'lg:mr-3'}>
														{breadcrumb.title}
													</div>
												)}
												<div
													className={
														'mr-2 lg:mr-3 mt-0.5 flex justify-center items-center'
													}
												>
													<Icon
														icon={
															IconEnum.BREADCRUMB_ARROW
														}
														size={'1.2rem'}
													/>
												</div>
											</div>
										);
									}
								)}

								<div className={'truncate'}>
									{viewStore.viewInfo.title}
								</div>
							</div>
						</div>
					)}
				</div>

				<div
					className={
						'flex flex-1 justify-end items-center select-none'
					}
				>
					<SlideToggle
						initialState={themeStore.darkTheme}
						onToggle={(on) => {
							themeStore.setTheme(on ? 'dark' : 'light');
						}}
						icons={{
							off: IconEnum.LIGHT_MODE,
							on: IconEnum.DARK_MODE,
						}}
						className={'mr-6 hidden lg:flex'}
					/>

					<ExpansionButton
						className={'hidden lg:flex'}
						title={`${authStore.user?.first_name ?? ''} ${
							authStore.user?.last_name ?? ''
						}`}
						icon={{
							icon: IconEnum.ACCOUNT,
							placement: 'right',
							size: '28px',
						}}
						subMenuButtons={[
							{
								title: 'Log Out',
								onClick: async () => {
									await authStore.logOut();
									navigate('/login');
								},
							},
						]}
					/>

					<Icon
						icon={
							navSidebarStore.open
								? IconEnum.CLEAR
								: IconEnum.MENU
						}
						className={
							'p-1 flex lg:hidden text-5xl ml-4 rounded-full cursor-pointer hover:bg-blue-500 hover:text-white'
						}
						onClick={() => navSidebarStore.toggleOpen()}
						size={'2.8rem'}
					/>
				</div>
			</div>
		</div>
	);
});

export default Nav;
