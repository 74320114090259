import { observer } from 'mobx-react-lite';
import View from '@components/view/view.tsx';
import ViewPanel from '@components/view/view-panel.tsx';
import UsersTable from '@views/users/view-components/users-table.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import userStore from '@store/user-store.ts';
import ViewPanelSizeEnum from '@components/view/view-panel-size-enum.ts';
import QuickData from '@components/data/quick-data/quick-data.tsx';
import { User } from '@/schemas/user-schema.ts';

const activeLast24Hours = (users: User[]) => {
	const oneDayAgo = new Date();
	oneDayAgo.setHours(new Date().getHours() - 24);
	return users.filter((user) => {
		return new Date(user.last_active) > oneDayAgo;
	});
};

const activeLast30Days = (users: User[]) => {
	const thirtyDaysAgo = new Date();
	thirtyDaysAgo.setDate(new Date().getDate() - 30);
	return users.filter((user) => {
		return new Date(user.last_active) > thirtyDaysAgo;
	});
};

const Users = observer(function Users() {
	const users = userStore.users;

	return (
		<View viewInfo={{ title: 'Users', icon: IconEnum.GROUP }} hideHeader>
			<ViewPanel options={{ size: ViewPanelSizeEnum.THIRD }}>
				<QuickData data={users.length} title={'Users'} />
			</ViewPanel>

			<ViewPanel options={{ size: ViewPanelSizeEnum.THIRD }}>
				<QuickData
					data={activeLast24Hours(users).length}
					title={'Active Last 24 Hours'}
				/>
			</ViewPanel>

			<ViewPanel options={{ size: ViewPanelSizeEnum.THIRD }}>
				<QuickData
					data={activeLast30Days(users).length}
					title={'Active Last 30 Days'}
				/>
			</ViewPanel>

			<ViewPanel options={{ noPadding: true, noOutline: true }}>
				<UsersTable users={users} />
			</ViewPanel>
		</View>
	);
});

export default Users;
