export enum Platform {
	WINDOWS = 'windows',
	MAC = 'mac',
}

function GetPlatform() {
	const platformString =
		// navigator.platform is deprecated but only Chrome has adopted navigator.userAgentData so far.
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		(navigator.userAgentData?.platform ||
			navigator.platform ||
			navigator.userAgent) as string;

	// Default to Windows if platform cannot be determined
	if (!platformString) {
		return Platform.WINDOWS;
	}

	if (platformString.toLowerCase().indexOf('mac') > -1) {
		return Platform.MAC;
	}

	return Platform.WINDOWS;
}

export default GetPlatform;
