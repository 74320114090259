import { makeAutoObservable, runInAction } from 'mobx';
import httpFetch from '@services/http-fetch-service.ts';
import {
	User,
	UserPermission,
	UserRole,
	UserSchema,
} from '@/schemas/user-schema.ts';
import * as Sentry from '@sentry/react';
import { CredentialResponse } from 'google-one-tap';
import postHogService from '@services/posthog-service.ts';

const baseUrl = import.meta.env.VITE_SERVER_URL as string;

const sessionUrl = `${baseUrl}/api/session`;

enum AUTH_STATE {
	PENDING = 'pending',
	AUTHENTICATED = 'authenticated',
	NOT_AUTHENTICATED = 'not_authenticated',
}

class AuthStore {
	private _user: User | null = null;
	private _isAuthenticated: AUTH_STATE = AUTH_STATE.PENDING;
	private _recentlyLoggedOut = false;

	constructor() {
		void this.restoreSession();
		makeAutoObservable(this);
	}

	async restoreSession() {
		try {
			const response = await httpFetch.GET(sessionUrl, true, true);
			if (response.ok) {
				await runInAction(async () => {
					this._isAuthenticated = AUTH_STATE.AUTHENTICATED;
					this._user = UserSchema.parse(await response.json());
					Sentry.setUser({ id: this._user.id });
					void postHogService.identify(this._user);
				});
			} else {
				runInAction(() => {
					this._isAuthenticated = AUTH_STATE.NOT_AUTHENTICATED;
					this._user = null;
				});
			}
		} catch (_) {
			runInAction(() => {
				this._isAuthenticated = AUTH_STATE.NOT_AUTHENTICATED;
				this._user = null;
			});
		}
	}

	async login(email: string, password: string) {
		const response = await httpFetch.POST(sessionUrl, {
			email,
			password,
		});

		if (response.ok) {
			await runInAction(async () => {
				this._recentlyLoggedOut = false;
				this._isAuthenticated = AUTH_STATE.AUTHENTICATED;
				await this.restoreSession();
			});
		}
	}

	async googleLogin(credentialResponse: CredentialResponse) {
		const response = await httpFetch.POST(
			`${baseUrl}/api/auth/google/token`,
			{
				id_token: credentialResponse.credential,
			}
		);

		if (response.ok) {
			await runInAction(async () => {
				this._isAuthenticated = AUTH_STATE.AUTHENTICATED;
				await this.restoreSession();
			});
		}
	}

	async logOut() {
		const response = await httpFetch.DELETE(sessionUrl);

		if (response.ok) {
			runInAction(() => {
				this._recentlyLoggedOut = true;
				this._isAuthenticated = AUTH_STATE.NOT_AUTHENTICATED;
				postHogService.reset();
			});
		}
	}

	get isLoggedIn() {
		return this._isAuthenticated === AUTH_STATE.AUTHENTICATED;
	}

	async isAuthenticated() {
		return await this.checkIfAuthenticated();
	}

	get user() {
		return this._user;
	}

	get recentlyLoggedOut() {
		return this._recentlyLoggedOut;
	}

	havePermission(permission: UserPermission) {
		return this._user?.permissions?.includes(permission);
	}

	haveUserRole(roles: UserRole | UserRole[]) {
		if (!this._user) {
			return false;
		}

		if (Array.isArray(roles)) {
			return roles.includes(this._user.role);
		} else {
			return roles === this._user.role;
		}
	}

	private async checkIfAuthenticated(): Promise<boolean> {
		switch (this._isAuthenticated) {
			case AUTH_STATE.PENDING:
				await new Promise((resolve) => setTimeout(resolve, 250));
				return this.checkIfAuthenticated();
			case AUTH_STATE.AUTHENTICATED:
				return true;
			case AUTH_STATE.NOT_AUTHENTICATED:
				return false;
		}
	}
}

const authStore = new AuthStore();
export default authStore;
