async function DownloadFileFromResponse(
	response: Response,
	fileName: string,
	fileType: string
) {
	const blob = await response.blob();
	const fileUrl = URL.createObjectURL(blob);
	const downloadLink = document.createElement('a');
	downloadLink.href = fileUrl;
	downloadLink.download = `${fileName}.${fileType}`;
	document.body.appendChild(downloadLink);
	downloadLink.click();
	document.body.removeChild(downloadLink);
	URL.revokeObjectURL(fileUrl);
}

export default DownloadFileFromResponse;
