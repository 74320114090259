import ReactDOM from 'react-dom/client';
import './index.scss';
import { RouterProvider } from 'react-router-dom';
import router from '@/routes.tsx';
import * as Sentry from '@sentry/react';
import initCustomTailwindMerge from '@/init-tailwind-merge.ts';
import postHogService from '@services/posthog-service.ts';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import modalService from '@services/modal-service.tsx';
import ModalBase from '@components/service/modal/modal-base.tsx';

const startTime = Date.now();

Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DNS as string,
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration({
			maskAllText: false,
			blockAllMedia: false,
		}),
	],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	tracesSampleRate: 1.0,

	// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ['localhost', 'https://tools.thegang.io'],

	// Capture Replay for 10% of all sessions,
	// plus for 100% of sessions with an error
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
	enabled: import.meta.env.VITE_SENTRY_ENABLED === 'true',
});

postHogService.init();

serviceWorkerRegistration.register({
	onUpdate: (registration) => {
		// Don't trigger an update if the service worker is updated within 10 seconds of booting the app
		const currentTime = Date.now();
		if (Math.floor((currentTime - startTime) / 1000) < 10) {
			return;
		}

		modalService.open({
			content: (
				<ModalBase
					title={'Update'}
					content={{
						children: (
							<>
								<div className={'mb-3'}>
									{
										'A new version of the app is available. Would you like to update?'
									}
								</div>
							</>
						),
					}}
					onConfirm={() => {
						// If confirmed, force the new service worker to take control
						registration.waiting?.postMessage({
							type: 'SKIP_WAITING',
						});
						window.location.reload();
					}}
					options={{
						confirmButtonTitle: 'Reload & Update',
					}}
				/>
			),
		});
	},
});

export const customTwMerge = initCustomTailwindMerge();

ReactDOM.createRoot(document.getElementById('root')!).render(
	<RouterProvider router={router} />
);
