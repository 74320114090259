import { observer } from 'mobx-react-lite';
import { Experience } from '@/schemas/experience-schema.ts';
import {
	DefaultThumbnails,
	PlaceThumbnailSize,
} from '@components/data/place-thumbnail/place-thumbnail-constants.ts';
import SeededRandom from '@utils/seeded-random.ts';
import { useEffect, useState } from 'react';
import thumbnailStore from '@store/thumbnail-store.ts';
import LoadingSpinner from '@components/core/loading-spinner/loading-spinner.tsx';

interface PlaceThumbnailProps {
	place?: Experience;
	size: PlaceThumbnailSize;
	className?: string;
}

const PlaceThumbnail = observer(function PlaceThumbnail(
	props: PlaceThumbnailProps
) {
	let hash: string | undefined = undefined;

	if (!props.place) {
		hash = 'default';
	} else if (props.place.thumbnails?.icon !== undefined) {
		hash = props.place.thumbnails.icon;
	} else if (props.place) {
		hash = thumbnailStore.getThumbnailHash(props.place);
	}

	const defaultThumbnail =
		DefaultThumbnails[
			Math.floor(
				SeededRandom(props.place ? +props.place.place_id : 0) *
					DefaultThumbnails.length
			)
		];

	const [imgSrc, setImgSrc] = useState<string | undefined>(
		props.place && hash !== 'default'
			? `https://tr.rbxcdn.com/${hash}/${props.size}/Image/Webp`
			: defaultThumbnail
	);
	const onError = () => setImgSrc(defaultThumbnail);

	useEffect(() => {
		setImgSrc(
			props.place && hash !== 'default'
				? `https://tr.rbxcdn.com/${hash}/${props.size}/Image/Webp`
				: defaultThumbnail
		);
	}, [defaultThumbnail, hash, props.place, props.size]);

	return (
		<>
			{hash && (
				<img
					src={imgSrc ? imgSrc : defaultThumbnail}
					alt={''}
					className={props.className}
					loading={'lazy'}
					onError={onError}
				/>
			)}

			{!hash && <LoadingSpinner className={props.className} />}
		</>
	);
});

export default PlaceThumbnail;
