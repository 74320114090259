import { observer } from 'mobx-react-lite';
import { ReactNode, useState } from 'react';
import authStore from '@store/auth-store.ts';
import { Navigate, useLocation } from 'react-router-dom';
import toastStore from '@store/toast-store.ts';
import { ToastType } from '@components/service/toast/toast-enums.ts';

interface AuthCheckProps {
	children?: ReactNode;
}

const AuthCheck = observer(function AuthCheck(props: AuthCheckProps) {
	const [showToast, setShowToast] = useState(true);

	const location = useLocation();

	if (authStore.isLoggedIn) {
		return props.children;
	} else {
		if (showToast && !authStore.recentlyLoggedOut) {
			toastStore.emit('Please log in to continue.', ToastType.WARNING);
			setShowToast(false);
			setTimeout(() => {
				setShowToast(true);
			}, 1000);
		}
		return <Navigate to={'/login'} state={{ from: location }} />;
	}
});

export default AuthCheck;
