function previousEvenTenMinuteDate(date: Date): Date {
	const newDate = new Date(date);
	const minutes = newDate.getMinutes();
	const roundedMinutes = Math.floor(minutes / 10) * 10;
	newDate.setMinutes(roundedMinutes, 0, 0);

	// If the current minutes are exactly on the ten-minute mark, subtract 10 minutes to get the previous interval.
	if (
		minutes % 10 === 0 &&
		date.getSeconds() === 0 &&
		date.getMilliseconds() === 0
	) {
		newDate.setMinutes(roundedMinutes - 10);
	}

	return newDate;
}

export default previousEvenTenMinuteDate;
