import { makeAutoObservable, runInAction } from 'mobx';
import {
	Thumbnail,
	ThumbnailListSchema,
	ThumbnailType,
} from '@/schemas/thumbnail-schema.ts';
import { Experience } from '@/schemas/experience-schema.ts';
import httpFetch from '@services/http-fetch-service.ts';

export const thumbnailApi = `${import.meta.env.VITE_SERVER_URL}/api/thumbnail`;

class ThumbnailStore {
	private _thumbnails: Record<number, Thumbnail> = {};

	constructor() {
		makeAutoObservable(this);
	}

	async loadThumbnailsById(experienceIds: number[]) {
		const filteredExperiences = experienceIds.filter(
			(id) => !this._thumbnails[id]
		);

		if (!filteredExperiences.length) {
			return;
		}

		const searchParams = new URLSearchParams();
		searchParams.append('placeIds', filteredExperiences.join(','));

		const response = await httpFetch.GET(
			`${thumbnailApi}/bulk?${searchParams.toString()}`
		);

		if (response.ok) {
			const thumbnails = ThumbnailListSchema.parse(await response.json());
			const loadedThumbnails: Record<number, Thumbnail> = {};

			// Some experiences do not have thumbnails, register them as empty
			filteredExperiences.forEach((id) => {
				loadedThumbnails[id] = {
					place_id: id,
					hash: 'default',
					type: ThumbnailType.ICON,
					time: '',
				};
			});

			thumbnails.forEach((t) => {
				loadedThumbnails[t.place_id] = t;
			});

			runInAction(() => {
				this._thumbnails = {
					...this._thumbnails,
					...loadedThumbnails,
				};
			});
		}
	}

	async loadThumbnails(experiences: Experience[]) {
		const filteredExperiences = experiences.filter(
			(exp) => !this._thumbnails[exp.place_id]
		);

		if (!filteredExperiences.length) {
			return;
		}

		const searchParams = new URLSearchParams();
		searchParams.append(
			'placeIds',
			filteredExperiences.map((exp) => exp.place_id).join(',')
		);

		const response = await httpFetch.GET(
			`${thumbnailApi}/bulk?${searchParams.toString()}`
		);

		if (response.ok) {
			const thumbnails = ThumbnailListSchema.parse(await response.json());
			const loadedThumbnails: Record<number, Thumbnail> = {};

			// Some experiences do not have thumbnails, register them as empty
			filteredExperiences.forEach((exp) => {
				loadedThumbnails[exp.place_id] = {
					place_id: exp.place_id,
					hash: 'default',
					type: ThumbnailType.ICON,
					time: '',
				};
			});

			thumbnails.forEach((t) => {
				loadedThumbnails[t.place_id] = t;
			});

			runInAction(() => {
				this._thumbnails = {
					...this._thumbnails,
					...loadedThumbnails,
				};
			});
		}
	}

	getThumbnailHash(place: Experience) {
		const thumbnail = this._thumbnails[place.place_id];

		return thumbnail ? thumbnail.hash : undefined;
	}
}

const thumbnailStore = new ThumbnailStore();
export default thumbnailStore;
