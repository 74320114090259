import { observer } from 'mobx-react-lite';
import View from '@components/view/view.tsx';
import Icon from '@components/core/icon/icon.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import { useRouteError } from 'react-router-dom';

interface RouterError {
	status: number;
}

interface ErrorMessage {
	title: string;
	message: string;
	icon: IconEnum;
}

const Error = observer(function Error() {
	const routerError = (useRouteError() ?? { status: 500 }) as RouterError;

	let errorMessage: ErrorMessage;

	switch (routerError.status) {
		case 404:
			errorMessage = {
				title: '404 - PAGE NOT FOUND',
				message: "This is not the resource you're looking for..",
				icon: IconEnum.HAND,
			};
			break;
		default:
			errorMessage = {
				title: 'Something went wrong',
				message:
					'Something went wrong, reload the page and try again..',
				icon: IconEnum.SAD,
			};
	}

	return (
		<View viewInfo={{ title: 'Error' }} hideHeader fullHeight>
			<div className={'flex flex-col justify-center items-center h-1/2'}>
				<div className={'text-5xl font-semibold'}>
					{errorMessage.title}
				</div>
				<div
					className={
						'flex justify-center items-center text-lg font-light mt-4'
					}
				>
					<Icon
						icon={errorMessage.icon}
						className={'mr-1.5'}
						size={'24px'}
					/>
					{errorMessage.message}
				</div>
			</div>
		</View>
	);
});

export default Error;
