import placeTagStore from '@store/place-tag-store.ts';

export async function PlaceTagsLoader() {
	if (!placeTagStore.initialized) {
		await placeTagStore.listPlaceTags();
	} else {
		void placeTagStore.listPlaceTags();
	}

	return placeTagStore.placeTags;
}
