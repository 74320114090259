import { useEffect, useState } from 'react';

export type HashParams = Record<string, string>;

const useHashQueryParams = (): [
	HashParams,
	(newParams: HashParams) => void,
] => {
	const getHashParams = (): HashParams => {
		const hashParams = new URLSearchParams(window.location.hash.slice(1));
		const params: HashParams = {};

		hashParams.forEach((value, key) => {
			params[key] = value;
		});

		return params;
	};

	const setHashParams = (newParams: HashParams): void => {
		const hashParams = new URLSearchParams();

		for (const key in newParams) {
			if (Object.prototype.hasOwnProperty.call(newParams, key)) {
				if (newParams[key]) {
					hashParams.set(key, newParams[key]);
				}
			}
		}

		window.location.hash = hashParams.toString();
	};

	const [params, setParams] = useState<HashParams>(getHashParams);

	useEffect(() => {
		const handleHashChange = () => {
			setParams(getHashParams());
		};

		window.addEventListener('hashchange', handleHashChange);

		return () => {
			window.removeEventListener('hashchange', handleHashChange);
		};
	}, []);

	return [params, setHashParams];
};

export default useHashQueryParams;
