import { observer } from 'mobx-react-lite';
import { SlimExperience } from '@/schemas/experience-schema.ts';
import GroupBy from '@utils/group-by.ts';
import TopCreatorsListEnum from '@views/overview/view-components/top-creators-list-enum.ts';
import TGSTable, { CustomColDef } from '@components/core/table/table.tsx';
import { CustomCellRendererProps } from 'ag-grid-react';

interface TopCreatorListProps {
	places: SlimExperience[];
	listBy: TopCreatorsListEnum;
}

interface CreatorPlaces {
	id: number;
	name: string;
	places: SlimExperience[];
	totalPlayers: number;
}

const SortTopCreatorsList = (
	creatorPlaces: CreatorPlaces[],
	listBy: TopCreatorsListEnum
) => {
	switch (listBy) {
		case TopCreatorsListEnum.BY_CREATED_PLACES:
			return creatorPlaces.sort(
				(a, b) => b.places.length - a.places.length
			);
		case TopCreatorsListEnum.BY_CURRENT_PLAYERS:
			return creatorPlaces.sort(
				(a, b) => b.totalPlayers - a.totalPlayers
			);
	}
};

const TopCreatorsList = observer(function TopCreatorsList(
	props: TopCreatorListProps
) {
	const placesGroupedByCreator = GroupBy(
		props.places,
		(place: SlimExperience) => place.creator.id
	);

	const top5Creators: CreatorPlaces[] = SortTopCreatorsList(
		Object.entries(
			Object.fromEntries(placesGroupedByCreator.entries())
		).map((entry) => {
			return {
				id: entry[1][0].creator.id,
				name: entry[1][0].creator.name,
				places: entry[1],
				totalPlayers: entry[1].reduce(
					(a, b) => a + (b.players_online ?? 0),
					0
				),
			} as CreatorPlaces;
		}),
		props.listBy
	).slice(0, 10);

	const ColumnDef: CustomColDef<CreatorPlaces>[] = [
		{
			minWidth: 55,
			maxWidth: 55,
			cellRenderer: (params: CustomCellRendererProps) => {
				const placement = (params.node.rowIndex ?? 0) + 1;
				return (
					<div
						className={
							'text-2xl font-bold flex justify-center items-center'
						}
					>
						{placement}
					</div>
				);
			},
			cellStyle: {
				display: 'flex',
				alignItems: 'center',
			},
			resizable: false,
			sortable: false,
		},
		{
			headerName: 'Creator',
			field: 'name',
			resizable: false,
			sortable: false,
		},
	];

	switch (props.listBy) {
		case TopCreatorsListEnum.BY_CREATED_PLACES:
			ColumnDef.push({
				headerName: 'Experiences',
				field: 'places',
				valueFormatter: (params) => {
					const placeIds = params.value as string[];
					return placeIds.length.toString();
				},
				cellStyle: {
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				},
				minWidth: 120,
				maxWidth: 120,
				resizable: false,
				sortable: false,
			});
			break;
		case TopCreatorsListEnum.BY_CURRENT_PLAYERS:
			ColumnDef.push({
				headerName: 'Players Online',
				field: 'totalPlayers',
				valueFormatter: (params) => {
					const totalPlayers = params.value as number;
					return new Intl.NumberFormat(navigator.language).format(
						totalPlayers
					);
				},
				cellStyle: {
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				},
				minWidth: 130,
				maxWidth: 130,
				resizable: false,
				sortable: false,
			});
			break;
	}

	return (
		<TGSTable
			columnDefs={ColumnDef}
			rowData={top5Creators}
			tableName={'top-5-creators-place-amounts'}
			disablePagination
		/>
	);
});

export default TopCreatorsList;
