import { ChangeEvent, RefObject, useId } from 'react';
import { INPUT_TEXT_TYPES } from '@components/core/input-text/input-text-enum.ts';
import ClassString from '@utils/class-string.ts';
import { observer } from 'mobx-react-lite';

export interface InputTextProps {
	label: string;
	value: string;
	onInput?: (event: ChangeEvent<HTMLInputElement>) => unknown;
	onBlur?: () => unknown;
	onFocus?: () => unknown;
	className?: string;
	inputClassName?: string;
	type?: INPUT_TEXT_TYPES;
	options?: {
		noMarginBottom?: boolean;
		noMargin?: boolean;
		width?: string;
		noAutocomplete?: boolean;
	};
	innerRef?: RefObject<HTMLInputElement>;
	placeholder?: string;
}

const InputText = observer(function InputText(props: InputTextProps) {
	const id = useId();

	return (
		<div
			className={ClassString({
				static: 'relative my-3 group w-52',
				dynamic: {
					'mb-0': props.options?.noMarginBottom,
					'my-0': props.options?.noMargin,
				},
				custom: props.className,
			})}
			style={{ width: props.options?.width }}
		>
			<label
				htmlFor={id}
				className={ClassString({
					static: 'absolute transition-all select-none left-4 top-2 text-sm opacity-70 cursor-text group-focus-within:cursor-default group-focus-within:-top-4 group-focus-within:text-xs group-focus-within:opacity-100 group-focus-within:text-blue-500 dark:group-focus-within:text-blue-100',
					dynamic: {
						'cursor-default -top-4 text-xs opacity-100':
							!!props.value.length,
					},
				})}
			>
				{props.label}
			</label>
			<input
				id={id}
				type={`${props.type ? props.type : INPUT_TEXT_TYPES.TEXT}`}
				value={props.value}
				onInput={props.onInput}
				onBlur={props.onBlur}
				onFocus={props.onFocus}
				className={ClassString({
					static: 'w-full h-9 text-ellipsis transition-all rounded-full outline-none px-4 bg-white dark:bg-gray-525 focus:border-blue-500 dark:focus:border-blue-100 border border-gray-600 dark:border-gray-500',
					dynamic: {},
					custom: props.inputClassName,
				})}
				autoComplete={props.options?.noAutocomplete ? 'off' : 'on'}
				ref={props.innerRef}
				placeholder={props.placeholder}
			/>
		</div>
	);
});

export default InputText;
