import placeTagStore from '@store/place-tag-store.ts';
import experienceStore from '@store/experience-store.ts';

export interface PlaceDetailsLoaderReturn {
	placeId: string;
}

export async function PlaceDetailsLoader(placeId: string) {
	void placeTagStore.listPlaceTags();

	if (experienceStore.getFullExperienceById(+placeId)) {
		void experienceStore.getFullExperience(placeId);
		return { placeId };
	}

	const res = await experienceStore.getFullExperience(placeId);

	if (!res.ok) {
		if (!experienceStore.getFullExperienceById(+placeId)) {
			throw new Response(null, { status: res.status });
		}
	}

	return { placeId };
}
