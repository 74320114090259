import { z } from 'zod';

export enum ExperienceStatus {
	UNKNOWN = 0,
	PLAYABLE = 1,
	UNAPPROVED = 3,
	PRIVATE = 5,
	UNDER_REVIEW = 9,
}

export interface ExperienceThumbnail {
	icon?: string;
	banner?: string;
}

export interface ExperienceCreatorResponse {
	id: number;
	name: string;
	type: string;
	is_verified: boolean;
}

export interface FullExperienceResponse {
	universe_id: number;
	place_id: number;
	name: string;
	genre: string;
	updated: string; // Date string
	created: string; // Date string
	released: string; // Date string
	canonical_name: string;
	thumbnails?: ExperienceThumbnail;
	players_online?: number;
	total_visits?: number;
	like_percentage?: number;
	creator: {
		id: number;
		name: string;
		type: string;
		is_verified: boolean;
	};
	play_status: ExperienceStatus;
}

export interface SlimExperienceResponse {
	universe_id: number;
	place_id: number;
	name: string;
	canonical_name: string;
	players_online?: number;
	total_visits?: number;
	like_percentage?: number;
	creator: {
		id: number;
		name: string;
		type: string;
		is_verified: boolean;
	};
}

export const ExperienceThumbnailSchema: z.ZodType<ExperienceThumbnail> =
	z.object({
		icon: z.string().optional(),
		banner: z.string().optional(),
	});

export const ExperienceCreatorSchema: z.ZodType<ExperienceCreatorResponse> =
	z.object({
		id: z.number(),
		name: z.string(),
		type: z.string(),
		is_verified: z.boolean(),
	});

export const FullExperienceResponseSchema: z.ZodType<FullExperienceResponse> =
	z.object({
		universe_id: z.number(),
		place_id: z.number(),
		name: z.string(),
		genre: z.string(),
		updated: z.string(),
		created: z.string(),
		released: z.string(),
		canonical_name: z.string(),
		thumbnails: ExperienceThumbnailSchema.optional(),
		players_online: z.number().optional(),
		total_visits: z.number().optional(),
		like_percentage: z.number().optional(),
		creator: ExperienceCreatorSchema,
		age_limit: z.number().nullable(),
		play_status: z.nativeEnum(ExperienceStatus),
	});

export const SlimExperienceResponseSchema: z.ZodType<SlimExperienceResponse> =
	z.object({
		universe_id: z.number(),
		place_id: z.number(),
		name: z.string(),
		canonical_name: z.string(),
		players_online: z.number().optional(),
		total_visits: z.number().optional(),
		like_percentage: z.number().optional(),
		creator: ExperienceCreatorSchema,
	});

export const SlimExperienceListSchema = z.array(SlimExperienceResponseSchema);

export enum ExperienceCreatorType {
	USER = 'User',
	GROUP = 'Group',
}

export interface ExperienceCreator {
	id: number;
	name: string;
	type: ExperienceCreatorType;
	is_verified: boolean;
}

export interface FullExperience
	extends Omit<
		FullExperienceResponse,
		| 'updated'
		| 'created'
		| 'released'
		| 'players_online'
		| 'total_visits'
		| 'like_percentage'
		| 'creator'
	> {
	updated: Date;
	created: Date;
	released: Date;
	players_online: number;
	total_visits: number;
	like_percentage: number;
	creator: ExperienceCreator;
	age_limit?: number;
	play_status: ExperienceStatus;
	rank?: number; // FE only for now
}

export interface SlimExperience
	extends Omit<
		FullExperienceResponse,
		| 'updated'
		| 'created'
		| 'released'
		| 'play_status'
		| 'creator'
		| 'players_online'
		| 'total_visits'
		| 'like_percentage'
		| 'genre'
		| 'thumbnails'
	> {
	players_online: number;
	total_visits: number;
	like_percentage: number;
	creator: ExperienceCreator;
	thumbnails?: ExperienceThumbnail; //
	rank?: number; // FE only for now
}

export type Experience = SlimExperience | FullExperience;
