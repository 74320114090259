import { observer } from 'mobx-react-lite';
import Highcharts from 'highcharts';
import Chart from '@components/core/chart/chart.tsx';
import Button from '@components/core/button/button.tsx';
import { useCallback, useEffect, useState } from 'react';
import placeStatsStore, {
	PlaceStatGranularity,
	PlaceStatType,
} from '@store/place-stats-store.ts';
import LoadingIndicator from '@components/core/loading-indicator/loading-indicator.tsx';
import {
	FullExperience,
	ExperienceStatus,
} from '@/schemas/experience-schema.ts';
import hexToRGBA from '@utils/hex-to-rgba.ts';
import ChartColors from '@components/core/chart/chart-colors.ts';
import themeStore from '@store/theme-store.ts';
import BrandColors from '@styles/_brand_colors.ts';

interface ExperienceCcuAndVisitsProps {
	place: FullExperience;
}

enum ExperienceCcuAndVisitsState {
	CCU,
	VISITS,
	BOTH,
}

const ChartTitles: Record<ExperienceCcuAndVisitsState, string> = {
	[ExperienceCcuAndVisitsState.CCU]: 'Concurrent Users',
	[ExperienceCcuAndVisitsState.VISITS]: 'New Visits',
	[ExperienceCcuAndVisitsState.BOTH]: 'Concurrent Users and New Visits',
};

const ExperienceCcuAndVisits = observer(function ExperienceCcuAndVisits(
	props: ExperienceCcuAndVisitsProps
) {
	const [state, setState] = useState(ExperienceCcuAndVisitsState.CCU);
	const [granularity, setGranularity] = useState(PlaceStatGranularity.DAY);
	const [loading, setLoading] = useState(true);

	const series: {
		name: string;
		color: string;
		data: number[][] | undefined;
	}[] = [];

	if (
		state === ExperienceCcuAndVisitsState.VISITS ||
		state === ExperienceCcuAndVisitsState.BOTH
	) {
		series.push({
			name: 'New Visits',
			color: ChartColors()[1],
			data: placeStatsStore.placeStat(
				props.place.place_id,
				PlaceStatType.VISITS,
				granularity
			),
		});
	}

	if (
		state === ExperienceCcuAndVisitsState.CCU ||
		state === ExperienceCcuAndVisitsState.BOTH
	) {
		series.push({
			name: 'Concurrent Users',
			color: ChartColors()[0],
			data: placeStatsStore.placeStat(
				props.place.place_id,
				PlaceStatType.CCUS,
				granularity
			),
		});
	}

	const noData = !loading && !series.some((series) => !!series.data?.length);

	const getData = useCallback(
		async (tf: PlaceStatGranularity) => {
			await Promise.all([
				placeStatsStore.getPlaceStats(
					[props.place.place_id],
					PlaceStatType.VISITS,
					tf
				),
				placeStatsStore.getPlaceStats(
					[props.place.place_id],
					PlaceStatType.CCUS,
					tf
				),
			]);

			setLoading(false);
		},
		[props.place]
	);

	const yMin = undefined;
	const yMax = undefined;

	let xMin = 0;
	let xMax = 0;
	series.forEach((pd) => {
		if (pd.data) {
			pd.data.forEach((d) => {
				const date = d[0];

				if (xMin === 0) {
					xMin = date;
				}

				if (xMax === 0) {
					xMax = date;
				}

				if (date < xMin) {
					xMin = date;
				}

				if (date > xMax) {
					xMax = date;
				}
			});
		}
	});

	const options: Highcharts.Options = {
		title: {
			text: ChartTitles[state],
		},
		series: series.map((series) => {
			return {
				name: series.name,
				type: 'area',
				showInLegend: false,
				data: series.data ?? [],
				marker: {
					enabled: false,
				},
				lineWidth: 3,
				lineColor: series.color,
				fillColor: hexToRGBA(series.color, 0.6),
			};
		}),
		chart: {
			marginLeft: 45,
		},
		xAxis: {
			type: 'datetime',
			title: {
				y: 5,
				style: {
					color: themeStore.lightTheme
						? BrandColors.gray600
						: BrandColors.white,
					fontStyle: 'italic',
				},
			},
			min: xMin,
			max: xMax,
		},
		yAxis: {
			title: {
				enabled: false,
			} as Highcharts.YAxisOptions,
			labels: {
				x: -10,
				y: 3,
				reserveSpace: false,
				style: {
					whiteSpace: 'nowrap',
				},
			},
			min: yMin,
			max: yMax,
		},
		plotOptions: {
			line: {
				marker: {
					enabled: false,
				},
			},
		},
		navigator: {
			enabled: true,
			xAxis: {
				min: xMin,
				max: xMax,
			},
		},
		scrollbar: {
			enabled: true,
			liveRedraw: true,
		},
		rangeSelector: {
			selected:
				props.place.play_status !== ExperienceStatus.PLAYABLE ? 7 : 2,
		},
	};

	const updateTimeFrame = (timeframe: PlaceStatGranularity) => {
		void getData(timeframe);
		setGranularity(timeframe);
		setLoading(true);
	};

	useEffect(() => {
		void getData(granularity);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={'w-full'}>
			<div className={'w-full relative'}>
				<Chart highcharts={Highcharts} options={options} />

				{loading && (
					<LoadingIndicator
						className={
							'!absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
						}
					/>
				)}
				{noData && (
					<div
						className={
							'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
						}
					>
						No Data
					</div>
				)}
			</div>

			<div
				className={
					'w-full flex items-center justify-between flex-wrap gap-2'
				}
			>
				<div className={'flex items-center gap-4'}>
					<Button
						title={'Hourly'}
						onClick={() => {
							updateTimeFrame(PlaceStatGranularity.HOUR);
						}}
						selected={granularity === PlaceStatGranularity.HOUR}
					/>

					<Button
						title={'Daily'}
						onClick={() => {
							updateTimeFrame(PlaceStatGranularity.DAY);
						}}
						selected={granularity === PlaceStatGranularity.DAY}
					/>

					<Button
						title={'Weekly'}
						onClick={() => {
							updateTimeFrame(PlaceStatGranularity.WEEK);
						}}
						selected={granularity === PlaceStatGranularity.WEEK}
					/>

					<Button
						title={'Monthly'}
						onClick={() => {
							updateTimeFrame(PlaceStatGranularity.MONTH);
						}}
						selected={granularity === PlaceStatGranularity.MONTH}
					/>
				</div>

				<div className={'flex items-center gap-4'}>
					<Button
						title={'CCU'}
						onClick={() => {
							setState(ExperienceCcuAndVisitsState.CCU);
						}}
						selected={state === ExperienceCcuAndVisitsState.CCU}
					/>

					<Button
						title={'Visits'}
						onClick={() => {
							setState(ExperienceCcuAndVisitsState.VISITS);
						}}
						selected={state === ExperienceCcuAndVisitsState.VISITS}
					/>

					<Button
						title={'Both'}
						onClick={() => {
							setState(ExperienceCcuAndVisitsState.BOTH);
						}}
						selected={state === ExperienceCcuAndVisitsState.BOTH}
					/>
				</div>
			</div>
		</div>
	);
});

export default ExperienceCcuAndVisits;
