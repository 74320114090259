import { observer } from 'mobx-react-lite';
import View from '@components/view/view.tsx';
import placeTagStore from '@store/place-tag-store.ts';
import Button from '@components/core/button/button.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import ViewPanel from '@components/view/view-panel.tsx';
import modalService from '@services/modal-service.tsx';
import PlaceTagsCreateNewModal from '@views/place-tags/view-components/place-tags-create-new-modal.tsx';
import { useNavigate } from 'react-router-dom';
import SearchBar from '@components/core/search-bar/search-bar.tsx';
import { useState } from 'react';
import searchService from '@services/search-service.ts';
import ExperienceTagList from '@views/place-tags/view-components/experience-tag-list.tsx';

const PlaceTags = observer(function PlaceTags() {
	const navigate = useNavigate();
	const [searchTerm, setSearchTerm] = useState('');

	let placeTags = placeTagStore.placeTags;
	if (searchTerm) {
		placeTags = placeTags.filter((placeTag) => {
			return searchService.tag(placeTag, searchTerm);
		});
	}

	const addNewPlaceTag = async (tagName: string) => {
		const newTag = await placeTagStore.addPlaceTag(tagName);

		if (newTag) {
			navigate(`/place-tags/${newTag.id}`);
		}
	};

	const openTagCreateModal = () => {
		modalService.open({
			content: <PlaceTagsCreateNewModal onSubmit={addNewPlaceTag} />,
		});
	};

	return (
		<View
			className={'max-w-screen-lg'}
			viewInfo={{ title: 'Tags', icon: IconEnum.TAG }}
			headerChildren={
				<div
					className={
						'flex justify-between items-center flex-wrap sm:flex-nowrap gap-4 w-full'
					}
				>
					<SearchBar
						onSearchUpdate={setSearchTerm}
						placeholder={'Search by name..'}
						className={'max-w-3xl'}
					/>

					<Button
						title={'New Tag'}
						icon={{
							icon: IconEnum.ADD,
							placement: 'right',
							size: '1.5rem',
						}}
						onClick={openTagCreateModal}
					/>
				</div>
			}
		>
			<ViewPanel options={{ noPadding: true, noOutline: true }}>
				<ExperienceTagList tags={placeTags} />
			</ViewPanel>
		</View>
	);
});

export default PlaceTags;
