import toastStore from '@store/toast-store.ts';
import { ToastType } from '@components/service/toast/toast-enums.ts';
import { ErrorResponseSchema } from '@/schemas/error-response-schema.ts';

class HttpFetchService {
	async POST(
		url: string,
		body: object,
		silentError = false
	): Promise<Response> {
		const headers: HeadersInit = {
			'Content-Type': 'application/json',
		};

		const response = await fetch(url, {
			method: 'POST',
			headers,
			body: JSON.stringify(body),
			credentials: 'include',
		});

		if (!response.ok && !silentError) {
			await this.responseError(response);
		}

		return response;
	}

	async PUT(
		url: string,
		body: object,
		silentError = false
	): Promise<Response> {
		const headers = {
			'content-type': 'application/json',
		};

		const response = await fetch(url, {
			method: 'PUT',
			headers,
			body: JSON.stringify(body),
			credentials: 'include',
		});

		if (!response.ok && !silentError) {
			await this.responseError(response);
		}

		return response;
	}

	async GET(
		url: string,
		includeCredentials = true,
		silentError = false,
		additionalHeaders?: HeadersInit
	): Promise<Response> {
		const headers: HeadersInit = additionalHeaders ?? {};

		const requestInit: RequestInit = {
			method: 'GET',
			credentials: includeCredentials ? 'include' : 'omit',
			headers,
		};

		const response = await fetch(url, requestInit);

		if (!response.ok && !silentError) {
			await this.responseError(response);
		}

		return response;
	}

	async DELETE(url: string, silentError = false): Promise<Response> {
		const response = await fetch(url, {
			method: 'DELETE',
			credentials: 'include',
		});

		if (!response.ok && !silentError) {
			await this.responseError(response);
		}

		return response;
	}

	private async responseError(response: Response) {
		const error = ErrorResponseSchema.parse(await response.json());
		toastStore.emit(error.error, ToastType.ERROR);
	}
}

const httpFetch = new HttpFetchService();
export default httpFetch;
