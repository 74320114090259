import { makeAutoObservable, runInAction } from 'mobx';
import httpFetch from '@services/http-fetch-service.ts';
import {
	PlatformCCU,
	PlatformCCUListResponseSchema,
} from '@/schemas/platform-ccu.ts';

const platformCCUApi = `${import.meta.env.VITE_SERVER_URL}/api/platform/ccu`;
const platformGenreApi = `${import.meta.env.VITE_SERVER_URL}/api/platform/genre`;

class PlatformStatsStore {
	private _platformCCU: PlatformCCU[] = [];
	private _platformCCUChartData: number[][] = [];

	private _platformGenreDistribution: Record<string, number> = {};

	private _loading = true;

	constructor() {
		makeAutoObservable(this);
	}

	async loadPlatformCCU() {
		const response = await httpFetch.GET(
			`${platformCCUApi}?granularity=day`
		);

		if (!this._loading) {
			runInAction(() => {
				this._loading = true;
			});
		}

		if (response.ok) {
			const platformCCUResponse = PlatformCCUListResponseSchema.parse(
				await response.json()
			);

			runInAction(() => {
				this._platformCCU = platformCCUResponse
					.map((data) => {
						return { ...data, time: new Date(data.time) };
					})
					.sort((a, b) => a.time.getTime() - b.time.getTime());

				this._platformCCUChartData = this._platformCCU.map((data) => {
					return [data.time.getTime(), data.ccu];
				});
			});
		}

		runInAction(() => {
			this._loading = false;
		});
	}

	async loadGenreDistribution() {
		if (Object.entries(this._platformGenreDistribution).length) {
			// Data already loaded
			return;
		}

		const response = await httpFetch.GET(platformGenreApi);

		if (response.ok) {
			const platformDis = (await response.json()) as Record<
				string,
				number
			>;
			runInAction(() => {
				this._platformGenreDistribution = platformDis;
			});
		}
	}

	get loading() {
		return this._loading;
	}

	get platformCCU() {
		return this._platformCCU;
	}

	get platformCCUChartData() {
		return this._platformCCUChartData;
	}

	get platformGenreDistribution() {
		return this._platformGenreDistribution;
	}
}

const platformStatsStore = new PlatformStatsStore();
export default platformStatsStore;
