import { observer } from 'mobx-react-lite';

interface QuickDataProps {
	data: string | number;
	title: string;
	className?: string;
}

const QuickData = observer(function QuickData(props: QuickDataProps) {
	return (
		<div className={`m-4 ${props.className}`}>
			<div className={'text-5xl font-semibold text-center break-words'}>
				{props.data}
			</div>
			<div className={'text-2xl font-light text-center'}>
				{props.title}
			</div>
		</div>
	);
});

export default QuickData;
