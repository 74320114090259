function calculateAverage(numbers: number[], round?: boolean): number {
	if (numbers.length === 0) {
		return 0;
	}

	const sum = numbers.reduce(
		(accumulator, currentValue) => accumulator + currentValue,
		0
	);

	const result = sum / numbers.length;

	if (round) {
		return Math.round(result);
	}

	return result;
}

export default calculateAverage;
