import { observer } from 'mobx-react-lite';
import TextArea from '@components/core/text-area/text-area.tsx';
import { ChangeEvent, useState } from 'react';
import RobloxUrlToPlaceId from '@utils/roblox-url-to-place-id.ts';
import validate from '@services/validation-service.ts';
import Button from '@components/core/button/button.tsx';
import modalService from '@services/modal-service.tsx';
import { BUTTON_STYLE } from '@components/core/button/button-style-enum.ts';

interface PlaceTagDetailsBulkAddProps {
	tagName: string;
	submit: (placeIds: string[]) => unknown;
}

const PlaceTagDetailsBulkAdd = observer(function PlaceTagDetailsBulkAdd(
	props: PlaceTagDetailsBulkAddProps
) {
	const [bulkPlaceIdsString, setBulkPlaceIdsString] = useState('');
	const [bulkPlaceIds, setBulkPlaceIds] = useState<string[]>([]);
	const [validBulkPlaceIds, setValidBulkPlaceIds] = useState(false);
	const [showError, setShowError] = useState(false);

	const handleBulkPlaceIdsChange = (
		event: ChangeEvent<HTMLTextAreaElement>
	) => {
		let newString = event.target.value;
		if (newString.length > bulkPlaceIdsString.length) {
			const slicedString = newString.slice(bulkPlaceIdsString.length);
			const newPlaceId = RobloxUrlToPlaceId(slicedString);
			if (slicedString !== newPlaceId) {
				newString = `${bulkPlaceIdsString}${newPlaceId}`;
			}
		}

		setBulkPlaceIdsString(newString);

		let valid = false;

		if (/[\s,]+/.test(newString)) {
			const splitString = newString.split(/[\s,]+/);
			const filteredString = splitString.filter(
				(string) => !/[\s,]+/.test(string) && !!string
			);
			valid = filteredString.every((placeId) =>
				validate.databaseId(placeId)
			);
			if (valid) {
				setBulkPlaceIds(filteredString);
			}
		} else {
			valid = validate.databaseId(newString);

			if (valid) {
				setBulkPlaceIds([newString]);
			}
		}

		setValidBulkPlaceIds(valid);

		setShowError(!valid && !!newString.length);
	};

	return (
		<div className={'flex flex-col items-center'}>
			<div className={'mb-2'}>
				Bulk add experiences to the tag {`"${props.tagName}"`}. The
				place IDs needs to be separated by either spaces, new lines or
				commas.
			</div>

			<div
				className={
					'mb-6 mt-4 w-full relative flex justify-center min-h-48'
				}
			>
				<TextArea
					label={'Experience IDs'}
					value={bulkPlaceIdsString}
					onChange={handleBulkPlaceIdsChange}
					className={'w-full min-h-48'}
					minHeight={'100%'}
				/>

				{showError && (
					<div className={'absolute bottom-3 right-4 text-red-500'}>
						format is not valid
					</div>
				)}
			</div>

			<div className={'w-full flex justify-evenly mt-2'}>
				<Button
					title={'Cancel'}
					onClick={() => {
						modalService.close();
					}}
				/>

				<Button
					title={'Add Experiences'}
					onClick={() => {
						props.submit(bulkPlaceIds);
						modalService.close();
					}}
					buttonStyle={BUTTON_STYLE.PRIMARY}
					disabled={!validBulkPlaceIds}
				/>
			</div>
		</div>
	);
});

export default PlaceTagDetailsBulkAdd;
