import { observer } from 'mobx-react-lite';
import EditableText from '@components/core/editable-text/editable-text.tsx';
import DatePicker from '@components/core/date-picker/date-picker.tsx';
import {
	FullExperience,
	ExperienceStatus,
} from '@/schemas/experience-schema.ts';
import experienceStore from '@store/experience-store.ts';
import IsValidDate from '@utils/is-valid-date.ts';
import formatNumberWithSuffix from '@utils/format-number-with-suffix.ts';
import PlaceCreator from '@components/data/place-creator/place-creator.tsx';
import PlaceDetailsInfoPanel from '@views/place-details/view-components/place-details-info-panel.tsx';
import PlaceThumbnail from '@components/data/place-thumbnail/place-thumbnail.tsx';
import { PlaceThumbnailSize } from '@components/data/place-thumbnail/place-thumbnail-constants.ts';
import Button from '@components/core/button/button.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import Icon from '@components/core/icon/icon.tsx';
import { Link } from 'react-router-dom';
import copyService from '@services/copy-service.ts';

interface PlaceDetailsInfoProps {
	place: FullExperience;
	className?: string;
}

const million = 1e6;

const robloxPlaceUrl = 'https://www.roblox.com/games/';

const ageLimit = (limit?: number) => {
	if (limit === 0) {
		return 'All Ages';
	}

	if (!limit) {
		return 'Not Rated';
	}

	return `${limit}+`;
};

const status = (status: ExperienceStatus) => {
	switch (status) {
		case ExperienceStatus.UNKNOWN:
			return 'Unknown';
		case ExperienceStatus.PRIVATE:
			return 'Private';
		case ExperienceStatus.UNAPPROVED:
			return 'Unapproved';
		case ExperienceStatus.PLAYABLE:
			return 'Playable';
		case ExperienceStatus.UNDER_REVIEW:
			return 'Under Review';
	}
};

const PlaceDetailsInfo = observer(function PlaceDetailsInfo(
	props: PlaceDetailsInfoProps
) {
	const handleCanonicalNameChance = (newName: string) => {
		if (props.place.canonical_name !== newName) {
			void experienceStore.updateCanonicalName(props.place, newName);
		}
	};

	const handleReleaseDateChange = (value: string) => {
		if (!props.place) {
			return;
		}

		let newDate: Date;

		if (value && IsValidDate(value)) {
			newDate = new Date(value);
		} else {
			newDate = new Date('0001-01-01T00:00:00Z');
		}

		if (newDate.getTime() !== props.place.released.getTime()) {
			void experienceStore.updateReleaseDate(props.place, newDate);
		}
	};

	return (
		<div
			className={
				'flex flex-col xl:flex-row items-center justify-between gap-6'
			}
		>
			<Link
				to={`${robloxPlaceUrl}${props.place.place_id}`}
				target={'_blank'}
				className={
					'rounded-lg border border-gray-300 dark:border-gray-500 hover:border-blue-400 shadow-lg items-center bg-white dark:bg-gray-550 cursor-pointer hover:bg-blue-500 hover:text-white overflow-hidden min-w-experience-thumbnail max-w-experience-thumbnail'
				}
			>
				<PlaceThumbnail
					place={props.place}
					size={PlaceThumbnailSize.VERY_LARGE}
					className={
						'min-w-experience-thumbnail max-w-experience-thumbnail'
					}
				/>
				<div
					className={
						'flex justify-center items-center text-lg py-2 w-full'
					}
				>
					<div className={'mr-2'}>View on roblox.com</div>
					<Icon icon={IconEnum.EXTERNAL_LINK} size={'1.5rem'} />
				</div>
			</Link>

			<div
				className={
					'flex justify-center xl:justify-center items-start content-center flex-wrap gap-4 flex-grow'
				}
			>
				<div className={'w-full grid grid-cols-1 lg:grid-cols-2 gap-4'}>
					<PlaceDetailsInfoPanel
						title={'Canonical Name'}
						content={
							<EditableText
								text={props.place.canonical_name}
								onTextChange={handleCanonicalNameChance}
								truncate
								noLeftPadding
							/>
						}
						className={'!w-full !max-w-full'}
					/>

					<PlaceDetailsInfoPanel
						title={'Creator'}
						content={<PlaceCreator place={props.place} />}
						className={'!w-full !max-w-full'}
					/>
				</div>

				<div className={'w-full grid grid-cols-2 lg:grid-cols-3 gap-4'}>
					<PlaceDetailsInfoPanel
						title={'Players Online'}
						content={new Intl.NumberFormat(
							navigator.language
						).format(props.place.players_online)}
						className={'!w-full !max-w-full'}
					/>

					<PlaceDetailsInfoPanel
						title={'Visits'}
						content={
							props.place.total_visits >= million
								? `${formatNumberWithSuffix(
										props.place.total_visits
									)}+`
								: new Intl.NumberFormat(
										navigator.language
									).format(props.place.total_visits)
						}
						className={'!w-full !max-w-full'}
					/>

					<PlaceDetailsInfoPanel
						title={'Like Percent'}
						content={`${(props.place.like_percentage * 100).toFixed(2)}%`}
						className={'!w-full !max-w-full'}
					/>

					<PlaceDetailsInfoPanel
						title={'Genre'}
						content={props.place.genre}
						className={'!w-full !max-w-full'}
					/>

					<PlaceDetailsInfoPanel
						title={'Age Limit'}
						content={ageLimit(props.place.age_limit)}
						disableTruncate
						className={'!w-full !max-w-full'}
					/>

					<PlaceDetailsInfoPanel
						title={'Release Date'}
						content={
							<DatePicker
								value={
									// Non set release dates are set to year 1
									props.place.released.getFullYear() < 2
										? '-'
										: props.place.released.toLocaleDateString()
								}
								onChange={handleReleaseDateChange}
							/>
						}
						className={'!w-full !max-w-full'}
					/>
				</div>

				<div className={'w-full grid grid-cols-2 lg:grid-cols-3 gap-4'}>
					<PlaceDetailsInfoPanel
						title={'Status'}
						content={status(props.place.play_status)}
						disableTruncate
						className={'!w-full !max-w-full'}
					/>

					<div
						className={
							'w-full flex justify-evenly items-center flex-wrap col-span-2 gap-4'
						}
					>
						<Button
							title={'Launch Experience'}
							icon={{
								icon: IconEnum.PLAY,
								placement: 'right',
								size: '1.5em',
							}}
							onClick={() => {
								open(
									`roblox://placeId=${props.place.place_id}`,
									'_blank'
								);
							}}
							disabled={
								props.place.play_status !==
								ExperienceStatus.PLAYABLE
							}
						/>

						<Button
							title={'Experience ID'}
							icon={{
								icon: IconEnum.COPY,
								placement: 'right',
							}}
							onClick={() => {
								copyService.experienceId(props.place);
							}}
						/>

						<Button
							title={'Universe ID'}
							icon={{
								icon: IconEnum.COPY,
								placement: 'right',
							}}
							onClick={() => {
								copyService.experienceUniverseId(props.place);
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
});

export default PlaceDetailsInfo;
