import default1 from '@assets/roblox-default-1.webp';
import default2 from '@assets/roblox-default-2.webp';
import default3 from '@assets/roblox-default-3.webp';

export enum PlaceThumbnailSize {
	VERY_SMALL = '50/50',
	SMALL = '128/128',
	MEDIUM = '150/150',
	LARGE = '256/256',
	VERY_LARGE = '512/512',
}

export const DefaultThumbnails = [default1, default2, default3];
