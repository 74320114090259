import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import authStore from '@store/auth-store.ts';
import InputText from '@components/core/input-text/input-text.tsx';
import Button from '@components/core/button/button.tsx';
import { BUTTON_STYLE } from '@components/core/button/button-style-enum.ts';
import { useLocation, useNavigate } from 'react-router-dom';
import View from '@components/view/view.tsx';
import ToolsLogo from '@components/core/tools-logo/tools-logo.tsx';
import { INPUT_TEXT_TYPES } from '@components/core/input-text/input-text-enum.ts';
import Form from '@components/core/form/form.tsx';
import { LocationState } from '@components/navigation/navigation-button/navigation-button.tsx';
import LoadingIndicator from '@components/core/loading-indicator/loading-indicator.tsx';
import { CredentialResponse } from 'google-one-tap';
import themeStore from '@store/theme-store.ts';

const Login = observer(function Login() {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();
	const locationState = useLocation().state as LocationState;
	const navigateTo = locationState?.from
		? `${locationState.from.pathname}`
		: '/';

	const ref = useRef<HTMLDivElement>(null);

	const handleLogin = async () => {
		try {
			setLoading(true);
			await authStore.login(email, password);
			navigate(navigateTo);
		} catch (_) {
			setLoading(false);
		}
	};

	const handleGoogleLogin = async (response: CredentialResponse) => {
		try {
			setLoading(true);
			await authStore.googleLogin(response);
			navigate(navigateTo);
		} catch (_) {
			setLoading(false);
		}
	};

	const initializeGSI = () => {
		window.google.accounts.id.initialize({
			client_id: import.meta.env.VITE_GOGGLE_OAUTH_CLIENT_ID as string,
			callback: (response: CredentialResponse) => {
				void handleGoogleLogin(response);
			},
		});

		if (ref.current) {
			window.google.accounts.id.renderButton(ref.current, {
				theme: 'outline',
				size: 'large',
				shape: 'pill',
			});
		}
	};

	useEffect(() => {
		const script = document.createElement('script');
		script.src = 'https://accounts.google.com/gsi/client';
		script.onload = initializeGSI;
		script.async = true;
		document.head.appendChild(script);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<View
			viewInfo={{ title: 'Log In' }}
			hideHeader={true}
			fullHeight
			className={'max-w-md'}
		>
			{!loading && (
				<div
					className={
						'-mt-nav flex flex-col justify-center items-center p-4'
					}
				>
					<ToolsLogo
						className={'mb-6'}
						width={'300px'}
						color={themeStore.lightTheme ? 'black' : 'white'}
					/>

					<Form
						className={'flex flex-col justify-center items-center'}
						onSubmit={() => {
							void handleLogin();
						}}
					>
						<InputText
							label={'Email'}
							value={email}
							onInput={(event) => setEmail(event.target.value)}
							type={INPUT_TEXT_TYPES.EMAIL}
							options={{ width: '250px' }}
						/>

						<InputText
							label={'Password'}
							value={password}
							onInput={(event) => setPassword(event.target.value)}
							type={INPUT_TEXT_TYPES.PASSWORD}
							options={{ width: '250px' }}
						/>

						<Button
							title={'Log In'}
							buttonStyle={BUTTON_STYLE.PRIMARY}
						/>
					</Form>

					<div
						className={
							'flex justify-center items-center my-6 text-gray-400 w-full'
						}
					>
						<div
							className={
								'border-b border-x-0 border-t-0 border-gray-400 border-solid w-1/2'
							}
						></div>
						<div className={'mx-4'}>OR</div>
						<div
							className={
								'border-b border-x-0 border-t-0 border-gray-400 border-solid w-1/2'
							}
						></div>
					</div>

					<div
						ref={ref}
						className={
							'h-14 w-full flex justify-center items-center'
						}
					></div>
				</div>
			)}
			{loading && <LoadingIndicator />}
		</View>
	);
});

export default Login;
