import { makeAutoObservable } from 'mobx';
import { User, UserListSchema, UserRole } from '@/schemas/user-schema.ts';
import toastStore from '@store/toast-store.ts';
import { ToastType } from '@components/service/toast/toast-enums.ts';

export const usersApi = `${import.meta.env.VITE_SERVER_URL}/api/users`;

class UserStore {
	private _users: User[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	async loadUsers() {
		const res = await fetch(usersApi);

		if (res.ok) {
			this._users = UserListSchema.parse(await res.json());
		}
	}

	async updateRole(user: User, newRole: UserRole) {
		const headers: HeadersInit = {
			'Content-Type': 'application/json',
		};

		const res = await fetch(`${usersApi}/${user.id}`, {
			method: 'PUT',
			headers,
			body: JSON.stringify({
				...user,
				role: `${newRole}`,
			}),
			credentials: 'include',
		});

		if (res.ok) {
			toastStore.emit(
				`Role successfully updated for ${user.first_name} ${user.last_name}`,
				ToastType.CONFIRM
			);
		} else {
			toastStore.emit(
				`Could not update role for ${user.first_name} ${user.last_name}: ${res.status} ${res.statusText}`,
				ToastType.ERROR
			);
		}
	}

	get users() {
		return this._users;
	}
}

const userStore = new UserStore();
export default userStore;
