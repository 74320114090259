import { makeAutoObservable, runInAction } from 'mobx';

class NavSidebarStore {
	private _open = false;

	constructor() {
		makeAutoObservable(this);
	}

	setOpen(isOpen: boolean) {
		runInAction(() => {
			this._open = isOpen;
		});
	}

	toggleOpen() {
		runInAction(() => {
			this._open = !this._open;
		});
	}

	get open() {
		return this._open;
	}
}

const navSidebarStore = new NavSidebarStore();
export default navSidebarStore;
