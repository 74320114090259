import { observer } from 'mobx-react-lite';
import Button from '@components/core/button/button.tsx';
import { useLoaderData } from 'react-router-dom';
import View from '@components/view/view.tsx';
import placeTagStore from '@store/place-tag-store.ts';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import modalService from '@services/modal-service.tsx';
import PlaceTagDetailsBulkAdd from '@views/place-tag-details/view-components/place-tag-details-bulk-add.tsx';
import { PlaceTagDetailsLoaderReturn } from '@views/place-tag-details/place-tag-details-loader.ts';
import PlaceStats from '@components/data/place-stats/place-stats.tsx';
import { PlaceStatType } from '@store/place-stats-store.ts';
import PlaceGenreDistribution from '@components/data/place-genre-distribution/place-genre-distribution.tsx';
import QuickData from '@components/data/quick-data/quick-data.tsx';
import GroupBy from '@utils/group-by.ts';
import { FullExperience } from '@/schemas/experience-schema.ts';
import ViewPanel from '@components/view/view-panel.tsx';
import ViewPanelSizeEnum from '@components/view/view-panel-size-enum.ts';
import LightdashDashboards from '@components/data/lightdash-dashboards/lightdash-dashboards.tsx';
import DashboardType from '@components/data/lightdash-dashboards/dashboard-type.ts';
import AverageTagCCU from '@components/data/average-tag-ccu/average-tag-ccu.tsx';
import PlaceTagDetailsAdd from '@views/place-tag-details/view-components/place-tag-details-add.tsx';
import ExperienceTable from '@components/tables/experience-table.tsx';
import { CustomCellRendererProps } from 'ag-grid-react';
import ActionsMenu from '@components/core/actions-menu/actions-menu.tsx';
import copyService from '@services/copy-service.ts';
import ModalBase from '@components/service/modal/modal-base.tsx';
import { PopulatedPlaceTag } from '@/schemas/plage-tag-schema.ts';
import toastStore from '@store/toast-store.ts';
import { ToastType } from '@components/service/toast/toast-enums.ts';
import { useEffect } from 'react';
import postHogService from '@services/posthog-service.ts';
import { PostHogEventType } from '@/enums/posthog-event.ts';

const robloxPlaceUrl = 'https://www.roblox.com/games/';

const removeExperience = async (
	populatedPlaceTag: PopulatedPlaceTag,
	place: FullExperience
) => {
	await placeTagStore.deletePlaceIdFromTag(
		populatedPlaceTag.id,
		place.place_id
	);

	toastStore.emit(
		`Successfully removed experience from tag`,
		ToastType.CONFIRM
	);
};

const PlaceTagDetails = observer(function PlaceTagDetails() {
	const { placeTagId } = useLoaderData() as PlaceTagDetailsLoaderReturn;
	const populatedPlaceTag = placeTagStore.populatedPlaceTag(+placeTagId);

	const top5Places = [...populatedPlaceTag.places]
		.sort((a, b) => b.players_online - a.players_online)
		.slice(0, 5);

	const addPlaceToTag = (id: number) => {
		const placeList = populatedPlaceTag?.places.map(
			(place) => place.place_id
		);
		placeList?.push(id);
		void placeTagStore.updatePlaceTag(populatedPlaceTag.id, {
			...populatedPlaceTag,
			place_ids: placeList,
		});
	};

	const bulkAddPlacesToTag = (placeIds: string[]) => {
		const placeList = populatedPlaceTag?.places.map(
			(place) => place.place_id
		);
		placeList?.push(...placeIds.map((placeId) => +placeId));
		void placeTagStore.updatePlaceTag(populatedPlaceTag.id, {
			...populatedPlaceTag,
			place_ids: placeList,
		});
	};

	const placesGroupedByCreator = GroupBy(
		populatedPlaceTag.places,
		(place: FullExperience) => place.creator.id
	);

	const topCreator = Object.entries(
		Object.fromEntries(placesGroupedByCreator.entries())
	)
		.map((entry) => {
			return {
				name: entry[1][0].creator.name,
				places: entry[1],
				totalPlayers: entry[1].reduce(
					(a, b) => a + b.players_online || 0,
					0
				),
			};
		})
		.sort((a, b) => b.totalPlayers - a.totalPlayers)[0];

	useEffect(() => {
		postHogService.capture({
			type: PostHogEventType.ViewTag,
			tag: populatedPlaceTag.name,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<View
			viewInfo={{
				title: populatedPlaceTag?.name ?? '',
				icon: IconEnum.TAG,
				breadCrumbs: [{ title: 'Tags', route: '/place-tags' }],
			}}
			headerChildren={
				populatedPlaceTag && (
					<div
						className={
							'w-full flex items-center justify-end flex-wrap'
						}
					>
						<Button
							title={'Add'}
							icon={{
								icon: IconEnum.ADD,
								placement: 'right',
							}}
							className={'mx-4'}
							onClick={() =>
								modalService.open({
									content: (
										<PlaceTagDetailsAdd
											tagName={
												populatedPlaceTag?.name ?? ''
											}
											submit={addPlaceToTag}
										/>
									),
									overflow: true,
								})
							}
						/>

						<div className={'border-l pl-4 dark:border-gray-400'}>
							<Button
								title={'Bulk Add'}
								icon={{
									icon: IconEnum.BULK_ADD,
									placement: 'right',
								}}
								onClick={() =>
									modalService.open({
										content: (
											<PlaceTagDetailsBulkAdd
												tagName={
													populatedPlaceTag?.name ??
													''
												}
												submit={bulkAddPlacesToTag}
											/>
										),
									})
								}
							/>
						</div>
					</div>
				)
			}
			className={'place-tag-details'}
		>
			<ViewPanel
				options={{ hideOverflow: true, noPadding: true }}
				expansionPanel={{
					title: `Experiences (${populatedPlaceTag.places.length})`,
					startExpanded: true,
				}}
			>
				<ExperienceTable
					experiences={populatedPlaceTag.places}
					contextMenu={{
						cellRenderer: (params: CustomCellRendererProps) => {
							const place = params.node.data as FullExperience;
							return (
								<ActionsMenu
									actions={[
										{
											title: 'View on roblox.com',
											onClick: () => {
												window.open(
													`${robloxPlaceUrl}${place.place_id}`
												);
											},
										},
										{
											title: 'Remove',
											onClick: () => {
												modalService.open({
													content: (
														<ModalBase
															title={`Remove experience from tag`}
															content={{
																description: `Are you sure you want to remove "${place.name}" from the tag "${populatedPlaceTag.name}"?`,
															}}
															onConfirm={async () => {
																await removeExperience(
																	populatedPlaceTag,
																	place
																);
															}}
															options={{
																confirmButtonTitle:
																	'Remove',
															}}
														/>
													),
												});
											},
										},
										{
											title: 'Copy Experience ID',
											onClick: () => {
												copyService.experienceId(place);
											},
										},
										{
											title: 'Copy Universe ID',
											onClick: () => {
												copyService.experienceUniverseId(
													place
												);
											},
										},
									]}
									width={'13rem'}
								/>
							);
						},
						minWidth: 70,
						maxWidth: 70,
						resizable: false,
						sortable: false,
						cellStyle: {
							display: 'flex',
							alignItems: 'center',
						},
						context: {
							priority: 1,
						},
					}}
					fillParent
					includeRanking={false}
				/>
			</ViewPanel>

			<ViewPanel>
				<LightdashDashboards
					dashboards={[
						{
							type: DashboardType.EXPERIENCE_INSIGHT_BY_TAG,
							tagName: populatedPlaceTag?.name,
						},
					]}
				/>
			</ViewPanel>

			{!!populatedPlaceTag.places.length && (
				<>
					<ViewPanel options={{ size: ViewPanelSizeEnum.HALF }}>
						<PlaceGenreDistribution
							places={[...populatedPlaceTag.places]}
						/>
					</ViewPanel>

					<ViewPanel
						options={{
							size: ViewPanelSizeEnum.HALF,
							centerContent: true,
						}}
					>
						<QuickData
							data={new Intl.NumberFormat(
								navigator.language
							).format(
								populatedPlaceTag.places.reduce(
									(a, b) => a + b.players_online || 0,
									0
								)
							)}
							title={'Total Players Online'}
						/>

						<QuickData
							data={new Intl.NumberFormat(
								navigator.language
							).format(
								populatedPlaceTag.places.reduce(
									(a, b) => a + b.total_visits || 0,
									0
								)
							)}
							title={'Total Visits'}
						/>

						<QuickData
							data={
								new Set(
									populatedPlaceTag.places.map(
										(place) => place.creator.id
									)
								).size
							}
							title={'Creators'}
						/>

						<QuickData
							data={topCreator?.name}
							title={'Top Creator (Players Online)'}
						/>
					</ViewPanel>

					<ViewPanel>
						<AverageTagCCU tag={populatedPlaceTag} />
					</ViewPanel>
				</>
			)}

			{!!top5Places.length && (
				<ViewPanel>
					<PlaceStats
						places={top5Places}
						statType={PlaceStatType.CCUS}
						title={'Concurrent Users (Current Top 5)'}
						options={{
							showLegend: true,
							useLineGraph: true,
							thickerLines: true,
							selectedRange: 7,
						}}
					/>
				</ViewPanel>
			)}
		</View>
	);
});

export default PlaceTagDetails;
