import { observer } from 'mobx-react-lite';
import Highcharts from 'highcharts';
import Chart from '@components/core/chart/chart.tsx';
import BrandColors from '@styles/_brand_colors.ts';
import platformStatsStore from '@store/platform-stats-store.ts';

const PlatformCCUChart = observer(function PlatformCCUChart() {
	const data = platformStatsStore.platformCCUChartData;

	const options: Highcharts.Options = {
		title: {
			text: 'Daily Roblox CCU',
		},
		series: [
			{
				type: 'area',
				name: 'Daily CCU',
				data,
				fillOpacity: 0.6,
				showInNavigator: true,
				cropThreshold: 5000,
				showInLegend: false,
			},
		],
		chart: {
			marginLeft: 45,
			events: {
				load: function () {
					if (
						this.xAxis[0] &&
						data[data.length - 1] &&
						data[data.length - 1 - 365]
					) {
						const xAxis = this.xAxis[0];
						// Set default zoom to 1 year
						xAxis.setExtremes(
							data[data.length - 1 - 365][0],
							data[data.length - 1][0]
						);
					}
				},
			},
		},
		xAxis: {
			type: 'datetime',
			minRange: 2592000000, // Minimum zoom value is 1 month (in ms)
			min: data[0][0],
			max: data[data.length - 1][0],
		},
		yAxis: {
			title: {
				enabled: false,
			} as Highcharts.YAxisOptions,
			labels: {
				x: -10,
				y: 3,
				reserveSpace: false,
				style: {
					whiteSpace: 'nowrap',
				},
			},
			tickInterval: 2000000,
			min: 0,
			softMax: 8000000,
		},
		plotOptions: {
			area: {
				marker: {
					enabled: false,
				},
			},
		},
		rangeSelector: {
			enabled: true,
			inputEnabled: false,
			buttonPosition: {
				x: -20,
			},
			buttonTheme: {
				r: 10,
				style: {
					borderWidth: '1px solid black',
				},
				fill: 'none',
				states: {
					hover: {
						fill: BrandColors.blue500,
						style: {
							color: BrandColors.white,
						},
					},
					select: {
						fill: BrandColors.gray600,
						style: {
							color: BrandColors.white,
						},
					},
				},
			},
			labelStyle: {
				visibility: 'hidden',
			},
			buttons: [
				{
					type: 'month',
					count: 1,
					text: '1m',
					title: 'View 1 month',
				},
				{
					type: 'month',
					count: 3,
					text: '3m',
					title: 'View 3 months',
				},
				{
					type: 'month',
					count: 6,
					text: '6m',
					title: 'View 6 months',
				},
				{
					type: 'ytd',
					text: 'YTD',
					title: 'View year to date',
				},
				{
					type: 'year',
					count: 1,
					text: '1y',
					title: 'View 1 year',
				},
				{
					type: 'all',
					text: 'All',
					title: 'View all',
				},
			],
		},
		scrollbar: {
			enabled: true,
			barBackgroundColor: BrandColors.gray400,
		},
		navigator: {
			enabled: true,
			xAxis: {
				min: data[0][0],
				max: data[data.length - 1][0],
			},
		},
	};

	return <Chart highcharts={Highcharts} options={options} />;
});

export default PlatformCCUChart;
