import { observer } from 'mobx-react-lite';
import { ReactElement } from 'react';
import modalService from '@services/modal-service.tsx';
import ClassString from '@utils/class-string.ts';

export interface ModalProps {
	content: ReactElement;
	overflow?: boolean;
}

const Modal = observer(function Modal(props: ModalProps) {
	return (
		<div
			className={
				'z-modal-overlay absolute top-0 left-0 h-screen w-screen flex justify-center items-center bg-black bg-opacity-50'
			}
			onClick={() => {
				modalService.close();
			}}
		>
			<div
				className={ClassString({
					static: 'flex justify-center items-center rounded-lg p-6 outline outline-1 outline-gray-500 w-[90vw] sm:w-3/5 max-w-lg shadow-lg bg-white dark:bg-gray-550 overflow-y-auto',
					dynamic: {
						'overflow-y-visible': props.overflow,
					},
				})}
				onClick={(event) => {
					event.stopPropagation();
				}}
			>
				{props.content}
			</div>
		</div>
	);
});

export default Modal;
