function extendObject(
	obj: Record<string, unknown>,
	path: string[],
	value: unknown
) {
	if (path.length === 1) {
		obj[path[0]] = value;
		return;
	}

	if (!obj[path[0]]) {
		obj[path[0]] = {};
	}

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-expect-error
	return extendObject(obj[path[0]], path.slice(1), value);
}

export default extendObject;
