import { observer } from 'mobx-react-lite';
import Icon from '@components/core/icon/icon.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import EditableText from '@components/core/editable-text/editable-text.tsx';
import { useState } from 'react';
import IsValidDate from '@utils/is-valid-date.ts';

interface DatePickerProps {
	value: string;
	onChange: (value: string) => unknown;
}

const DatePicker = observer(function DatePicker(props: DatePickerProps) {
	const [key, setKey] = useState(Math.random().toString());

	let datePickerInput: HTMLInputElement | null;
	let currentDate = '';

	const onTextChange = (text: string) => {
		props.onChange(text);

		// Force rerender when entering an invalid date
		if (!IsValidDate(text) && !IsValidDate(props.value)) {
			setKey(Math.random().toString());
		}

		// Force rerender if setting the same date
		if (new Date(text).getDate() === new Date(props.value).getDate()) {
			setKey(Math.random().toString());
		}
	};

	return (
		<div className={'flex items-center'}>
			<input
				type={'date'}
				value={props.value}
				className={'w-0 h-0 border-0'}
				ref={(ref) => (datePickerInput = ref)}
				onChange={(event) => {
					// Fires twice on change, avoid calling the props function twice
					if (currentDate !== event.target.value) {
						currentDate = event.target.value;
						props.onChange(event.target.value);
					}
				}}
			/>
			<Icon
				icon={IconEnum.CALENDAR}
				className={
					'min-w-6 min-h-6 cursor-pointer mr-1 p-0.5 rounded-full hover:text-white hover:bg-blue-500'
				}
				onClick={() => {
					if (datePickerInput) {
						datePickerInput.showPicker();
					}
				}}
			/>
			<EditableText
				key={key}
				text={props.value}
				onTextChange={onTextChange}
				hideIcon
			/>
		</div>
	);
});

export default DatePicker;
