import { observer } from 'mobx-react-lite';
import Highcharts from 'highcharts';
import Chart from '@components/core/chart/chart.tsx';
import { useCallback, useEffect, useState } from 'react';
import placeStatsStore, {
	CCUDataSegment,
	PlaceStatGranularity,
	PlaceStatType,
} from '@store/place-stats-store.ts';
import LoadingIndicator from '@components/core/loading-indicator/loading-indicator.tsx';
import { Experience, SlimExperience } from '@/schemas/experience-schema.ts';
import Button from '@components/core/button/button.tsx';
import { PlaceTag } from '@/schemas/plage-tag-schema.ts';
import Tooltip from '@components/core/tooltip/tooltip.tsx';

interface ExperienceVsTagCcuProps {
	place: Experience;
	tag: PlaceTag;
	tagExperiences: SlimExperience[];
	title: string;
	options?: {
		defaultGranularity?: PlaceStatGranularity;
		selectedRange?: number;
	};
}

const ExperienceVsTagCcu = observer(function PlaceStats(
	props: ExperienceVsTagCcuProps
) {
	const [granularity, setGranularity] = useState(
		props.options?.defaultGranularity ?? PlaceStatGranularity.DAY
	);
	const [dataSegment, setDataSegment] = useState(CCUDataSegment.ACTIVE);

	const [loading, setLoading] = useState(true);

	const data = [
		placeStatsStore.placeStat(
			props.place.place_id,
			PlaceStatType.CCUS,
			granularity
		),
		placeStatsStore.averageTagCCU(props.tag.id, granularity, dataSegment),
	];

	const noData = !loading && !data.length;

	const getData = useCallback(
		async (
			granularity: PlaceStatGranularity,
			dataSegment: CCUDataSegment
		) => {
			await Promise.all([
				placeStatsStore.getPlaceStats(
					[props.place.place_id],
					PlaceStatType.CCUS,
					granularity
				),
				placeStatsStore.getAverageTagCCU(
					props.tag,
					props.tagExperiences,
					granularity,
					dataSegment
				),
			]);
			setLoading(false);
		},
		[props.place.place_id, props.tag, props.tagExperiences]
	);

	const showColumnLabels = true;
	let series: Highcharts.SeriesOptionsType[] = [];
	series = data.map((d, index) => {
		return {
			name: index === 0 ? props.place.name : props.tag.name,
			type: 'line',
			showInLegend: true,
			data: d,
			marker: {
				enabled: false,
			},
			lineWidth: 3,
		};
	});

	let xMin = 0;
	let xMax = 0;
	data.forEach((td) => {
		if (!td) {
			return;
		}

		td.forEach((d) => {
			const date = d[0];

			if (xMin === 0) {
				xMin = date;
			}

			if (xMax === 0) {
				xMax = date;
			}

			if (date < xMin) {
				xMin = date;
			}

			if (date > xMax) {
				xMax = date;
			}
		});
	});

	const options: Highcharts.Options = {
		title: {
			text: props.title,
		},
		series,
		chart: {
			marginLeft: 45,
		},
		xAxis: {
			type: 'datetime',
			min: xMin,
			max: xMax,
		},
		yAxis: {
			title: {
				enabled: false,
			} as Highcharts.YAxisOptions,
			labels: {
				x: -10,
				y: 3,
				reserveSpace: false,
				style: {
					whiteSpace: 'nowrap',
				},
			},
		},
		plotOptions: {
			line: {
				marker: {
					enabled: false,
				},
			},
			column: {
				stacking: 'normal',
				dataLabels: {
					enabled: showColumnLabels,
				},
			},
		},
		navigator: {
			enabled: true,
			series: data.map((td) => {
				return {
					data: td,
				};
			}),
			xAxis: {
				min: xMin,
				max: xMax,
			},
		},
		scrollbar: {
			enabled: true,
			liveRedraw: true,
		},
		rangeSelector: {
			enabled: true,
			selected: props.options?.selectedRange ?? 2,
		},
	};

	const updateGranularity = (g: PlaceStatGranularity) => {
		void getData(g, dataSegment);
		setGranularity(g);
		setLoading(true);
	};

	const updateDataSegment = (s: CCUDataSegment) => {
		void getData(granularity, s);
		setDataSegment(s);
		setLoading(true);
	};

	useEffect(() => {
		void getData(granularity, dataSegment);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<div className={'relative'}>
				<Chart highcharts={Highcharts} options={options} />

				{loading && (
					<div
						className={
							'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
						}
					>
						<LoadingIndicator />
					</div>
				)}
				{noData && (
					<div
						className={
							'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
						}
					>
						No Data
					</div>
				)}
			</div>

			<div
				className={'flex items-center justify-between gap-4 flex-wrap'}
			>
				<div className={'flex items-center gap-4'}>
					<Button
						title={'Hourly'}
						onClick={() => {
							updateGranularity(PlaceStatGranularity.HOUR);
						}}
						selected={granularity === PlaceStatGranularity.HOUR}
					/>

					<Button
						title={'Daily'}
						onClick={() => {
							updateGranularity(PlaceStatGranularity.DAY);
						}}
						selected={granularity === PlaceStatGranularity.DAY}
					/>

					<Button
						title={'Weekly'}
						onClick={() => {
							updateGranularity(PlaceStatGranularity.WEEK);
						}}
						selected={granularity === PlaceStatGranularity.WEEK}
					/>

					<Button
						title={'Monthly'}
						onClick={() => {
							updateGranularity(PlaceStatGranularity.MONTH);
						}}
						selected={granularity === PlaceStatGranularity.MONTH}
					/>
				</div>

				<div className={'flex items-center gap-4 relative'}>
					<Tooltip
						text={
							'Only include experiences with active players at the specific timestamp.'
						}
						direction={'left'}
					>
						<Button
							title={'Active'}
							onClick={() =>
								updateDataSegment(CCUDataSegment.ACTIVE)
							}
							selected={dataSegment === CCUDataSegment.ACTIVE}
						/>
					</Tooltip>

					{props.tagExperiences.length > 5 && (
						<Tooltip
							text={
								'Only include the top 5 experiences in terms of current player count.'
							}
							direction={'left'}
						>
							<Button
								title={'Top 5'}
								onClick={() =>
									updateDataSegment(CCUDataSegment.TOP_FIVE)
								}
								selected={
									dataSegment === CCUDataSegment.TOP_FIVE
								}
							/>
						</Tooltip>
					)}

					<Tooltip
						text={
							'Include all experiences that was created at or after each specific timestamp.'
						}
						direction={'left'}
					>
						<Button
							title={'All'}
							onClick={() =>
								updateDataSegment(CCUDataSegment.ALL)
							}
							selected={dataSegment === CCUDataSegment.ALL}
						/>
					</Tooltip>
				</div>
			</div>
		</div>
	);
});

export default ExperienceVsTagCcu;
