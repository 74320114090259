import { observer } from 'mobx-react-lite';
import ChartColors from '@components/core/chart/chart-colors.ts';
import Icon from '@components/core/icon/icon.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import ClassString from '@utils/class-string.ts';

interface DataComparisonRowProps {
	label: string;
	dataLeft: string;
	dataRight: string;
	dataDifference?: {
		direction: 'left' | 'right' | 'none';
		data: string;
	};
	borderBottom?: boolean;
}

const DataComparisonRow = observer((props: DataComparisonRowProps) => {
	return (
		<div
			className={
				'flex justify-center items-center h-16 text-base md:text-2xl'
			}
		>
			<div
				className={ClassString({
					static: 'mr-4 flex-grow basis-0 flex justify-center items-center text-center h-16 max-h-16',
					dynamic: {
						'border-b border-solid border-gray-200':
							props.borderBottom,
					},
				})}
			>
				{props.dataLeft}
			</div>
			<div
				className={
					'flex flex-col justify-center items-center w-28 md:w-44 border-l-4 border-r-4 border-solid h-full text-xl'
				}
				style={{
					borderLeftColor: ChartColors()[0],
					borderRightColor: ChartColors()[1],
				}}
			>
				<div className={'font-semibold'}>{props.label}</div>

				{props.dataDifference && (
					<div className={'flex justify-center items-center'}>
						<div className={'w-4 mr-1 text-base'}>
							{props.dataDifference.direction === 'left' && (
								<Icon icon={IconEnum.ARROW_BACK} />
							)}
						</div>
						<div className={'text-base'}>
							{props.dataDifference.data}
						</div>
						<div className={'w-4 mr-1 text-base'}>
							{props.dataDifference.direction === 'right' && (
								<Icon icon={IconEnum.ARROW_FORWARD} />
							)}
						</div>
					</div>
				)}
			</div>
			<div
				className={ClassString({
					static: 'ml-4 flex-grow basis-0 flex justify-center items-center text-center h-16 max-h-16',
					dynamic: {
						'border-b border-solid border-gray-200':
							props.borderBottom,
					},
				})}
			>
				{props.dataRight}
			</div>
		</div>
	);
});

export default DataComparisonRow;
