import { observer } from 'mobx-react-lite';
import logo from '@assets/logo_white.svg';
import ExpansionPanel from '@components/core/expansion-panel/expansion-panel.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import NavSidebarLink from '@components/navigation/nav/nav-sidebar-link.tsx';
import { NavLink, useNavigate } from 'react-router-dom';
import ClassString from '@utils/class-string.ts';
import navSidebarStore from '@store/nav-sidebar-store.ts';
import useOnClickOutside from '@hooks/use-on-click-outside.tsx';
import Icon from '@components/core/icon/icon.tsx';
import authStore from '@store/auth-store.ts';
import { UserPermission } from '@/schemas/user-schema.ts';
import SlideToggle from '@components/core/slide-toggle/slide-toggle.tsx';
import themeStore from '@store/theme-store.ts';

enum NavBarMenuKey {
	EXPERIENCES = 'experiences',
	TOOLS = 'tools',
	COMPARE = 'compare',
}

const compareOpen = () => {
	return (
		window.location.href.includes('/compare') ||
		menuOpen(NavBarMenuKey.COMPARE)
	);
};

const toolsOpen = () => {
	return (
		window.location.href.includes('/robux') ||
		window.location.href.includes('/deep-link') ||
		window.location.href.includes('/event-credentials') ||
		menuOpen(NavBarMenuKey.TOOLS)
	);
};

const storeMenuOpen = (menuKey: NavBarMenuKey, open: boolean) => {
	return localStorage.setItem(
		`tgs-nbm-o-${menuKey}`,
		open ? 'true' : 'false'
	);
};

const menuOpen = (menuKey: NavBarMenuKey) => {
	return localStorage.getItem(`tgs-nbm-o-${menuKey}`) === 'true';
};

const NavSidebar = observer(function NavSidebar() {
	const { ref } = useOnClickOutside<HTMLElement>(() => {
		navSidebarStore.setOpen(false);
	});

	const navigate = useNavigate();

	const logoutHandler = async () => {
		navSidebarStore.setOpen(false);
		await authStore.logOut();
		navigate('/login');
	};

	if (authStore.isLoggedIn) {
		document.getElementById('root')?.classList.add('root-padding');
	} else {
		document.getElementById('root')?.classList.remove('root-padding');
	}

	return (
		<aside
			className={ClassString({
				static: '-left-sidebar lg:left-0 w-sidebar bg-gray-600 fixed -mt-nav h-screen flex flex-col z-nav-sidebar overflow-y-scroll',
				dynamic: {
					'!left-0': navSidebarStore.open,
					'!-left-sidebar': !authStore.isLoggedIn,
				},
			})}
			style={{
				transition:
					'left 400ms cubic-bezier(0.165, 0.84, 0.44, 1), visibility 0ms ease 400ms',
			}}
			ref={ref}
		>
			<div className={'py-6 pl-10 pr-6 flex justify-center items-center'}>
				<NavLink to={'/overview'} draggable="false">
					<img
						src={logo}
						alt={'logo-full'}
						className={'nav-sidebar-logo w-full max-w-lg'}
						draggable="false"
					/>
				</NavLink>
			</div>

			<div className={'flex flex-col justify-between grow'}>
				<div className={'py-4 pl-6 pr-2 mt-4 font-light'}>
					<NavSidebarLink
						title={'Experiences'}
						route={'/experiences'}
						icon={{ icon: IconEnum.PLACE, size: '30px' }}
						className={'my-2'}
					/>

					<NavSidebarLink
						title={'Tags'}
						route={'/tags'}
						icon={{ icon: IconEnum.TAG, size: '28px' }}
						className={'my-2'}
					/>

					<ExpansionPanel
						title={'Compare'}
						icon={{ icon: IconEnum.COMPARE, size: '28px' }}
						className={'text-xl text-gray-100 my-2'}
						colorOnOpen
						titleOptions={{
							titleHeight: '42px',
							titleBorderRadius: true,
						}}
						initialState={compareOpen() ? 'expanded' : 'closed'}
						onStateChange={(open) => {
							storeMenuOpen(NavBarMenuKey.COMPARE, open);
						}}
					>
						<div className={'px-6'}>
							<NavSidebarLink
								title={'Compare Experiences'}
								route={'/compare/experiences'}
								className={'my-2'}
							/>

							<NavSidebarLink
								title={'Compare Tags'}
								route={'/compare/tags'}
								className={'my-2'}
							/>

							<NavSidebarLink
								title={'Compare Experience & Tag'}
								route={'/compare/experience-tag'}
								className={'my-2'}
							/>
						</div>
					</ExpansionPanel>

					<ExpansionPanel
						title={'Tools'}
						icon={{ icon: IconEnum.TOOLS, size: '28px' }}
						className={'text-xl text-gray-100 my-2'}
						colorOnOpen
						titleOptions={{
							titleHeight: '42px',
							titleBorderRadius: true,
						}}
						initialState={toolsOpen() ? 'expanded' : 'closed'}
						onStateChange={(open) => {
							storeMenuOpen(NavBarMenuKey.TOOLS, open);
						}}
					>
						<div className={'px-6'}>
							<NavSidebarLink
								title={'Robux Converter'}
								route={'/robux'}
							/>
						</div>

						<div className={'px-6'}>
							<NavSidebarLink
								title={'Roblox Deep Link'}
								route={'/deep-link'}
							/>
						</div>

						{authStore.havePermission(
							UserPermission.EDIT_GAME_CREDENTIALS
						) && (
							<div className={'px-6'}>
								<NavSidebarLink
									title={'Event Credentials'}
									route={'/event-credentials'}
									className={'my-2'}
								/>
							</div>
						)}
					</ExpansionPanel>

					<NavSidebarLink
						title={'Code Library'}
						route={'/code-library'}
						icon={{ icon: IconEnum.DESCRIPTION, size: '28px' }}
						className={'my-2'}
					/>

					{authStore.havePermission(UserPermission.LIST_USERS) && (
						<NavSidebarLink
							title={'Users'}
							route={'/users'}
							icon={{ icon: IconEnum.GROUP, size: '28px' }}
							className={'my-2'}
						/>
					)}
				</div>

				<div className={'lg:hidden py-4 px-6 mt-4 font-light'}>
					<div
						className={
							'flex items-center justify-between text-white text-lg px-4 py-2'
						}
					>
						<div className={'mr-2 flex items-center'}>
							<Icon
								icon={IconEnum.ACCOUNT}
								size={'20px'}
								className={'mr-2'}
							/>

							<div className={'mr-2'}>
								{`${authStore.user?.first_name ?? ''} ${
									authStore.user?.last_name ?? ''
								}`}
							</div>
						</div>

						<SlideToggle
							initialState={themeStore.darkTheme}
							onToggle={(on) => {
								themeStore.setTheme(on ? 'dark' : 'light');
							}}
							icons={{
								off: IconEnum.LIGHT_MODE,
								on: IconEnum.DARK_MODE,
							}}
							className={ClassString({
								dynamic: {
									'text-gray-600': themeStore.lightTheme,
								},
							})}
						/>
					</div>

					<div
						className={
							'flex items-center text-white text-lg px-4 py-2'
						}
					>
						<div
							className={
								'nav-sidebar-link mr-2 px-4 py-2 w-full cursor-pointer'
							}
							onClick={() => {
								void logoutHandler();
							}}
						>
							Log Out
						</div>
					</div>
				</div>
			</div>
		</aside>
	);
});

export default NavSidebar;
