import { observer } from 'mobx-react-lite';
import View from '@components/view/view.tsx';
import { useLoaderData, useLocation } from 'react-router-dom';
import experienceStore from '@store/experience-store.ts';
import placeTagStore from '@store/place-tag-store.ts';
import PlaceDetailsInfo from '@views/place-details/view-components/place-details-info.tsx';
import PlaceDetailsTags from '@views/place-details/view-components/place-details-tags.tsx';
import { PlaceDetailsLoaderReturn } from '@views/place-details/place-details-loader.ts';
import PlaceStats from '@components/data/place-stats/place-stats.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import {
	PlaceStatGranularity,
	PlaceStatType,
} from '@store/place-stats-store.ts';
import ViewPanel from '@components/view/view-panel.tsx';
import LightdashDashboards from '@components/data/lightdash-dashboards/lightdash-dashboards.tsx';
import DashboardType from '@components/data/lightdash-dashboards/dashboard-type.ts';
import ExperienceLoadingTimesList from '@components/data/lightdash-dashboards/experience-loading-times-list.ts';
import { DashboardInfoType } from '@components/data/lightdash-dashboards/dashboard-info.ts';
import ExperienceDetailsRanking from '@views/place-details/view-components/experience-details-ranking.tsx';
import ChartColors from '@components/core/chart/chart-colors.ts';
import ExperienceCcuAndVisits from '@components/data/experience-ccu-and-visits/experience-ccu-and-visits.tsx';
import ExperienceDetailsRankingTable from '@views/place-details/view-components/experience-details-ranking-table.tsx';
import { useEffect } from 'react';
import { LocationState } from '@components/navigation/navigation-button/navigation-button.tsx';
import postHogService from '@services/posthog-service.ts';
import { PostHogEventType } from '@/enums/posthog-event.ts';
import { ExperienceStatus } from '@/schemas/experience-schema.ts';

const PlaceDetails = observer(function PlaceDetails() {
	const locationState = useLocation().state as LocationState;

	// Loader handles assuring there is a Place
	const { placeId } = useLoaderData() as PlaceDetailsLoaderReturn;
	const place = experienceStore.getFullExperienceById(+placeId);

	const placeTags = placeTagStore.getPlaceTagsByPlaceId(place?.place_id ?? 0);

	const dashboards: DashboardInfoType[] = [
		{
			type: DashboardType.GAME_SPECIFIC_OVERVIEW,
			placeId: place?.place_id ?? 0,
		},
	];

	if (place && ExperienceLoadingTimesList.includes(place.place_id)) {
		dashboards.push({
			type: DashboardType.EXPERIENCE_LOADING_TIMES,
			experienceCanonicalName: place.canonical_name,
		});
	}

	useEffect(() => {
		postHogService.capture({
			type: PostHogEventType.NavigateToExperience,
			from: locationState?.from.pathname ?? 'Direct Navigation',
			experience: place?.canonical_name ?? '?',
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<View
			viewInfo={{
				title: `${place?.name}` ?? 'Experience',
				icon: IconEnum.PLACE,
				breadCrumbs: [{ title: 'Experiences', route: '/places' }],
			}}
			loading={!place}
			hideHeader
		>
			{!!place && (
				<>
					<ViewPanel
						options={{ container: true }}
						className={'max-w-screen-xl'}
					>
						<PlaceDetailsInfo place={place} />
					</ViewPanel>

					<ViewPanel className={'max-w-screen-xl'}>
						<PlaceDetailsTags
							placeTags={placeTags}
							experience={place}
						/>
					</ViewPanel>

					<ViewPanel className={'max-w-screen-xl'}>
						<LightdashDashboards dashboards={dashboards} />
					</ViewPanel>

					<ViewPanel>
						<ExperienceDetailsRanking placeId={place.place_id} />

						<ExperienceDetailsRankingTable
							placeId={place.place_id}
						/>
					</ViewPanel>

					<ViewPanel>
						<ExperienceCcuAndVisits place={place} />
					</ViewPanel>

					<ViewPanel>
						<PlaceStats
							places={[place]}
							statType={PlaceStatType.LIKES}
							title={'Likes'}
							options={{
								selectedRange:
									place.play_status !==
									ExperienceStatus.PLAYABLE
										? 7
										: 2,
							}}
						/>
					</ViewPanel>

					<ViewPanel>
						<PlaceStats
							places={[place]}
							statType={PlaceStatType.RATING}
							title={'Like Percentage'}
							options={{
								fill: true,
								singleGranularity: PlaceStatGranularity.DAY,
								yPercentage: true,
								lineColor: ChartColors()[2],
							}}
						/>
					</ViewPanel>
				</>
			)}
		</View>
	);
});

export default PlaceDetails;
