import { makeAutoObservable, runInAction } from 'mobx';
import httpFetch from '@services/http-fetch-service.ts';
import { mapExperiences } from '@store/experience-store.ts';
import {
	PlaceTag,
	PlaceTagListSchema,
	PopulatedPlaceTag,
	PopulatedPlaceTagResponseSchema,
} from '@/schemas/plage-tag-schema.ts';
import toastStore from '@store/toast-store.ts';
import { ToastType } from '@components/service/toast/toast-enums.ts';
import authStore from '@store/auth-store.ts';

export const placeTagApi = `${import.meta.env.VITE_SERVER_URL}/api/placetag`;

class PlaceTagStore {
	private _placeTags: PlaceTag[] = [];
	private _loadingList = false;
	private _initialized = false;

	private _populatedPlaceTags: Record<number, PopulatedPlaceTag> = {};
	private _populatedPlaceTagLoading = false;

	constructor() {
		makeAutoObservable(this);
	}

	async listPlaceTags() {
		if (!this._loadingList) {
			this._loadingList = true;
			const response = await httpFetch.GET(placeTagApi, true, true);

			if (response.ok) {
				const placeTags = PlaceTagListSchema.parse(
					await response.json()
				);

				runInAction(() => {
					this._placeTags = placeTags.sort((a, b) => {
						return a.name.localeCompare(b.name);
					});
					this._initialized = true;
					this._loadingList = false;
				});
			} else {
				runInAction(() => {
					this._initialized = true;
					this._loadingList = false;
					if (authStore.isLoggedIn) {
						toastStore.emit(
							'Failed to load experience tags',
							ToastType.ERROR
						);
					}
				});
			}
		}
	}

	async addPlaceTag(name: string) {
		const response = await httpFetch.POST(placeTagApi, {
			name,
		});

		if (response.ok) {
			const newTag = PopulatedPlaceTagResponseSchema.parse(
				await response.json()
			);
			runInAction(() => {
				this._placeTags.push({
					id: newTag.id,
					name: newTag.name,
					place_ids: newTag.places.map((place) => place.place_id),
				});
			});

			return newTag;
		}
	}

	async deletePlaceTag(id: number) {
		const response = await httpFetch.DELETE(`${placeTagApi}/${id}`);

		if (response.ok) {
			runInAction(() => {
				this._placeTags = this._placeTags.filter(
					(tag) => tag.id !== id
				);
			});
		}
	}

	async updatePlaceTag(id: number, update: Partial<PlaceTag>) {
		const response = await httpFetch.PUT(`${placeTagApi}/${id}`, update);
		if (response.ok) {
			const updatedTag = PopulatedPlaceTagResponseSchema.parse(
				await response.json()
			);

			runInAction(() => {
				// Add id property to use as a key prop
				this._populatedPlaceTags[updatedTag.id] = {
					id: updatedTag.id,
					name: updatedTag.name,
					places: mapExperiences(updatedTag.places),
				};

				this._populatedPlaceTags[updatedTag.id].places =
					this._populatedPlaceTags[updatedTag.id].places.sort(
						(a, b) => {
							return a.name.localeCompare(b.name);
						}
					);

				this._placeTags = this._placeTags.map((tag) => {
					if (tag.id === updatedTag.id) {
						tag.name = updatedTag.name;
						tag.place_ids = updatedTag.places.map(
							(place) => place.place_id
						);
					}
					return tag;
				});
			});
		}
	}

	async addPlaceIdToTag(tagId: number, placeId: number) {
		let placeTag = this._placeTags.find((tag) => tag.id === tagId);

		if (!placeTag) {
			const populatedPlaceTag = this._populatedPlaceTags[tagId];
			if (!populatedPlaceTag) {
				return;
			}

			placeTag = {
				...populatedPlaceTag,
				place_ids: populatedPlaceTag.places.map(
					(place) => place.place_id
				),
			};
		}

		placeTag.place_ids = [...placeTag.place_ids, placeId];

		await this.updatePlaceTag(tagId, placeTag);
	}

	async deletePlaceIdFromTag(tagId: number, placeId: number) {
		let placeTag = this._placeTags.find((tag) => tag.id === tagId);

		if (!placeTag) {
			const populatedPlaceTag = this._populatedPlaceTags[tagId];
			if (!populatedPlaceTag) {
				return;
			}

			placeTag = {
				...populatedPlaceTag,
				place_ids: populatedPlaceTag.places.map(
					(place) => place.place_id
				),
			};
		}

		placeTag.place_ids = placeTag.place_ids.filter((id) => id !== placeId);

		await this.updatePlaceTag(tagId, placeTag);
	}

	async getPopulatedPlaceTag(id: number) {
		runInAction(() => {
			this._populatedPlaceTagLoading = true;
		});

		const response = await httpFetch.GET(`${placeTagApi}/${id}`);

		if (response.ok) {
			const tag = PopulatedPlaceTagResponseSchema.parse(
				await response.json()
			);
			runInAction(() => {
				tag.places = tag.places.sort((a, b) => {
					return a.name.localeCompare(b.name);
				});
				// Add id property to use as a key prop
				this._populatedPlaceTags[tag.id] = {
					id: tag.id,
					name: tag.name,
					places: mapExperiences(tag.places),
				};
			});
		}

		runInAction(() => {
			this._populatedPlaceTagLoading = false;
		});
	}

	get placeTags() {
		return this._placeTags;
	}

	get isListLoading() {
		return this._loadingList;
	}

	get isPopulatedPlaceTagLoading() {
		return this._populatedPlaceTagLoading;
	}

	populatedPlaceTag(id: number) {
		return this._populatedPlaceTags[id];
	}

	getPlaceTagById(tagId: number) {
		return this._placeTags.find((tag) => tag.id === tagId);
	}

	getPlaceTagsByPlaceId(placeId: number) {
		return this.placeTags.filter((tag) => tag.place_ids.includes(placeId));
	}

	get initialized() {
		return this._initialized;
	}
}

const placeTagStore = new PlaceTagStore();
export default placeTagStore;
