import {
	createBrowserRouter,
	Outlet,
	redirect,
	RouteObject,
} from 'react-router-dom';
import authStore from '@store/auth-store.ts';
import App from '@/app.tsx';
import Login from '@views/login/login.tsx';
import Overview from '@views/overview/overview.tsx';
import Places from '@views/places/places.tsx';
import PlaceDetails from '@views/place-details/place-details.tsx';
import PlaceTags from '@views/place-tags/place-tags.tsx';
import PlaceTagDetails from '@views/place-tag-details/place-tag-details.tsx';
import RobuxConverter from '@views/robux-converter/robux-converter.tsx';
import DeepLink from '@views/deep-link/deep-link.tsx';
import { PlaceTagDetailsLoader } from '@views/place-tag-details/place-tag-details-loader.ts';
import Error from '@views/error/error.tsx';
import { PlaceTagsLoader } from '@views/place-tags/place-tags-loader.ts';
import { PlaceDetailsLoader } from '@views/place-details/place-details-loader.ts';
import { PlacesLoader } from '@views/places/places-loader.ts';
import LoadingView from '@views/loading-view/loading-view.tsx';
import { OverviewLoader } from '@views/overview/overview-loader.ts';
import experienceStore from '@store/experience-store.ts';
import validationService from '@services/validation-service.ts';
import ExperienceComparison from '@views/experience-comparison/experience-comparison.tsx';
import Users from '@views/users/users.tsx';
import userStore from '@store/user-store.ts';
import { UserPermission } from '@/schemas/user-schema.ts';
import AuthCheck from '@views/auth-check/auth-check.tsx';
import CodeLibrary from '@views/code-library/code-library.tsx';
import EventCredentials from '@views/event-credentials/event-credentials.tsx';
import { EventCredentialsLoader } from '@views/event-credentials/event-credentials-loader.ts';
import ExperienceTagComparison from '@views/experience-tag-comparison/experience-tag-comparison.tsx';
import TagComparison from '@views/tag-comparison/tag-comparison.tsx';

// Deprecated routes, still supported for potential bookmarks
const oldPaths: RouteObject[] = [
	{
		path: 'places',
		loader: () => {
			return redirect('/experiences');
		},
		children: [
			{
				path: ':placeId',
				loader: (args) => {
					return redirect(`/experiences/${args.params.placeId}`);
				},
			},
		],
	},
	{
		path: 'place-tags',
		loader: () => {
			return redirect('/tags');
		},
		children: [
			{
				path: ':placeTagId',
				loader: (args) => {
					return redirect(`/tags/${args.params.placeTagId}`);
				},
			},
		],
	},
	{
		path: 'experiences/compare',
		loader: () => {
			return redirect(`/compare/experiences${window.location.hash}`);
		},
	},
];

const router = createBrowserRouter([
	{
		path: '',
		element: <App />,
		children: [
			{
				path: '/',
				loader: async () => {
					if (await authStore.isAuthenticated()) {
						return redirect('/overview');
					} else {
						return redirect('/login');
					}
				},
			},
			{
				path: '*',
				loader: () => {
					return redirect('/');
				},
			},
			{
				path: 'login',
				loader: async () => {
					if (await authStore.isAuthenticated()) {
						return redirect('/overview');
					}

					return null;
				},
				element: <Login />,
			},
			{
				loader: async () => {
					if (await authStore.isAuthenticated()) {
						void experienceStore.listSlimExperiences();
					}

					return null;
				},
				element: (
					<AuthCheck>
						<Outlet />
					</AuthCheck>
				),
				children: [
					{
						path: 'loading',
						element: <LoadingView />,
					},
					{
						path: 'overview',
						element: <Overview />,
						loader: OverviewLoader,
					},
					{
						path: 'experiences',
						children: [
							{
								index: true,
								element: <Places />,
								loader: PlacesLoader,
							},
							{
								path: ':placeId',
								element: <PlaceDetails />,
								loader: async ({ params }) => {
									const placeId = params.placeId;
									if (
										!placeId ||
										!validationService.databaseId(placeId)
									) {
										throw new Response(null, {
											status: 404,
										});
									}

									return await PlaceDetailsLoader(placeId);
								},
								errorElement: <Error />,
							},
						],
					},
					{
						path: 'tags',
						element: <LoadingView />,
						children: [
							{
								index: true,
								element: <PlaceTags />,
								loader: PlaceTagsLoader,
							},
							{
								path: ':placeTagId',
								element: <PlaceTagDetails />,
								loader: async ({ params }) => {
									const placeTagId = params.placeTagId;
									if (
										!placeTagId ||
										!validationService.databaseId(
											placeTagId
										)
									) {
										throw new Response(null, {
											status: 404,
										});
									}

									return await PlaceTagDetailsLoader(
										placeTagId
									);
								},
								errorElement: <Error />,
							},
							{
								path: 'compare',
								element: <ExperienceTagComparison />,
								errorElement: <Error />,
								loader: PlaceTagsLoader,
							},
						],
					},
					{
						path: 'compare/experiences',
						element: <ExperienceComparison />,
						errorElement: <Error />,
					},
					{
						path: 'compare/tags',
						element: <TagComparison />,
						errorElement: <Error />,
						loader: PlaceTagsLoader,
					},
					{
						path: 'compare/experience-tag',
						element: <ExperienceTagComparison />,
						errorElement: <Error />,
						loader: PlaceTagsLoader,
					},
					{
						path: 'robux',
						element: <RobuxConverter />,
					},
					{
						path: 'deep-link',
						element: <DeepLink />,
					},
					{
						path: 'event-credentials',
						element: <EventCredentials />,
						loader: EventCredentialsLoader,
					},
					{
						path: 'users',
						element: <Users />,
						loader: async () => {
							if (
								(await authStore.isAuthenticated()) &&
								!authStore.havePermission(
									UserPermission.LIST_USERS
								)
							) {
								return redirect('/');
							}

							await userStore.loadUsers();
							return null;
						},
					},
					{
						path: 'code-library',
						element: <CodeLibrary />,
					},
					...oldPaths,
				],
			},
		],
	},
]);

export default router;
