import { makeAutoObservable, runInAction } from 'mobx';
import httpFetch from '@services/http-fetch-service.ts';
import {
	EventCredential,
	EventCredentialListSchema,
	EventCredentialSchema,
} from '@/schemas/event-credential-schema.ts';
import toastStore from '@store/toast-store.ts';
import { ToastType } from '@components/service/toast/toast-enums.ts';

const eventCredentialApi = `${import.meta.env.VITE_SERVER_URL}/api/gamecredential`;

class EventCredentialStore {
	private _eventCredentials: EventCredential[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	async getEventCredentials() {
		const response = await httpFetch.GET(eventCredentialApi);

		if (response.ok) {
			const loadedCredentials = EventCredentialListSchema.parse(
				await response.json()
			);
			runInAction(() => {
				this._eventCredentials = loadedCredentials.reverse();
			});
		}
	}

	async createEventCredential(name: string, experienceId: number) {
		const headers: HeadersInit = {
			'Content-Type': 'application/json',
		};

		const response = await fetch(eventCredentialApi, {
			method: 'POST',
			headers,
			body: JSON.stringify({ placeId: experienceId, comment: name }),
			credentials: 'include',
		});

		if (response.ok) {
			const newCredential = EventCredentialSchema.parse(
				await response.json()
			);
			runInAction(() => {
				this._eventCredentials.unshift(newCredential);
			});

			toastStore.emit(
				`Successfully created event credential`,
				ToastType.CONFIRM
			);
			return true;
		} else {
			toastStore.emit(
				'Could not create event credential',
				ToastType.ERROR
			);
			return false;
		}
	}

	async updateName(credential: EventCredential, name: string) {
		const credentialUpdate = { ...credential, comment: name };
		const response = await this.putEventCredential(credentialUpdate);

		if (response.ok) {
			const updatedCredential = EventCredentialSchema.parse(
				await response.json()
			);
			runInAction(() => {
				toastStore.emit(
					'Successfully updated event credential name',
					ToastType.CONFIRM
				);

				this._eventCredentials = this._eventCredentials.map((cred) => {
					if (cred.game_key === updatedCredential.game_key) {
						return cred;
					}

					return cred;
				});
			});
		} else {
			toastStore.emit(
				'Could not update event credential name',
				ToastType.ERROR
			);
		}
	}

	async updateDisabled(credential: EventCredential, disabled: boolean) {
		const credentialUpdate = { ...credential, disabled };
		const response = await this.putEventCredential(credentialUpdate);

		if (response.ok) {
			const updatedCredential = EventCredentialSchema.parse(
				await response.json()
			);

			runInAction(() => {
				toastStore.emit(
					`Successfully ${disabled ? 'disabled' : 'enabled'} event credential`,
					ToastType.CONFIRM
				);

				this._eventCredentials = this._eventCredentials.map((cred) => {
					if (cred.game_key === updatedCredential.game_key) {
						return cred;
					}

					return cred;
				});
			});
		} else {
			toastStore.emit(
				`Could not ${disabled ? 'disable' : 'enable'} event credential`,
				ToastType.ERROR
			);
		}
	}

	async deleteEventCredential(credential: EventCredential) {
		const response = await fetch(
			`${eventCredentialApi}/${credential.game_key}`,
			{
				method: 'DELETE',
				credentials: 'include',
			}
		);

		if (response.ok) {
			runInAction(() => {
				this._eventCredentials = this._eventCredentials.filter(
					(cred) => cred.game_key !== credential.game_key
				);
			});
			toastStore.emit(
				'Successfully deleted event credential name',
				ToastType.CONFIRM
			);
		} else {
			toastStore.emit(
				'Could not delete event credential',
				ToastType.ERROR
			);
		}
	}

	get eventCredentials() {
		return this._eventCredentials;
	}

	private async putEventCredential(credential: EventCredential) {
		const headers: HeadersInit = {
			'Content-Type': 'application/json',
		};

		return fetch(`${eventCredentialApi}/${credential.game_key}`, {
			method: 'PUT',
			headers,
			body: JSON.stringify(credential),
			credentials: 'include',
		});
	}
}

const eventCredentialStore = new EventCredentialStore();
export default eventCredentialStore;
