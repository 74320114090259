import { observer } from 'mobx-react-lite';
import experienceRankingStore from '@store/experience-ranking-store.ts';
import Highcharts from 'highcharts';
import BrandColors from '@styles/_brand_colors.ts';
import Chart from '@components/core/chart/chart.tsx';
import { useState } from 'react';
import LoadingIndicator from '@components/core/loading-indicator/loading-indicator.tsx';
import RankingCategories from '@views/place-details/view-components/ranking-categories.ts';
import themeStore from '@store/theme-store.ts';

interface CustomChart extends Highcharts.Chart {
	customTooltip: CustomTooltip;
}

interface CustomTooltip extends Highcharts.SVGElement {
	height: number;
}

interface CustomLabel extends Highcharts.SVGElement {
	textStr: string;
	xy: {
		opacity: number;
		x: number;
		y: number;
	};
}

interface ExperienceDetailsRankingProps {
	placeId: number;
}

interface RankingData {
	start: number;
	end: number;
	name: string;
	y: number;
}

const getDescriptionByTitle = (title: string) => {
	for (const key in RankingCategories) {
		if (RankingCategories[key].title === title) {
			return RankingCategories[key].description;
		}
	}
	return 'No description';
};

const ExperienceDetailsRanking = observer(function ExperienceDetailsRanking(
	props: ExperienceDetailsRankingProps
) {
	const [loading, setLoading] = useState(true);

	void experienceRankingStore.loadExperienceRankings(props.placeId);
	const experienceRankings = experienceRankingStore.experienceRating(
		props.placeId
	);

	let data;
	const seenY: number[] = [];

	if (experienceRankings) {
		experienceRankings.forEach((ranking) => {
			if (!seenY.includes(ranking.y)) {
				seenY.push(ranking.y);
			}
		});
		seenY.sort((a, b) => a - b);
		data = experienceRankings.map((ranking) => {
			return {
				...ranking,
				start: ranking.start.getTime(),
				end: ranking.end.getTime(),
				y: seenY.indexOf(ranking.y),
			};
		});
		seenY.sort((a, b) => a - b);

		if (loading) {
			setLoading(false);
		}
	}

	const categorizedData: Record<number, RankingData[]> = {};
	if (data) {
		data.forEach((d: RankingData) => {
			const yValue = d.y;
			if (!categorizedData[yValue]) {
				categorizedData[yValue] = [];
			}
			categorizedData[yValue].push(d);
		});
	}

	const series: Highcharts.SeriesOptionsType[] = Object.values(
		categorizedData
	).map((d) => {
		return {
			name: 'Ranking',
			type: 'gantt',
			data: d,
			borderWidth: 1,
			dataLabels: {
				enabled: true,
				formatter: function () {
					return this.point.name;
				},
			},
			// Default limit is 1000 data points, overriding this will cause the scrolling to lag.
			// Maybe we should limit the returned amount in the BE?
			turboThreshold: 0,
		};
	});

	const categories = seenY.map((y) => {
		return RankingCategories[y]?.title ?? 'Unknown Category';
	});

	const navigatorData: RankingData[] = [];

	Object.values(categorizedData).forEach((dataArray) => {
		dataArray.forEach((d) => {
			if (
				!navigatorData.length ||
				navigatorData[navigatorData.length - 1].y !== d.y
			) {
				navigatorData.push(d);
			}

			const dateDifference =
				d.start - navigatorData[navigatorData.length - 1].end;
			if (dateDifference < 3600000) {
				navigatorData[navigatorData.length - 1] = {
					...navigatorData[navigatorData.length - 1],
					end: d.end,
				};
			} else {
				navigatorData.push(d);
			}
		});
	});

	const options: Highcharts.Options = {
		boost: {
			enabled: true,
			useGPUTranslations: true,
			pixelRatio: 0,
			// Chart-level boost when there are more than 5 series in the chart
			seriesThreshold: 5,
			usePreallocated: true,
		},
		title: {
			text: 'Rankings',
		},
		xAxis: {
			type: 'datetime',
		},
		yAxis: {
			title: {
				text: '',
			},
			categories,
			min: 0,
			max: seenY.length - 1,
		},
		series,
		scrollbar: {
			enabled: true,
			liveRedraw: true,
			barBackgroundColor: BrandColors.gray400,
		},
		navigator: {
			enabled: true,
			series: {
				type: 'gantt',
				data: navigatorData,
				turboThreshold: 0,
			},
			yAxis: {
				min: 0,
				max: seenY.length - 1,
				reversed: true,
			},
			height: Math.max(30, seenY.length * 13),
		},
		chart: {
			events: {
				load: function () {
					const chart = this as CustomChart;

					const yTicks = chart.yAxis[0].ticks;

					chart.customTooltip = chart.renderer
						.label('custom tooltip', 0, 0, 'callout')
						.attr({
							zIndex: 12,
							fill: themeStore.lightTheme
								? BrandColors.white
								: BrandColors.gray525,
							'stroke-width': 1,
							stroke: themeStore.lightTheme
								? BrandColors.gray600
								: BrandColors.gray500,
							padding: 12,
							r: 6,
						})
						.css({
							color: themeStore.lightTheme
								? BrandColors.gray600
								: BrandColors.white,
							pointerEvents: 'none',
							width: 500,
						})
						.add()
						.hide() as CustomTooltip;

					Object.values(yTicks).forEach((tick) => {
						const label = tick.label as CustomLabel;
						if (!label) {
							return;
						}

						const labelHeight = label.getBBox().height;

						label.on('mouseover', () => {
							chart.customTooltip
								.attr({
									text: `${getDescriptionByTitle(label.textStr)}`,
									x: label.xy.x + label.textStr.length * 3.5,
									y:
										label.xy.y -
										labelHeight / 4 -
										chart.customTooltip.height / 2,
									anchorX: label.xy.x,
									anchorY: label.xy.y - labelHeight / 4,
								})
								.show();
						});

						label.on('mouseout', () => {
							chart.customTooltip.hide();
						});
					});
				},
			},
		},
	};

	return (
		<>
			{loading && (
				<div className={'h-32 flex justify-center items-center'}>
					<LoadingIndicator />
				</div>
			)}

			{!loading && !experienceRankings?.length && (
				<span className={'h-32 flex justify-center items-center'}>
					No Ranking Data
				</span>
			)}

			{!loading && !!experienceRankings?.length && (
				<Chart
					highcharts={Highcharts}
					constructorType={'ganttChart'}
					options={options}
				/>
			)}
		</>
	);
});

export default ExperienceDetailsRanking;
