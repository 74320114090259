import { observer } from 'mobx-react-lite';
import Highcharts from 'highcharts';
import Chart from '@components/core/chart/chart.tsx';
import { useCallback, useEffect, useState } from 'react';
import placeStatsStore, {
	PlaceStatGranularity,
	PlaceStatType,
} from '@store/place-stats-store.ts';
import LoadingIndicator from '@components/core/loading-indicator/loading-indicator.tsx';
import { Experience } from '@/schemas/experience-schema.ts';
import hexToRGBA from '@utils/hex-to-rgba.ts';
import ChartColors from '@components/core/chart/chart-colors.ts';
import Button from '@components/core/button/button.tsx';

interface PlaceStatsProps {
	places: Experience[];
	statType: PlaceStatType;
	title: string;
	options?: {
		singleGranularity?: PlaceStatGranularity;
		defaultGranularity?: PlaceStatGranularity;
		yPercentage?: boolean;
		fill?: boolean;
		showLegend?: boolean;
		thickerLines?: boolean;
		lineColor?: string;
		useLineGraph?: boolean;
		selectedRange?: number;
	};
}

interface PlaceData {
	placeId: number;
	placeName: string;
	data: number[][];
}

const PlaceStats = observer(function PlaceStats(props: PlaceStatsProps) {
	const [granularity, setGranularity] = useState(
		props.options?.defaultGranularity ??
			props.options?.singleGranularity ??
			PlaceStatGranularity.DAY
	);
	const [loading, setLoading] = useState(true);

	const mappedData = props.places.map((place) => {
		return {
			placeId: place.place_id,
			placeName: place.name,
			data: placeStatsStore.placeStat(
				place.place_id,
				props.statType,
				granularity
			),
		};
	});
	const data = mappedData.filter(
		(loadedData) => !!loadedData.data
	) as PlaceData[];

	const noData = !loading && !data.length;

	const getData = useCallback(
		async (tf: PlaceStatGranularity) => {
			await placeStatsStore.getPlaceStats(
				props.places.map((place) => place.place_id),
				props.statType,
				tf
			);
			setLoading(false);
		},
		[props.places, props.statType]
	);

	let yMin = undefined;
	let yMax = undefined;

	if (props.statType === PlaceStatType.RATING) {
		for (const innerData of data) {
			const rawData = innerData.data
				.map((data) => data[1])
				.sort((a, b) => a - b);

			const min = Math.max(rawData[0] - 0.5, 0);
			if (!yMin || min < yMin) {
				yMin = min;
			}

			const max = Math.min(rawData.reverse()[0] + 0.5, 100);
			if (!yMax || max > yMax) {
				yMax = max;
			}
		}
	}

	let showColumnLabels = true;
	let series: Highcharts.SeriesOptionsType[] = [];
	if (props.statType === PlaceStatType.LIKES) {
		if (data[0]?.data) {
			showColumnLabels = data[0].data.length < 2000;
			const likesData: { likes: number[][]; dislikes: number[][] } = {
				likes: [],
				dislikes: [],
			};
			data[0].data.forEach((placeData) => {
				likesData.likes.push([placeData[0], placeData[1]]);
				likesData.dislikes.push([placeData[0], placeData[2]]);
			});

			series = [
				{
					name: 'Likes',
					type: 'column',
					data: likesData.likes,
					showInLegend: !!props.options?.showLegend,
					color: ChartColors()[2],
				},
				{
					name: 'Dislikes',
					type: 'column',
					data: likesData.dislikes,
					showInLegend: !!props.options?.showLegend,
					color: ChartColors()[1],
				},
			];
		}
	} else {
		series = data.map((placeData) => {
			return {
				name: placeData.placeName,
				type: props.options?.useLineGraph ? 'line' : 'area',
				showInLegend: !!props.options?.showLegend,
				data: placeData.data ?? [],
				marker: {
					enabled: false,
				},
				fillOpacity: props.options?.fill ? 0.6 : 0,
				lineWidth: props.options?.thickerLines ? 3 : 2,
				lineColor: props.options?.lineColor,
				fillColor:
					props.options?.lineColor && props.options?.fill
						? hexToRGBA(props.options.lineColor, 0.6)
						: undefined,
			};
		});
	}

	let xMin = 0;
	let xMax = 0;
	data.forEach((pd) => {
		pd.data.forEach((d) => {
			const date = d[0];

			if (xMin === 0) {
				xMin = date;
			}

			if (xMax === 0) {
				xMax = date;
			}

			if (date < xMin) {
				xMin = date;
			}

			if (date > xMax) {
				xMax = date;
			}
		});
	});

	const options: Highcharts.Options = {
		title: {
			text: props.title,
		},
		series,
		chart: {
			marginLeft: 45,
		},
		xAxis: {
			type: 'datetime',
			min: xMin,
			max: xMax,
		},
		yAxis: {
			title: {
				enabled: false,
			} as Highcharts.YAxisOptions,
			labels: {
				x: props.statType === PlaceStatType.RATING ? -5 : -10,
				y: 3,
				reserveSpace: false,
				style: {
					whiteSpace: 'nowrap',
				},
				formatter: props.options?.yPercentage
					? function () {
							return `${this.value}%`;
						}
					: undefined,
			},
			min: yMin,
			max: yMax,
		},
		plotOptions: {
			line: {
				marker: {
					enabled: false,
				},
			},
			column: {
				stacking: 'normal',
				dataLabels: {
					enabled: showColumnLabels,
				},
			},
		},
		navigator: {
			enabled: props.statType !== PlaceStatType.RATING,
			series:
				props.statType === PlaceStatType.LIKES
					? data.map((pd) => {
							return {
								data: pd.data.map((placeData) => [
									placeData[0],
									placeData[2],
								]),
								color: ChartColors()[1],
							};
						})
					: data.map((pd) => {
							return {
								data: pd.data,
							};
						}),
			xAxis: {
				min: xMin,
				max: xMax,
			},
		},
		scrollbar: {
			enabled: props.statType !== PlaceStatType.RATING,
			liveRedraw: true,
		},
		rangeSelector: {
			enabled: props.statType !== PlaceStatType.RATING,
			selected: props.options?.selectedRange ?? 2,
		},
	};

	const updateGranularity = (g: PlaceStatGranularity) => {
		void getData(g);
		setGranularity(g);
		setLoading(true);
	};

	useEffect(() => {
		void getData(granularity);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<div className={'relative'}>
				<Chart highcharts={Highcharts} options={options} />

				{loading && (
					<div
						className={
							'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
						}
					>
						<LoadingIndicator />
					</div>
				)}
				{noData && (
					<div
						className={
							'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
						}
					>
						No Data
					</div>
				)}
			</div>

			<div className={'flex gap-4'}>
				{(!props.options?.singleGranularity ||
					props.options.singleGranularity ===
						PlaceStatGranularity.HOUR) && (
					<Button
						title={'Hourly'}
						onClick={() => {
							updateGranularity(PlaceStatGranularity.HOUR);
						}}
						selected={granularity === PlaceStatGranularity.HOUR}
					/>
				)}

				{(!props.options?.singleGranularity ||
					props.options.singleGranularity ===
						PlaceStatGranularity.DAY) && (
					<Button
						title={'Daily'}
						onClick={() => {
							updateGranularity(PlaceStatGranularity.DAY);
						}}
						selected={granularity === PlaceStatGranularity.DAY}
					/>
				)}

				{(!props.options?.singleGranularity ||
					props.options.singleGranularity ===
						PlaceStatGranularity.WEEK) && (
					<Button
						title={'Weekly'}
						onClick={() => {
							updateGranularity(PlaceStatGranularity.WEEK);
						}}
						selected={granularity === PlaceStatGranularity.WEEK}
					/>
				)}

				{(!props.options?.singleGranularity ||
					props.options.singleGranularity ===
						PlaceStatGranularity.MONTH) && (
					<Button
						title={'Monthly'}
						onClick={() => {
							updateGranularity(PlaceStatGranularity.MONTH);
						}}
						selected={granularity === PlaceStatGranularity.MONTH}
					/>
				)}
			</div>
		</div>
	);
});

export default PlaceStats;
