function IsValidJson(jsonString: string): boolean {
	try {
		const jsonObject = JSON.parse(jsonString) as object;

		return !!jsonObject && typeof jsonObject === 'object';
	} catch (_) {
		// Expected for non-json strings
	}

	return false;
}

export default IsValidJson;
