import { makeAutoObservable, runInAction } from 'mobx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';

export interface ViewInfo {
	title: string;
	icon?: IconEnum;
	iconSize?: string;
	breadCrumbs?: {
		title: string;
		route?: string;
	}[];
}

class ViewStore {
	private _viewInfo: ViewInfo | undefined;

	constructor() {
		makeAutoObservable(this);
	}

	resetViewInfo() {
		runInAction(() => {
			this._viewInfo = undefined;
		});
	}

	setViewInfo(viewInfo: ViewInfo) {
		runInAction(() => {
			this._viewInfo = viewInfo;
		});
	}

	get viewInfo() {
		return this._viewInfo;
	}
}

const viewStore = new ViewStore();
export default viewStore;
