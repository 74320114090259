import { observer } from 'mobx-react-lite';
import Icon from '@components/core/icon/icon.tsx';
import {
	Experience,
	ExperienceCreatorType,
} from '@/schemas/experience-schema.ts';
import { IconEnum } from '@components/core/icon/icon-enum.ts';

interface PlaceCreatorProps {
	place: Experience;
}

const PlaceCreator = observer(function PlaceCreator(props: PlaceCreatorProps) {
	return (
		<div className={'flex items-center justify-start w-full max-w-fit'}>
			<div className={'truncate'}>{props.place.creator.name}</div>
			<span title={props.place.creator.type} className={'pl-2'}>
				<Icon
					icon={
						props.place.creator.type === ExperienceCreatorType.GROUP
							? IconEnum.GROUP
							: IconEnum.USER
					}
					size={
						props.place.creator.type === ExperienceCreatorType.GROUP
							? '24px'
							: '20px'
					}
				/>
			</span>
		</div>
	);
});

export default PlaceCreator;
