import { z } from 'zod';

export interface PlaceStatsSeries {
	place_id: number;
	name: string;
	data: number[][];
}

export interface PlaceTagStatsSeries {
	data: number[][];
}

interface PlaceStats {
	stat: string;
	series: PlaceStatsSeries[];
}

interface PlaceTagStats {
	stat: string;
	series: PlaceTagStatsSeries[];
}

export const PlaceStatsSeriesSchema: z.ZodType<PlaceStatsSeries> = z.object({
	place_id: z.number(),
	name: z.string(),
	data: z.array(z.array(z.number())),
});

export const PlaceStatsSchema: z.ZodType<PlaceStats> = z.object({
	stat: z.string(),
	series: z.array(PlaceStatsSeriesSchema),
});

export const PlaceTagStatsSeriesSchema: z.ZodType<PlaceTagStatsSeries> =
	z.object({
		data: z.array(z.array(z.number())),
	});

export const PlaceTagStatsSchema: z.ZodType<PlaceTagStats> = z.object({
	stat: z.string(),
	series: z.array(PlaceTagStatsSeriesSchema),
});
