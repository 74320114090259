function IsValidDate(date: string) {
	try {
		new Date(date).toISOString();
		return true;
	} catch (_) {
		return false;
	}
}

export default IsValidDate;
