import { observer } from 'mobx-react-lite';
import ExpansionInput from '@components/core/expansion-input/expansion-input.tsx';
import { ChangeEvent, RefObject } from 'react';
import RobloxUrlToPlaceId from '@utils/roblox-url-to-place-id.ts';
import ClassString from '@utils/class-string.ts';
import Icon from '@components/core/icon/icon.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import { PlaceTag, PopulatedPlaceTag } from '@/schemas/plage-tag-schema.ts';
import placeTagStore from '@store/place-tag-store.ts';

interface TagExpansionInputProps {
	searchTerm: string;
	setSearchTerm: (searchTerm: string) => void;
	tag: PlaceTag | PopulatedPlaceTag | undefined;
	setTag: (tag: PlaceTag | undefined) => unknown;
	className?: string;
	elementToFocus?: RefObject<HTMLElement>;
	width?: string;
	label?: string;
	scroll?: boolean;
	filteredTagIds?: number[];
}

const TagExpansionInput = observer(function PlaceExpansionInput(
	props: TagExpansionInputProps
) {
	const filteredTagIds = props.filteredTagIds ?? [];

	const searchFilter = (tag: PlaceTag): boolean => {
		return tag.name.toLowerCase().includes(props.searchTerm.toLowerCase());
	};

	const matchingTags = placeTagStore.placeTags.filter((tag) => {
		return !filteredTagIds.includes(tag.id) && searchFilter(tag);
	});

	const onInput = (event: ChangeEvent<HTMLInputElement>) => {
		const extractedPlaceId = RobloxUrlToPlaceId(event.target.value);
		if (extractedPlaceId) {
			props.setSearchTerm(extractedPlaceId);
			return;
		}
		props.setSearchTerm(event.target.value);
	};

	const onBlur = () => {
		if (props.searchTerm && matchingTags.length === 1) {
			props.setSearchTerm(matchingTags[0].name);
			setCurrentTag(matchingTags[0]);
		} else {
			setCurrentTag(undefined);
		}
	};

	const onFocus = () => {
		setCurrentTag(undefined);
	};

	const setCurrentTag = (tag: PlaceTag | undefined) => {
		props.setTag(tag);
	};

	return (
		<div
			className={ClassString({
				static: 'relative w-fit',
				dynamic: {},
				custom: props.className,
			})}
		>
			{!!props.tag && (
				<div
					className={
						'absolute w-7 mr-2 top-1.5 left-1.5 z-in-front-3'
					}
				>
					<Icon icon={IconEnum.TAG} size={'1.5rem'} />
				</div>
			)}

			{!!props.searchTerm && (
				<Icon
					icon={IconEnum.CLEAR}
					size={'1.75rem'}
					onClick={() => {
						props.setSearchTerm('');
						setCurrentTag(undefined);
					}}
					className={
						'absolute top-1 right-1.5 p-0.5 rounded-full z-in-front-3 cursor-pointer hover:bg-blue-500 hover:text-white'
					}
				/>
			)}

			<ExpansionInput
				label={props.label ?? 'Tag'}
				value={props.searchTerm}
				onInput={onInput}
				onBlur={onBlur}
				onFocus={onFocus}
				width={props.width ?? '230px'}
				elementToFocus={props.elementToFocus}
				suggestionLimit={9}
				suggestions={matchingTags.map((tag) => {
					return {
						value: (
							<div className={'flex items-center'}>
								<div className={'truncate pr-2'}>
									{tag.name}
								</div>
							</div>
						),
						title: tag.name,
						onClick: () => {
							setCurrentTag(tag);
							props.setSearchTerm(tag.name);
						},
					};
				})}
				inputClassName={ClassString({
					static: '!h-9 !pr-8',
					dynamic: {
						'!pl-9 !rounded-l-md': !!props.tag,
					},
				})}
				scroll={props.scroll}
			/>
		</div>
	);
});

export default TagExpansionInput;
