import { observer } from 'mobx-react-lite';
import PlaceExpansionInput from '@components/data/place-expansion-input/place-expansion-input.tsx';
import useHashQueryParams from '@hooks/use-hash-query-params.tsx';
import experienceStore from '@store/experience-store.ts';
import { useEffect, useState } from 'react';
import { Experience } from '@/schemas/experience-schema.ts';
import PlaceThumbnail from '@components/data/place-thumbnail/place-thumbnail.tsx';
import { PlaceThumbnailSize } from '@components/data/place-thumbnail/place-thumbnail-constants.ts';
import ChartColors from '@components/core/chart/chart-colors.ts';

interface ExperienceSelectorProps {
	experience: Experience | undefined;
	setExperience: (place: Experience | undefined) => unknown;
	index: number;
}

const ExperienceSelector = observer((props: ExperienceSelectorProps) => {
	const [searchParams, setSearchParams] = useHashQueryParams();
	const exp = experienceStore.getSlimExperienceById(
		+searchParams[`exp-${props.index}`]
	);

	useEffect(() => {
		props.setExperience(exp);
	}, [exp, props]);

	const [searchTerm, setSearchTerm] = useState(exp ? exp.name : '');

	const setExperience = (place?: Experience) => {
		setSearchParams({
			...searchParams,
			[`exp-${props.index}`]: place ? place.place_id.toString() : '',
		});
	};

	useEffect(() => {
		if (props.experience && searchTerm !== props.experience.name) {
			setSearchTerm(props.experience.name);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.experience]);

	const borderColor =
		props.index <= ChartColors().length
			? ChartColors()[props.index - 1]
			: ChartColors()[0];

	return (
		<div
			className={
				'border dark:border-gray-500 p-6 rounded-xl flex flex-col items-center m-2'
			}
		>
			<PlaceExpansionInput
				searchTerm={searchTerm}
				setSearchTerm={setSearchTerm}
				place={props.experience}
				setPlace={setExperience}
			/>

			<div
				className={'flex flex-col items-center justify-between h-full'}
			>
				<div
					className={`w-48 h-48 mt-4 rounded-lg overflow-hidden shadow-lg bg-gray-200 dark:bg-gray-500 border-4`}
					style={{ borderColor }}
				>
					{props.experience && (
						<PlaceThumbnail
							place={props.experience}
							size={PlaceThumbnailSize.LARGE}
						/>
					)}
				</div>

				<div
					className={
						'w-64 text-center break-words font-semibold mt-2'
					}
				>
					{props.experience?.name ?? '. . .'}
				</div>
			</div>
		</div>
	);
});

export default ExperienceSelector;
