function RobloxUrlToPlaceId(url: string) {
	if (url.includes('roblox.com/games/')) {
		const placeIdUrl = url.split('roblox.com/games/').pop();
		return placeIdUrl?.split('/')[0] ?? url;
	}

	return url;
}

export default RobloxUrlToPlaceId;
