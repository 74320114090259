import BrandColors from '@styles/_brand_colors.ts';
import themeStore from '@store/theme-store.ts';

const ChartColors = () => {
	if (themeStore.lightTheme) {
		return [
			BrandColors.blue500,
			BrandColors.red400,
			BrandColors.green500,
			BrandColors.yellow500,
			BrandColors.gray600,
			BrandColors.blue400,
			BrandColors.red300,
			BrandColors.green400,
			BrandColors.yellow400,
			BrandColors.blue300,
			BrandColors.pink500,
			BrandColors.gray500,
		];
	}

	return [
		BrandColors.blue400,
		BrandColors.red400,
		BrandColors.green500,
		BrandColors.yellow500,
		BrandColors.blue300,
		BrandColors.pink400,
		BrandColors.gray500,
		BrandColors.blue300,
		BrandColors.red300,
		BrandColors.green300,
		BrandColors.yellow300,
	];
};

export default ChartColors;
