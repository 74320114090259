export enum DashboardType {
	GAME_SPECIFIC_OVERVIEW = 'game_specific_overview',
	EXPERIENCE_INSIGHT_BY_TAG = 'experience_insight_by_tag',
	TOP_100_WEEKLY = 'top_100_weekly',
	COMPARE_EXPERIENCES = 'compare_experiences',
	EXPERIENCE_LOADING_TIMES = 'experience_loading_times',
	TAGS_OVERVIEW = 'tags_overview',
	COMPARE_EXPERIENCE_AND_TAG = 'compare_experience_and_tag',
}

export default DashboardType;
