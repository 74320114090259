import { observer } from 'mobx-react-lite';
import { Experience } from '@/schemas/experience-schema.ts';
import Highcharts from 'highcharts';
import Chart from '@components/core/chart/chart.tsx';

interface PlaceLikeDistributionProps {
	places: Experience[];
}

interface PlaceLikeDistributionData {
	'90To100': Experience[];
	'80To89': Experience[];
	'70To79': Experience[];
	'60To69': Experience[];
	'50To59': Experience[];
	'40To49': Experience[];
	'30To39': Experience[];
	'20To29': Experience[];
	'10To19': Experience[];
	'0To9': Experience[];
}

const PlaceLikeDistribution = observer(function PlaceLikeDistribution(
	props: PlaceLikeDistributionProps
) {
	const placeLikeData: PlaceLikeDistributionData = {
		'90To100': [],
		'80To89': [],
		'70To79': [],
		'60To69': [],
		'50To59': [],
		'40To49': [],
		'30To39': [],
		'20To29': [],
		'10To19': [],
		'0To9': [],
	};

	// Filter out places that are not actively tracked
	const filteredPlaces = props.places.filter(
		(place) => !!place.like_percentage && !!place.total_visits
	);

	for (const place of filteredPlaces) {
		const likePercentage = place.like_percentage * 100;

		if (likePercentage >= 90) {
			placeLikeData['90To100'].push(place);
			continue;
		}
		if (likePercentage >= 80) {
			placeLikeData['80To89'].push(place);
			continue;
		}
		if (likePercentage >= 70) {
			placeLikeData['70To79'].push(place);
			continue;
		}
		if (likePercentage >= 60) {
			placeLikeData['60To69'].push(place);
			continue;
		}
		if (likePercentage >= 50) {
			placeLikeData['50To59'].push(place);
			continue;
		}
		if (likePercentage >= 40) {
			placeLikeData['40To49'].push(place);
			continue;
		}
		if (likePercentage >= 30) {
			placeLikeData['30To39'].push(place);
			continue;
		}
		if (likePercentage >= 20) {
			placeLikeData['20To29'].push(place);
			continue;
		}
		if (likePercentage >= 10) {
			placeLikeData['10To19'].push(place);
			continue;
		}

		placeLikeData['0To9'].push(place);
	}

	const data = Object.values(placeLikeData).map(
		(placeArray: Experience[]) => placeArray.length
	);

	const options: Highcharts.Options = {
		title: {
			text: 'Like Rate of Actively Tracked',
		},
		xAxis: {
			categories: [
				'90% - 100%',
				'80% - 89%',
				'70% - 79%',
				'60% - 69%',
				'50% - 59%',
				'40% - 49%',
				'30% - 39%',
				'20% - 29%',
				'10% - 19%',
				'0% - 9%',
			],
			title: {
				text: null,
			},
			gridLineWidth: 0,
			lineWidth: 0,
		},
		series: [
			{
				name: 'Experiences',
				type: 'bar',
				data,
				pointWidth: 20,
			},
		],
		rangeSelector: {
			enabled: false,
		},
	};

	return (
		<div>
			<Chart highcharts={Highcharts} options={options} />
		</div>
	);
});

export default PlaceLikeDistribution;
