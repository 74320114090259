import { observer } from 'mobx-react-lite';
import Icon from '@components/core/icon/icon.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import { useCallback, useEffect, useState } from 'react';
import GetPlatform, { Platform } from '@utils/get-platform.ts';
import ClassString from '@utils/class-string.ts';

interface SearchBarProps {
	onSearchUpdate: (search: string) => unknown;
	placeholder: string;
	className?: string;
}

const SearchBar = observer(function SearchBar(props: SearchBarProps) {
	const [inputElement, setInputElement] = useState<HTMLDivElement | null>();
	const [searchTerm, setSearchTerm] = useState('');

	const focusSearchInput = useCallback(() => {
		if (inputElement) {
			inputElement.focus();
		}
	}, [inputElement]);

	const searchHasFocus = useCallback(() => {
		return inputElement && document.activeElement === inputElement;
	}, [inputElement]);

	useEffect(() => {
		const onKeyDown = (event: KeyboardEvent) => {
			// Start Search
			if (GetPlatform() === Platform.MAC) {
				if (event.getModifierState('Meta') && event.key === 'f') {
					if (!searchHasFocus()) {
						event.preventDefault();
						focusSearchInput();
					}
				}
			} else {
				if (event.getModifierState('Control') && event.key === 'f') {
					if (!searchHasFocus()) {
						event.preventDefault();
						focusSearchInput();
					}
				}
			}
		};
		document.addEventListener('keydown', onKeyDown);

		return () => {
			document.removeEventListener('keydown', onKeyDown);
		};
	}, [focusSearchInput, searchHasFocus]);

	return (
		<div
			className={ClassString({
				static: 'w-full relative group',
				dynamic: {},
				custom: props.className,
			})}
		>
			<Icon
				icon={IconEnum.SEARCH}
				size={'2em'}
				className={
					'absolute left-3 top-1/2 -translate-y-1/2 rounded-full pointer-events-none group-focus-within:text-blue-500 dark:group-focus-within:text-blue-100'
				}
			/>

			<input
				className={
					'py-2 pl-14 dark:bg-gray-525 rounded-full w-full text-lg border border-gray-600 dark:border-gray-500 outline-none focus:shadow-focus focus:border-blue-500 dark:focus:shadow-focus-dark dark:focus:border-blue-100'
				}
				value={searchTerm}
				onChange={(event) => {
					setSearchTerm(event.target.value);
					props.onSearchUpdate(event.target.value);
				}}
				placeholder={props.placeholder}
				ref={(ref) => setInputElement(ref)}
			/>

			{!!searchTerm.length && (
				<Icon
					icon={IconEnum.CLEAR}
					size={'2em'}
					className={
						'absolute right-2 top-1/2 -translate-y-1/2 cursor-pointer hover:bg-blue-500 hover:text-white rounded-full'
					}
					onClick={() => {
						setSearchTerm('');
						props.onSearchUpdate('');
					}}
				/>
			)}
		</div>
	);
});

export default SearchBar;
