import { z } from 'zod';

export interface PlatformCCUResponse {
	ccu: number;
	time: string; // Date string
}

export const PlatformCCUResponseSchema: z.ZodType<PlatformCCUResponse> =
	z.object({
		ccu: z.number(),
		time: z.string(),
	});

export const PlatformCCUListResponseSchema: z.ZodType<PlatformCCUResponse[]> =
	z.array(PlatformCCUResponseSchema);

export interface PlatformCCU {
	ccu: number;
	time: Date;
}
