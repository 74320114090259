import { observer } from 'mobx-react-lite';
import View from '@components/view/view.tsx';
import LoadingIndicator from '@components/core/loading-indicator/loading-indicator.tsx';
import { Outlet, useNavigation } from 'react-router-dom';

const LoadingView = observer(function LoadingView() {
	const { state } = useNavigation();

	return (
		<>
			{state === 'loading' && (
				<View fullHeight hideHeader center>
					<LoadingIndicator />
				</View>
			)}

			{state !== 'loading' && <Outlet />}
		</>
	);
});

export default LoadingView;
