import { ChangeEvent, useState } from 'react';
import IsValidJson from '@utils/is-valid-json.ts';
import Button from '@components/core/button/button.tsx';
import CopyToClipboard from '@utils/copy-to-clipboard.ts';
import { BUTTON_STYLE } from '@components/core/button/button-style-enum.ts';
import TextArea from '@components/core/text-area/text-area.tsx';
import View from '@components/view/view.tsx';
import { observer } from 'mobx-react-lite';
import toastStore from '@store/toast-store.ts';
import { ToastType } from '@components/service/toast/toast-enums.ts';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import ViewPanel from '@components/view/view-panel.tsx';
import { Experience } from '@/schemas/experience-schema.ts';
import PlaceExpansionInput from '@components/data/place-expansion-input/place-expansion-input.tsx';
import validationService from '@services/validation-service.ts';
import postHogService from '@services/posthog-service.ts';
import { PostHogEventType } from '@/enums/posthog-event.ts';

const DeepLink = observer(function DeepLin() {
	const [searchTerm, setSearchTerm] = useState('');
	const [place, setPlace] = useState<Experience | undefined>();
	const [jsonString, setJsonString] = useState('');
	const [validJsonObject, setValidJsonObject] = useState(false);

	const launchDataError = !validJsonObject && !!jsonString.length;

	const generateRobloxLink = (useRoBloxLink: boolean) => {
		if (!place && !validationService.number(searchTerm)) {
			return;
		}

		let launchData = jsonString;
		try {
			launchData = JSON.stringify(JSON.parse(jsonString));
		} catch (_) {
			// Use plain string if not JSON
		}

		postHogService.capture({
			type: PostHogEventType.GenerateDeepLink,
			link_type: useRoBloxLink ? 'ro.blox.com' : 'roblox.com',
		});

		if (useRoBloxLink) {
			CopyToClipboard(
				`https://ro.blox.com/Ebh5?af_dp=roblox://placeId=${place?.place_id ?? searchTerm}&launchData=${btoa(launchData)}&af_web_dp=https://www.roblox.com/games/start?placeId=${place?.place_id ?? searchTerm}&launchData=${btoa(launchData)}&deep_link_value=roblox://placeId=${place?.place_id ?? searchTerm}&launchData=${btoa(launchData)}`
			);
		} else {
			CopyToClipboard(
				`https://www.roblox.com/games/start?placeId=${place?.place_id ?? searchTerm}&launchData=${btoa(launchData)}`
			);
		}
		toastStore.emit(
			'Deep link has been copied to your clipboard.',
			ToastType.INFO
		);
	};

	const handleJsonChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
		setJsonString(event.target.value);
		setValidJsonObject(IsValidJson(event.target.value));
	};

	return (
		<View
			viewInfo={{
				title: 'Roblox Deep Link',
				icon: IconEnum.TOOLS,
				iconSize: '28px',
				breadCrumbs: [{ title: 'Tools' }],
			}}
			className={'max-w-screen-md'}
			hideHeader
		>
			<ViewPanel
				className={
					'flex flex-col md:flex-row items-center justify-evenly gap-4 md:gap-0'
				}
			>
				<div className={'max-w-80'}>
					<div className={'mb-4'}>
						Select an experience (by name or ID) and provide JSON
						formatted launch data to copy a Roblox launch URL to
						your clipboard.
					</div>
					<div className={'mb-4'}>
						{
							"roblox.com links are shorter but will not prompt the user to install Roblox if it's not already installed."
						}
					</div>
					<div>
						{
							"ro.blox.com links are longer and will prompt the user to install Roblox if it's not installed on their system."
						}
					</div>
				</div>

				<div className={'flex flex-col'}>
					<PlaceExpansionInput
						searchTerm={searchTerm}
						setSearchTerm={setSearchTerm}
						place={place}
						setPlace={setPlace}
						className={'my-4'}
					/>

					<div className={'mb-8 mt-2 relative'}>
						<TextArea
							label={'Launch Data'}
							value={jsonString}
							onChange={handleJsonChange}
							minHeight={'120px'}
						/>
						{launchDataError && (
							<div
								className={
									'absolute bottom-3 right-4 text-sm text-red-500'
								}
							>
								invalid json
							</div>
						)}
					</div>

					<Button
						title={'Copy roblox.com Link'}
						disabled={
							!place && !validationService.number(searchTerm)
						}
						buttonStyle={BUTTON_STYLE.PRIMARY}
						onClick={() => {
							generateRobloxLink(false);
						}}
					/>

					<Button
						title={'Copy ro.blox.com Link'}
						disabled={
							!place && !validationService.number(searchTerm)
						}
						buttonStyle={BUTTON_STYLE.PRIMARY}
						onClick={() => {
							generateRobloxLink(true);
						}}
						className={'mt-4'}
					/>
				</div>
			</ViewPanel>
		</View>
	);
});

export default DeepLink;
