import { observer } from 'mobx-react-lite';
import {
	ChangeEvent,
	CSSProperties,
	ReactNode,
	RefObject,
	useCallback,
	useEffect,
	useRef,
} from 'react';
import InputText from '@components/core/input-text/input-text.tsx';
import ClassString from '@utils/class-string.ts';
import GetPlatform, { Platform } from '@utils/get-platform.ts';

interface ExpansionInputProps {
	label: string;
	value: string;
	onInput?: (event: ChangeEvent<HTMLInputElement>) => unknown;
	onBlur?: () => unknown;
	onFocus?: () => unknown;
	suggestions: ExpansionInputSuggestion[];
	className?: string;
	inputClassName?: string;
	suggestionClassName?: string;
	width?: string;
	suggestionLimit?: number;
	elementToFocus?: RefObject<HTMLElement>;
	placeholder?: string;
	focusOnSearchShortcut?: boolean;
	scroll?: boolean;
}

interface ExpansionInputSuggestion {
	value: string | ReactNode;
	title: string;
	onClick: () => unknown;
}

const getSuggestionDiv = () => {
	return document.activeElement as HTMLDivElement;
};

const ExpansionInput = observer(function ExpansionInput(
	props: ExpansionInputProps
) {
	const inputRef = useRef<HTMLInputElement>(null);
	const suggestionsRef = useRef<HTMLDivElement>(null);

	const limit = props.suggestionLimit ?? 5;

	const suggestions = props.scroll
		? props.suggestions
		: props.suggestions.slice(0, limit);

	const isSuggestionFocused = () => {
		return (
			document.activeElement?.className.includes(
				'expansion-input-suggestions-button'
			) && document.activeElement.parentElement === suggestionsRef.current
		);
	};

	useEffect(() => {
		const targetFirst = () => {
			const children = suggestionsRef.current?.children;

			if (
				children
					?.item(0)
					?.className.includes('expansion-input-suggestions-button')
			) {
				const firstSuggestion = children?.item(0) as HTMLDivElement;
				firstSuggestion.focus();
			}
		};

		const targetLast = () => {
			const children = suggestionsRef.current?.children;

			const lastSuggestion = children
				?.item(children?.length - 1)
				?.className.includes('expansion-input-suggestions-button')
				? (children?.item(children?.length - 1) as HTMLDivElement)
				: (children?.item(children?.length - 2) as HTMLDivElement);

			if (lastSuggestion) {
				lastSuggestion.focus();
			}
		};

		const targetPrevious = () => {
			if (isSuggestionFocused()) {
				if (
					getSuggestionDiv().previousElementSibling?.className.includes(
						'expansion-input-suggestions-button'
					)
				) {
					const previousSuggestion = getSuggestionDiv()
						.previousElementSibling as HTMLDivElement;
					previousSuggestion.focus();
				} else {
					targetLast();
				}
			}
		};

		const targetNext = () => {
			if (isSuggestionFocused()) {
				if (
					getSuggestionDiv().nextElementSibling?.className?.includes(
						'expansion-input-suggestions-button'
					)
				) {
					const nextSuggestion = getSuggestionDiv()
						.nextElementSibling as HTMLDivElement;
					nextSuggestion.focus();
				} else {
					targetFirst();
				}
			}
		};

		const onKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				if (document.activeElement === inputRef.current) {
					inputRef.current?.blur();
				} else if (isSuggestionFocused()) {
					inputRef?.current?.focus();
				}
				return;
			}

			if (event.key === 'Enter' && isSuggestionFocused()) {
				getSuggestionDiv().click();
				if (props.elementToFocus?.current) {
					// Needed to be done in a timeout to actually focus correctly
					setTimeout(() => {
						props.elementToFocus?.current?.focus();
					});
				} else {
					(document.activeElement as HTMLDivElement).blur();
				}
				return;
			}

			if (
				document.activeElement === inputRef.current ||
				isSuggestionFocused()
			) {
				if (event.key === 'ArrowUp') {
					if (isSuggestionFocused()) {
						targetPrevious();
					} else {
						targetLast();
					}

					event.preventDefault();
					return;
				}

				if (event.key === 'ArrowDown') {
					if (isSuggestionFocused()) {
						targetNext();
					} else {
						targetFirst();
					}

					event.preventDefault();
					return;
				}
			}

			// Refocus input if continue typing
			if (isSuggestionFocused()) {
				inputRef.current?.focus();
			}
		};
		document.addEventListener('keydown', onKeyDown);

		return () => {
			document.removeEventListener('keydown', onKeyDown);
		};
	}, [props.elementToFocus]);

	const focusSearchInput = useCallback(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, [inputRef]);

	const searchHasFocus = useCallback(() => {
		return inputRef.current && document.activeElement === inputRef.current;
	}, [inputRef]);

	useEffect(() => {
		const onKeyDown = (event: KeyboardEvent) => {
			// Start Search
			if (GetPlatform() === Platform.MAC) {
				if (event.getModifierState('Meta') && event.key === 'f') {
					if (!searchHasFocus()) {
						event.preventDefault();
						focusSearchInput();
					}
				}
			} else {
				if (event.getModifierState('Control') && event.key === 'f') {
					if (!searchHasFocus()) {
						event.preventDefault();
						focusSearchInput();
					}
				}
			}
		};

		if (props.focusOnSearchShortcut) {
			document.addEventListener('keydown', onKeyDown);
		}

		return () => {
			document.removeEventListener('keydown', onKeyDown);
		};
	}, [focusSearchInput, props.focusOnSearchShortcut, searchHasFocus]);

	return (
		<div
			className={ClassString({
				static: 'group',
				dynamic: {},
				custom: props.className,
			})}
			style={
				{
					'--button-count':
						props.suggestions.length > limit
							? limit + 2 // Top padding and message at bottom
							: suggestions.length === 0
								? 0
								: suggestions.length + 1, // Top padding
					width: props.width,
				} as CSSProperties
			}
		>
			<InputText
				label={props.label}
				value={props.value}
				onInput={props.onInput}
				onBlur={() => {
					if (props.onBlur) {
						props.onBlur();
					}
				}}
				onFocus={() => {
					if (props.onFocus) {
						props.onFocus();
					}
				}}
				options={{
					noAutocomplete: true,
					noMargin: true,
					width: '100%',
				}}
				innerRef={inputRef}
				inputClassName={props.inputClassName}
				placeholder={props.placeholder}
				className={'relative z-in-front-2'}
			/>

			<div
				className={ClassString({
					static: 'pt-0 overflow-hidden rounded-button transition-all z-in-front w-[calc(100%-8px)] top-4 left-1 h-0 group-focus-within:h-expansion-input-open absolute bg-white dark:bg-gray-525 border border-transparent dark:border-transparent',
					dynamic: {
						'group-focus-within:shadow-lg group-focus-within:pt-9 group-focus-within:top-0 group-focus-within:border-gray-600 dark:group-focus-within:border-gray-500':
							!!suggestions.length,
						'overflow-y-auto': props.scroll,
					},
					custom: props.suggestionClassName,
				})}
				ref={suggestionsRef}
			>
				{suggestions.map((suggestion) => {
					return (
						<div
							onClick={(event) => {
								const targetEl = event.target as HTMLDivElement;
								targetEl.blur();
								suggestion.onClick();
							}}
							key={`${suggestion.title}`}
							className={
								'expansion-input-suggestions-button flex justify-start items-center h-button pl-4 cursor-pointer outline-none hover:bg-blue-500 hover:text-white focus:bg-blue-500 focus:text-white'
							}
							title={suggestion.title}
							tabIndex={
								document.activeElement === inputRef.current ||
								isSuggestionFocused()
									? 0
									: -1
							}
						>
							<div className={'truncate pointer-events-none'}>
								{suggestion.value}
							</div>
						</div>
					);
				})}
				{props.suggestions.length > limit && !props.scroll && (
					<div
						className={
							'flex justify-start items-center center h-button pl-4 italic text-sm'
						}
					>{`${props.suggestions.length - suggestions.length} additional results`}</div>
				)}
			</div>
		</div>
	);
});

export default ExpansionInput;
