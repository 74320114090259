import { observer } from 'mobx-react-lite';
import Highcharts, {
	AxisSetExtremesEventObject,
	ExportingButtonsContextButtonThemeOptions,
} from 'highcharts';
import HighchartsReact, {
	HighchartsReactProps,
} from 'highcharts-react-official';
import ChartColors from '@components/core/chart/chart-colors.ts';
import highchartsGantt from 'highcharts/modules/gantt';
import highchartsStock from 'highcharts/modules/stock';
import highchartsExporting from 'highcharts/modules/exporting';
import themeStore from '@store/theme-store.ts';
import BrandColors from '@styles/_brand_colors.ts';
import extendObject from '@utils/extend-object.ts';
import { useEffect, useRef } from 'react';

highchartsGantt(Highcharts);
highchartsStock(Highcharts);
highchartsExporting(Highcharts);

const defaultOptions: Highcharts.Options = {
	credits: {
		enabled: false,
	},
	time: {
		useUTC: false,
	},
	chart: {
		style: {
			fontFamily: 'Inter',
		},
	},
	title: {
		style: {
			fontWeight: '400',
			fontSize: '1.5rem',
		},
	},
	exporting: {
		buttons: {
			contextButton: {
				menuItems: [
					'viewFullscreen',
					'separator',
					'downloadPNG',
					'downloadPDF',
				],
				theme: {
					states: {
						hover: {
							fill: themeStore.lightTheme
								? BrandColors.white
								: BrandColors.gray550,
							stroke: themeStore.lightTheme
								? BrandColors.gray600
								: BrandColors.white,
						},
						select: {
							fill: themeStore.lightTheme
								? BrandColors.white
								: BrandColors.gray550,
						},
					},
				} as ExportingButtonsContextButtonThemeOptions,
			},
		},
	},
	xAxis: {
		labels: {
			style: {
				color: themeStore.lightTheme
					? BrandColors.gray600
					: BrandColors.white,
			},
		},
	},
	rangeSelector: {
		enabled: true,
		selected: 2,
		buttons: [
			{
				type: 'day',
				count: 1,
				text: '1d',
				title: 'View 1 day',
			},
			{
				type: 'day',
				count: 7,
				text: '7d',
				title: 'View 7 days',
			},
			{
				type: 'month',
				count: 1,
				text: '1m',
				title: 'View 1 month',
			},
			{
				type: 'month',
				count: 3,
				text: '3m',
				title: 'View 3 months',
			},
			{
				type: 'month',
				count: 6,
				text: '6m',
				title: 'View 6 months',
			},
			{
				type: 'ytd',
				text: 'YTD',
				title: 'View year to date',
			},
			{
				type: 'year',
				count: 1,
				text: '1y',
				title: 'View 1 year',
			},
			{
				type: 'all',
				text: 'All',
				title: 'View all',
			},
		],
		buttonPosition: {
			x: -20,
		},
		buttonTheme: {
			r: 10,
			style: {
				borderWidth: '1px solid black',
			},
			fill: 'none',
			states: {
				hover: {
					fill: BrandColors.blue500,
					style: {
						color: BrandColors.white,
					},
				},
				select: {
					fill: BrandColors.gray600,
					style: {
						color: BrandColors.white,
					},
				},
			},
		},
		inputEnabled: true,
		inputStyle: {
			color: themeStore.lightTheme
				? BrandColors.gray600
				: BrandColors.white,
			backgroundColor: themeStore.lightTheme
				? BrandColors.white
				: BrandColors.gray500,
		},
		labelStyle: {
			color: themeStore.lightTheme
				? BrandColors.gray600
				: BrandColors.white,
		},
	},
};

Highcharts.setOptions(defaultOptions);

const Chart = observer(function Chart(props: HighchartsReactProps) {
	const xExtremes = useRef<{ min: number; max: number } | undefined>(
		undefined
	);

	const chart = useRef<Highcharts.Chart | undefined>();

	const chartOptions = {
		...props.options,
	};

	extendObject(chartOptions, ['colors'], ChartColors());

	extendObject(
		chartOptions,
		['title', 'style', 'color'],
		themeStore.lightTheme ? BrandColors.gray600 : BrandColors.white
	);

	extendObject(
		chartOptions,
		['chart', 'backgroundColor'],
		themeStore.lightTheme ? BrandColors.white : BrandColors.gray550
	);

	extendObject(
		chartOptions,
		['yAxis', 'labels', 'style', 'color'],
		themeStore.lightTheme ? BrandColors.gray600 : BrandColors.white
	);

	extendObject(
		chartOptions,
		['xAxis', 'labels', 'style', 'color'],
		themeStore.lightTheme ? BrandColors.gray600 : BrandColors.white
	);

	extendObject(
		chartOptions,
		['legend', 'itemStyle', 'color'],
		themeStore.lightTheme ? BrandColors.gray600 : BrandColors.white
	);

	extendObject(
		chartOptions,
		['exporting', 'buttons', 'contextButton', 'theme', 'fill'],
		themeStore.lightTheme ? BrandColors.white : BrandColors.gray550
	);

	extendObject(
		chartOptions,
		['exporting', 'buttons', 'contextButton', 'symbolStroke'],
		themeStore.lightTheme ? BrandColors.gray600 : BrandColors.white
	);

	extendObject(
		chartOptions,
		['rangeSelector', 'buttonTheme', 'style', 'color'],
		themeStore.lightTheme ? BrandColors.gray600 : BrandColors.white
	);

	extendObject(
		chartOptions,
		[
			'rangeSelector',
			'buttonTheme',
			'states',
			'disabled',
			'style',
			'color',
		],
		themeStore.lightTheme ? BrandColors.gray400 : BrandColors.gray500
	);

	extendObject(
		chartOptions,
		['rangeSelector', 'buttonTheme', 'states', 'select', 'fill'],
		themeStore.lightTheme ? BrandColors.gray600 : BrandColors.blue525
	);

	extendObject(
		chartOptions,
		['xAxis', 'events', 'setExtremes'],
		(extremes: AxisSetExtremesEventObject) => {
			xExtremes.current = { min: extremes.min, max: extremes.max };
		}
	);

	extendObject(chartOptions, ['chart', 'events', 'render'], (draw: Event) => {
		chart.current = draw.target as unknown as Highcharts.Chart;
	});

	if (
		props.options?.xAxis &&
		!Array.isArray(props.options?.xAxis) &&
		props.options.xAxis.type === 'datetime'
	) {
		extendObject(chartOptions, ['xAxis', 'minRange'], 86400000); // Set default min range to 1 day
	}

	useEffect(() => {
		if (chart.current && xExtremes.current) {
			chart.current.xAxis.forEach((axis) => {
				axis.setExtremes(
					xExtremes.current?.min,
					xExtremes.current?.max,
					true,
					false
				);
			});
		}
	}, [props.options]);

	return (
		<HighchartsReact
			containerProps={{
				className: `highcharts-${themeStore.lightTheme ? 'light' : 'dark'}`,
			}}
			highcharts={{
				...Highcharts,
				...props.highcharts,
			}}
			options={chartOptions}
			constructorType={props.constructorType}
		/>
	);
});

export default Chart;
