function hexToRGBA(hex: string, alpha: number): string {
	// Validate hex format
	if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hex)) {
		throw new Error('Invalid hex color format');
	}

	// Remove '#' if present
	hex = hex.replace('#', '');

	// Convert hex to RGB
	const r = parseInt(hex.substring(0, 2), 16);
	const g = parseInt(hex.substring(2, 4), 16);
	const b = parseInt(hex.substring(4, 6), 16);

	// Return RGBA string
	return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export default hexToRGBA;
