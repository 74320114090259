import { customTwMerge } from '@/main.tsx';

interface ClassStringClasses {
	static?: string;
	dynamic: Record<string, boolean | undefined>;
	custom?: string;
}

function ClassString(classes: ClassStringClasses) {
	let appendedClass = classes.static ?? '';

	for (const [classString, enabled] of Object.entries(classes.dynamic)) {
		if (enabled) {
			appendedClass += ` ${classString}`;
		}
	}

	if (classes.custom) {
		appendedClass += ` ${classes.custom}`;
	}

	return appendedClass ? customTwMerge(appendedClass) : undefined;
}

export default ClassString;
