import { makeAutoObservable, runInAction } from 'mobx';
import {
	ExperienceRanking,
	ExperienceRatingListResponseSchema,
} from '@/schemas/experience-rating.ts';
import httpFetch from '@services/http-fetch-service.ts';

const experienceRankingApi = `${import.meta.env.VITE_SERVER_URL}/api/stats/ranking`;

class ExperienceRankingStore {
	private experienceRanking: Record<number, ExperienceRanking[]> = {};

	constructor() {
		makeAutoObservable(this);
	}

	async loadExperienceRankings(experienceId: number) {
		if (this.experienceRanking[experienceId]) {
			return;
		}

		const dateNow = new Date();
		const threeYearsAgo = new Date();
		threeYearsAgo.setUTCFullYear(dateNow.getFullYear() - 3);
		threeYearsAgo.setUTCHours(0, 0, 0, 0);
		const url = `${experienceRankingApi}?placeId=${experienceId}&startTime=${threeYearsAgo.toISOString()}`;
		const res = await httpFetch.GET(url, true, true);

		if (res.ok) {
			const ratingsResponse = ExperienceRatingListResponseSchema.parse(
				await res.json()
			);

			runInAction(() => {
				this.experienceRanking[experienceId] = ratingsResponse.map(
					(rating) => {
						return {
							...rating,
							start: new Date(rating.start),
							end: new Date(rating.end),
						};
					}
				);
			});
		}
	}

	experienceRating(experienceId: number): ExperienceRanking[] | undefined {
		return this.experienceRanking[experienceId];
	}
}

const experienceRankingStore = new ExperienceRankingStore();
export default experienceRankingStore;
