import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { PlaceTag } from '@/schemas/plage-tag-schema.ts';
import InputText from '@components/core/input-text/input-text.tsx';
import placeTagStore from '@store/place-tag-store.ts';
import ModalBase from '@components/service/modal/modal-base.tsx';

interface PlaceTagsRenameProps {
	placeTag: PlaceTag;
}

const PlaceTagsRenameModal = observer(function PlaceTagsRenameModal(
	props: PlaceTagsRenameProps
) {
	const [tagName, setTagName] = useState(props.placeTag.name);

	return (
		<ModalBase
			title={`Rename Tag`}
			content={{
				children: (
					<InputText
						label={'Tag Name'}
						value={tagName}
						onInput={(event) => setTagName(event.target.value)}
						options={{
							noMarginBottom: true,
						}}
					/>
				),
			}}
			onConfirm={async () => {
				if (props.placeTag.name !== tagName) {
					await placeTagStore.updatePlaceTag(props.placeTag.id, {
						...props.placeTag,
						name: tagName,
					});
				}
			}}
			options={{
				confirmButtonTitle: 'Rename',
				confirmButtonDisabled: !tagName.length,
			}}
		/>
	);
});

export default PlaceTagsRenameModal;
