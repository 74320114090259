import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import InputText from '@components/core/input-text/input-text.tsx';
import PlaceExpansionInput from '@components/data/place-expansion-input/place-expansion-input.tsx';
import { Experience } from '@/schemas/experience-schema.ts';
import Button from '@components/core/button/button.tsx';
import validationService from '@services/validation-service.ts';
import PlaceThumbnail from '@components/data/place-thumbnail/place-thumbnail.tsx';
import { PlaceThumbnailSize } from '@components/data/place-thumbnail/place-thumbnail-constants.ts';
import eventCredentialStore from '@store/event-credential-store.ts';
import experienceStore from '@store/experience-store.ts';

const EventCredentialCreate = observer(function EventCredentialCreate() {
	const [searchTerm, setSearchTerm] = useState('');
	const [place, setPlace] = useState<Experience | undefined>();
	const [eventCredentialName, setEventCredentialName] = useState('');

	const invalid =
		(!place && !validationService.number(searchTerm)) ||
		!eventCredentialName.trim().length;
	const nameInUse = eventCredentialStore.eventCredentials.some(
		(cred) =>
			cred.comment.toLowerCase() === eventCredentialName.toLowerCase()
	);

	const createCredential = async () => {
		const created = await eventCredentialStore.createEventCredential(
			eventCredentialName,
			place?.place_id ?? +searchTerm
		);

		if (created) {
			setSearchTerm('');
			setPlace(undefined);
			setEventCredentialName('');
		}
	};

	const updatePlace = (place?: Experience) => {
		if (place && !eventCredentialName.trim().length) {
			setEventCredentialName(place.canonical_name);
		}

		setPlace(place);
	};

	const getUntrackedExperience = useCallback(async () => {
		const untrackedExp =
			await experienceStore.getAndReturnFullExperience(searchTerm);
		if (untrackedExp) {
			setPlace(untrackedExp);

			if (!eventCredentialName.trim().length) {
				setEventCredentialName(untrackedExp.name);
			}
		}
	}, [eventCredentialName, searchTerm]);

	useEffect(() => {
		if (
			!place &&
			validationService.databaseId(searchTerm) &&
			!experienceStore.getSlimExperienceById(+searchTerm)
		) {
			void getUntrackedExperience();
		}
	}, [getUntrackedExperience, place, searchTerm]);

	return (
		<div className={'flex flex-col'}>
			<div className={'w-full text-2xl mb-4'}>
				Create New Event Credential
			</div>

			<div
				className={
					'flex justify-center items-end gap-4 sm:gap-8 w-full flex-wrap'
				}
			>
				<div className={'flex justify-center items-center w-24 h-24'}>
					<PlaceThumbnail
						place={place}
						size={PlaceThumbnailSize.SMALL}
						className={'min-w-24 max-w-24 w-24 rounded-md'}
					/>
				</div>

				<div
					className={
						'flex flex-col w-full max-w-96 justify-between gap-5'
					}
				>
					<PlaceExpansionInput
						searchTerm={searchTerm}
						setSearchTerm={setSearchTerm}
						place={place}
						setPlace={updatePlace}
						className={'w-full max-w-96 mt-2'}
						width={'100%'}
						label={'Experience ID or Experience'}
					/>

					<InputText
						label={'Name'}
						value={eventCredentialName}
						onInput={(event) =>
							setEventCredentialName(event.target.value)
						}
						options={{ width: '100%', noMargin: true }}
						className={'w-full max-w-96'}
					/>
				</div>

				<div className={'flex flex-col items-center gap-2'}>
					{nameInUse && (
						<div
							className={'text-sm text-red-500 dark:text-red-400'}
						>
							name already in use
						</div>
					)}

					<Button
						title={'Create Credential'}
						onClick={() => {
							void createCredential();
						}}
						disabled={invalid || nameInUse}
					/>
				</div>
			</div>
		</div>
	);
});

export default EventCredentialCreate;
