import { z } from 'zod';
import {
	FullExperience,
	FullExperienceResponse,
	FullExperienceResponseSchema,
} from '@/schemas/experience-schema.ts';

export interface PlaceTag {
	id: number;
	name: string;
	place_ids: number[];
}

export const PlaceTagSchema: z.ZodType<PlaceTag> = z.object({
	id: z.number(),
	name: z.string(),
	place_ids: z.array(z.number()),
});

export const PlaceTagListSchema = z.array(PlaceTagSchema);

export interface PopulatedPlaceTagResponse extends Omit<PlaceTag, 'place_ids'> {
	places: FullExperienceResponse[];
}

export const PopulatedPlaceTagResponseSchema: z.ZodType<PopulatedPlaceTagResponse> =
	z.object({
		id: z.number(),
		name: z.string(),
		places: z.array(FullExperienceResponseSchema),
	});

export interface PopulatedPlaceTag extends Omit<PlaceTag, 'place_ids'> {
	places: FullExperience[];
}
