import { observer } from 'mobx-react-lite';
import Highcharts from 'highcharts';
import Chart from '@components/core/chart/chart.tsx';
import { useEffect } from 'react';
import platformStatsStore from '@store/platform-stats-store.ts';
import { FullExperience } from '@/schemas/experience-schema.ts';
import GroupBy from '@utils/group-by.ts';

interface PlaceGenreDistributionProps {
	places?: FullExperience[];
}

const PlaceGenreDistribution = observer(function PlaceGenreDistribution(
	props: PlaceGenreDistributionProps
) {
	const data = props.places
		? Object.entries(
				Object.fromEntries(
					GroupBy(
						props.places,
						(place: FullExperience) => place.genre
					).entries()
				)
			)
				.sort((a, b) => b[1].length - a[1].length)
				.map(([key, value]) => [key, value.length])
		: Object.entries(platformStatsStore.platformGenreDistribution)
				.sort((a, b) => {
					return b[1] - a[1];
				})
				.map(([genre, count]) => {
					return [genre, count];
				});

	useEffect(() => {
		if (!props.places) {
			void platformStatsStore.loadGenreDistribution();
		}
	}, [props.places]);

	const options: Highcharts.Options = {
		title: {
			text: 'Genre',
		},
		series: [
			{
				name: 'Experiences',
				type: 'pie',
				data,
			},
		],
		plotOptions: {
			series: {
				allowPointSelect: true,
				cursor: 'pointer',
				dataLabels: [
					{
						enabled: true,
						distance: 20,
						allowOverlap: true,
					} as Highcharts.DataLabelsOptions,
					{
						enabled: true,
						distance: -40,
						format: '{point.percentage:.1f}%',
						allowOverlap: true,
						style: {
							fontSize: '1.1em',
							textOutline: 'none',
							opacity: 0.7,
						},
						filter: {
							operator: '>',
							property: 'percentage',
							value: 5,
						},
					} as Highcharts.DataLabelsOptions,
				],
			},
		},
		tooltip: {
			pointFormat: `<b style="font-size: 16px">{point.percentage:.1f}%</b> <br/> <b>{point.y}</b> Experiences `,
		},
		rangeSelector: {
			enabled: false,
		},
	};

	return (
		<div>
			<Chart highcharts={Highcharts} options={options} />
		</div>
	);
});

export default PlaceGenreDistribution;
