import './loading-incdicator.scss';
import { observer } from 'mobx-react-lite';
import ClassString from '@utils/class-string.ts';

interface LoadingIndicatorProps {
	className?: string;
}

const LoadingIndicator = observer(function LoadingIndicator(
	props: LoadingIndicatorProps
) {
	return (
		<div
			className={ClassString({
				static: 'loading-indicator',
				dynamic: {},
				custom: props.className,
			})}
		>
			<div className={'after:bg-gray-600 dark:after:bg-white'}></div>
			<div className={'after:bg-gray-600 dark:after:bg-white'}></div>
			<div className={'after:bg-gray-600 dark:after:bg-white'}></div>
			<div className={'after:bg-gray-600 dark:after:bg-white'}></div>
			<div className={'after:bg-gray-600 dark:after:bg-white'}></div>
			<div className={'after:bg-gray-600 dark:after:bg-white'}></div>
			<div className={'after:bg-gray-600 dark:after:bg-white'}></div>
			<div className={'after:bg-gray-600 dark:after:bg-white'}></div>
		</div>
	);
});

export default LoadingIndicator;
