import { observer } from 'mobx-react-lite';
import NavigationButton from '@components/navigation/navigation-button/navigation-button.tsx';
import { NavigationButtonEnum } from '@components/navigation/navigation-button/navigation-button-enum.ts';
import { PlaceTag } from '@/schemas/plage-tag-schema.ts';
import ClassString from '@utils/class-string.ts';
import placeTagStore from '@store/place-tag-store.ts';
import Button from '@components/core/button/button.tsx';
import { useState } from 'react';
import { FullExperience } from '@/schemas/experience-schema.ts';
import { BUTTON_STYLE } from '@components/core/button/button-style-enum.ts';
import TagExpansionInput from '@components/data/tag-expansion-input/tag-expansion-input.tsx';

interface PlaceDetailsTagsProps {
	experience: FullExperience;
	placeTags: PlaceTag[];
	className?: string;
}

const PlaceDetailsTags = observer(function PlaceDetailsTags(
	props: PlaceDetailsTagsProps
) {
	const [selectedTag, setSelectedTag] = useState<PlaceTag | undefined>(
		undefined
	);
	const [searchTerm, setSearchTerm] = useState('');

	const addTag = () => {
		if (selectedTag) {
			void placeTagStore.addPlaceIdToTag(
				selectedTag.id,
				props.experience.place_id
			);
			setSelectedTag(undefined);
			setSearchTerm('');
		}
	};

	return (
		<div
			className={ClassString({
				static: 'w-full',
				dynamic: {},
				custom: props.className,
			})}
		>
			<div className={'text-2xl mb-4'}>{'Experience Tags'}</div>

			<div className={'flex flex-col gap-8 lg:gap-0 lg:flex-row w-full'}>
				<div
					className={ClassString({
						static: 'w-full xl:w-1/2 flex flex-wrap justify-center lg:justify-start items-center content-start gap-4 p-4 border border-gray-600 dark:border-gray-500 rounded-lg',
						dynamic: {
							'justify-center': !props.placeTags.length,
						},
					})}
				>
					{!props.placeTags.length && (
						<div
							className={
								'w-full h-full flex items-center justify-center'
							}
						>
							No tags
						</div>
					)}

					{props.placeTags.map((tag) => {
						return (
							<NavigationButton
								path={`/tags/${tag.id}`}
								title={tag.name}
								direction={NavigationButtonEnum.NONE}
								key={tag.id}
								outline
								background
							/>
						);
					})}
				</div>

				<div
					className={
						'w-full lg:w-1/2 flex flex-col justify-center items-center lg:pl-4'
					}
				>
					<div className={'flex justify-center gap-4 w-full'}>
						<TagExpansionInput
							searchTerm={searchTerm}
							setSearchTerm={setSearchTerm}
							tag={selectedTag}
							setTag={setSelectedTag}
							filteredTagIds={props.placeTags.map(
								(tag) => tag.id
							)}
							scroll
						/>

						<Button
							title={'Add Tag'}
							disabled={!selectedTag}
							onClick={addTag}
							buttonStyle={BUTTON_STYLE.PRIMARY}
						/>
					</div>

					<div className={'px-8 py-6'}>
						Add an existing tag to the experience. To remove a tag,
						click on a tag and remove the experience from the
						experience list in the specific tag.
					</div>
				</div>
			</div>
		</div>
	);
});

export default PlaceDetailsTags;
