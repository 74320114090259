import { observer } from 'mobx-react-lite';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import useComponentVisible from '@hooks/use-component-visible.tsx';
import actionsMenuService from '@services/actions-menu-service.tsx';
import { useEffect, useRef } from 'react';
import Button from '@components/core/button/button.tsx';

export interface ActionsMenuProps {
	actions: {
		title: string;
		onClick: () => unknown;
	}[];
	width?: string;
}

const ActionsMenu = observer(function ActionsMenu(props: ActionsMenuProps) {
	const { ref, isComponentVisible, setIsComponentVisible } =
		useComponentVisible(false);
	const menuButton = useRef<HTMLDivElement>(null);

	if (!isComponentVisible) {
		actionsMenuService.close();
	}

	useEffect(() => {
		if (!isComponentVisible) {
			actionsMenuService.close();
		}

		const closeMenu = () => {
			if (isComponentVisible) {
				setIsComponentVisible(false);
			}
		};

		window.addEventListener('resize', closeMenu);

		return () => {
			window.removeEventListener('resize', closeMenu);
		};
	}, [isComponentVisible, setIsComponentVisible]);

	const onClick = () => {
		setIsComponentVisible(true);
		actionsMenuService.open(
			props,
			ref,
			menuButton?.current?.getBoundingClientRect(),
			() => {
				setIsComponentVisible(false);
			},
			props.width
		);
	};

	return (
		<div className={'cursor-pointer relative'} ref={menuButton}>
			<Button
				title={''}
				icon={{ icon: IconEnum.MORE, placement: 'left', size: '2rem' }}
				onClick={onClick}
				round
			/>
		</div>
	);
});

export default ActionsMenu;
