import { z } from 'zod';

export interface ExchangeRateApiResponse {
	time_next_update_utc: string;
	rates: {
		SEK: number;
		EUR: number;
	};
}

export const ExchangeRateApiResponseSchema: z.ZodType<ExchangeRateApiResponse> =
	z.object({
		time_next_update_utc: z.string(),
		rates: z.object({
			SEK: z.number(),
			EUR: z.number(),
		}),
	});
