import { makeAutoObservable, runInAction } from 'mobx';
import { ToastType } from '@components/service/toast/toast-enums.ts';
import { ToastMessage } from '@components/service/toast/toast.tsx';

const toastDuration = 3000;
const fadeOutDuration = 225;

class ToastStore {
	private _toastList: ToastMessage[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	emit(message: string, type: ToastType) {
		const toastId = Date.now();
		runInAction(() => {
			this._toastList.unshift({
				message,
				type,
				id: toastId,
				fadeOut: false,
			});
		});

		setTimeout(() => {
			const toastToFade = this._toastList.find(
				(toast) => toast.id === toastId
			);

			if (toastToFade) {
				runInAction(() => {
					toastToFade.fadeOut = true;
				});
				setTimeout(() => {
					runInAction(() => {
						this._toastList = this._toastList.filter(
							(toast) => toast.id !== toastId
						);
					});
				}, fadeOutDuration);
			}
		}, toastDuration);
	}

	dismiss(toastMessage: ToastMessage) {
		toastMessage.fadeOut = true;

		setTimeout(() => {
			runInAction(() => {
				this._toastList = this._toastList.filter(
					(toast) => toast.id !== toastMessage.id
				);
			});
		}, fadeOutDuration);
	}

	get toasts() {
		return this._toastList;
	}
}

const toastStore = new ToastStore();
export default toastStore;
