import logo_white from '@assets/logo_white.svg';
import logo_black from '@assets/logo_black.svg';
import React from 'react';
import { observer } from 'mobx-react-lite';
import ClassString from '@utils/class-string.ts';

interface ToolsLogoProps {
	width: string;
	color: 'white' | 'black';
	className?: string;
}

const ToolsLogo = observer(function ToolsLogo(props: ToolsLogoProps) {
	return (
		<div
			className={ClassString({
				static: 'flex flex-col justify-center items-center',
				dynamic: {},
				custom: props.className,
			})}
			style={
				{
					'--tools-logo-width': props.width ?? '400px',
				} as React.CSSProperties
			}
		>
			<img
				src={props.color === 'black' ? logo_black : logo_white}
				alt={'logo-full'}
				className={'w-tools-logo max-w-[90vw] mb-1 select-none'}
				draggable="false"
			/>
			<div
				className={'text-5xl font-bold tracking-wider pl-2 select-none'}
			>
				{'← TOOLS →'}
			</div>
		</div>
	);
});

export default ToolsLogo;
