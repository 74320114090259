import { makeAutoObservable, runInAction } from 'mobx';

class ThemeStore {
	_theme: 'light' | 'dark' =
		localStorage.getItem('theme') === 'dark' ? 'dark' : 'light';

	constructor() {
		makeAutoObservable(this);
		if (this._theme === 'dark') {
			document.documentElement.classList.add('dark');
		}
	}

	get lightTheme() {
		return this._theme === 'light';
	}

	get darkTheme() {
		return this._theme === 'dark';
	}

	toggleTheme() {
		const newTheme = this._theme === 'light' ? 'dark' : 'light';
		localStorage.setItem('theme', newTheme);
		runInAction(() => {
			this._theme = newTheme;
		});

		if (newTheme === 'light') {
			document.documentElement.classList.remove('dark');
		} else {
			document.documentElement.classList.add('dark');
		}
	}

	setTheme(theme: 'light' | 'dark') {
		localStorage.setItem('theme', theme);
		runInAction(() => {
			this._theme = theme;
		});

		if (theme === 'light') {
			document.documentElement.classList.remove('dark');
		} else {
			document.documentElement.classList.add('dark');
		}
	}
}

const themeStore = new ThemeStore();
export default themeStore;
