import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import { SlimExperience } from '@/schemas/experience-schema.ts';
import Button from '@components/core/button/button.tsx';
import ExpansionButton from '@components/core/expansion-button/expansion-button.tsx';
import placeTagStore from '@store/place-tag-store.ts';
import searchService from '@services/search-service.ts';
import experienceStore from '@store/experience-store.ts';
import { useDebounce } from '@uidotdev/usehooks';
import SearchBar from '@components/core/search-bar/search-bar.tsx';

const getFilteredTags = (selectedTags: Record<number, boolean>) => {
	return placeTagStore.placeTags.filter((tag) =>
		Object.entries(selectedTags)
			.filter((entry) => entry[1])
			.map((entry) => entry[0])
			.includes(tag.id.toString())
	);
};

interface ExperienceSearchProps {
	onSearch: (experiences: SlimExperience[]) => unknown;
}

const ExperienceSearch = observer(function ExperienceSearch(
	props: ExperienceSearchProps
) {
	const [searchTerm, setSearchTerm] = useState('');
	const debouncedSearchTerm = useDebounce(searchTerm, 300);

	const [selectedTags, setSelectedTags] = useState<Record<number, boolean>>(
		{}
	);
	const filteredTags = getFilteredTags(selectedTags);

	useEffect(() => {
		const validSearchTerm = searchTerm.length >= 3;

		const filteredExperiences = experienceStore.slimExperiences.filter(
			(experience) => {
				return filteredTags.every((tag) => {
					return tag.place_ids.includes(experience.place_id);
				});
			}
		);

		if (validSearchTerm && debouncedSearchTerm) {
			props.onSearch(
				filteredExperiences.filter((experience) => {
					return searchService.experience(experience, searchTerm);
				})
			);
		} else {
			props.onSearch(filteredExperiences);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearchTerm, selectedTags]);

	const placeTagButtons = placeTagStore.placeTags.map((tag) => {
		return {
			title: tag.name,
			onClick: () => {
				selectedTags[tag.id] = !selectedTags[tag.id];
				setSelectedTags({ ...selectedTags });
			},
			active: selectedTags[tag.id],
		};
	});

	const clearFilter = () => {
		for (const key of Object.keys(selectedTags)) {
			selectedTags[+key] = false;
		}
		setSelectedTags({ ...selectedTags });
	};

	return (
		<div className={'flex justify-between items-center w-full'}>
			<SearchBar
				onSearchUpdate={setSearchTerm}
				placeholder={'Search by name, creator or ID..'}
				className={'mr-4 max-w-3xl'}
			/>

			<div className={'flex'}>
				{!!filteredTags.length && (
					<Button
						title={''}
						icon={{
							icon: IconEnum.CLEAR,
							placement: 'left',
							size: '2em',
						}}
						className={'mr-3'}
						onClick={clearFilter}
						round
					/>
				)}

				<ExpansionButton
					title={`Filter By Tag (${filteredTags.length})`}
					subMenuButtons={placeTagButtons}
					toggleable
					width={'200px'}
				/>
			</div>
		</div>
	);
});

export default ExperienceSearch;
