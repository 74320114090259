import View from '@components/view/view.tsx';
import experienceStore from '@store/experience-store.ts';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import PlaceStats from '@components/data/place-stats/place-stats.tsx';
import {
	PlaceStatGranularity,
	PlaceStatType,
} from '@store/place-stats-store.ts';
import PlaceGenreDistribution from '@components/data/place-genre-distribution/place-genre-distribution.tsx';
import PlaceLikeDistribution from '@components/data/place-like-distribution/place-like-distribution.tsx';
import TopCreatorsList from '@views/overview/view-components/top-creators-list.tsx';
import TopCreatorsListTypeEnum from '@views/overview/view-components/top-creators-list-enum.ts';
import QuickData from '@components/data/quick-data/quick-data.tsx';
import ViewPanel from '@components/view/view-panel.tsx';
import ViewPanelSizeEnum from '@components/view/view-panel-size-enum.ts';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import LightdashDashboards from '@components/data/lightdash-dashboards/lightdash-dashboards.tsx';
import DashboardType from '@components/data/lightdash-dashboards/dashboard-type.ts';
import platformStatsStore from '@store/platform-stats-store.ts';
import PlatformCCUChart from '@views/overview/view-components/platform-ccu-chart.tsx';
import calculateAverage from '@utils/calculate-average.ts';
import NavigateToExperience from '@components/navigation/navigate-to-experience/navigate-to-experience.tsx';
import ExperienceTopList from '@components/tables/experience-top-list.tsx';

const Overview = observer(function Overview() {
	const [loading, setLoading] = useState(true);
	const initialized = experienceStore.initialized;

	const places = experienceStore.slimExperiences;
	const totalPlayersOnline = places.reduce(
		(sum, experience) => sum + (experience.players_online || 0),
		0
	);

	const top5Places = [...places]
		.sort((a, b) => b.players_online - a.players_online)
		.slice(0, 5);

	useEffect(() => {
		if (initialized) {
			setLoading(false);
		}
	}, [initialized]);

	const dailyCCU = [...platformStatsStore.platformCCU].sort(
		(a, b) => a.ccu - b.ccu
	);
	const lowestCCU = dailyCCU.length ? dailyCCU[0] : { ccu: 0, time: 0 };
	const highestCCU = dailyCCU.length
		? dailyCCU[dailyCCU.length - 1]
		: { ccu: 0, time: 0 };

	const lastMonthAverageCCU = () => {
		const now = new Date();
		const firstDayOfCurrentMonth = new Date(
			now.getFullYear(),
			now.getMonth(),
			1
		);
		const lastDayOfPreviousMonth = new Date(firstDayOfCurrentMonth);
		lastDayOfPreviousMonth.setDate(lastDayOfPreviousMonth.getDate() - 1);
		lastDayOfPreviousMonth.setHours(23, 59, 59, 999);
		const firstDayOfPreviousMonth = new Date(
			lastDayOfPreviousMonth.getFullYear(),
			lastDayOfPreviousMonth.getMonth(),
			1
		);

		const ccu = +calculateAverage(
			dailyCCU
				.filter((ccu) => {
					const time = ccu.time.getTime();
					return (
						time > firstDayOfPreviousMonth.getTime() &&
						time < lastDayOfPreviousMonth.getTime()
					);
				})
				.map((ccu) => ccu.ccu)
		);
		return ccu.toFixed(0);
	};

	return (
		<View
			viewInfo={{
				title: 'Overview',
				icon: IconEnum.OVERVIEW,
				iconSize: '30px',
			}}
			hideHeader
		>
			<div className={'w-full px-2'}>
				<NavigateToExperience />
			</div>

			<ViewPanel
				loading={loading}
				options={{ size: ViewPanelSizeEnum.THIRD }}
			>
				<QuickData
					data={new Intl.NumberFormat(navigator.language).format(
						places.length
					)}
					title={'Tracked Experiences'}
				/>
			</ViewPanel>

			<ViewPanel
				loading={loading}
				options={{ size: ViewPanelSizeEnum.THIRD }}
			>
				<QuickData
					data={new Intl.NumberFormat(navigator.language).format(
						new Set(places.map((place) => place.creator.name)).size
					)}
					title={'Creators'}
				/>
			</ViewPanel>

			<ViewPanel
				loading={loading}
				options={{ size: ViewPanelSizeEnum.THIRD }}
			>
				<QuickData
					data={`~${new Intl.NumberFormat(navigator.language).format(
						totalPlayersOnline
					)}`}
					title={'Players Online'}
				/>
			</ViewPanel>

			<ViewPanel>
				<LightdashDashboards
					dashboards={[
						{ type: DashboardType.TAGS_OVERVIEW },
						{ type: DashboardType.TOP_100_WEEKLY },
					]}
				/>
			</ViewPanel>

			<ViewPanel loading={platformStatsStore.loading}>
				{!!platformStatsStore.platformCCUChartData.length && (
					<>
						<PlatformCCUChart />

						<div
							className={
								'w-full flex justify-around flex-col xl:flex-row'
							}
						>
							<div
								className={
									'flex flex-col items-center mb-6 xl:mb-0'
								}
							>
								<QuickData
									data={new Intl.NumberFormat(
										navigator.language
									).format(lowestCCU.ccu)}
									title={'Lowest Daily CCU'}
									className={'mt-0 mb-0'}
								/>
								<div className={'text-sm'}>
									{new Date(
										lowestCCU.time
									).toLocaleDateString()}
								</div>
							</div>

							<QuickData
								data={new Intl.NumberFormat(
									navigator.language
								).format(+lastMonthAverageCCU())}
								title={'Average Daily CCU Last Month'}
								className={'mt-0 mb-6 xl:mb-0'}
							/>

							<div className={'flex flex-col items-center'}>
								<QuickData
									data={new Intl.NumberFormat(
										navigator.language
									).format(highestCCU.ccu)}
									title={'Highest Daily CCU'}
									className={'mt-0 mb-0'}
								/>
								<div className={'text-sm'}>
									{new Date(
										highestCCU.time
									).toLocaleDateString()}
								</div>
							</div>
						</div>
					</>
				)}

				{!platformStatsStore.platformCCUChartData.length && (
					<div className={'w-full flex justify-center'}>
						No Roblox CCU data
					</div>
				)}
			</ViewPanel>

			<ViewPanel
				title={'Top Creators (Total Experiences)'}
				loading={loading}
				options={{ size: ViewPanelSizeEnum.HALF }}
			>
				<TopCreatorsList
					places={places}
					listBy={TopCreatorsListTypeEnum.BY_CREATED_PLACES}
				/>
			</ViewPanel>

			<ViewPanel
				title={'Top Creators (Total Players Online)'}
				loading={loading}
				options={{ size: ViewPanelSizeEnum.HALF }}
			>
				<TopCreatorsList
					places={places}
					listBy={TopCreatorsListTypeEnum.BY_CURRENT_PLAYERS}
				/>
			</ViewPanel>

			<ViewPanel loading={loading} className={'overview-top-5-panel'}>
				<div className={'mb-4'}>
					<PlaceStats
						places={top5Places}
						statType={PlaceStatType.CCUS}
						title={'Concurrent Users (Current Top 5 Experiences)'}
						options={{
							showLegend: true,
							defaultGranularity: PlaceStatGranularity.WEEK,
							selectedRange: 7,
						}}
					/>
				</div>

				<ExperienceTopList experiences={top5Places} />
			</ViewPanel>

			<ViewPanel
				loading={loading}
				options={{ size: ViewPanelSizeEnum.HALF }}
			>
				<PlaceGenreDistribution />
			</ViewPanel>

			<ViewPanel
				loading={loading}
				options={{ size: ViewPanelSizeEnum.HALF }}
			>
				<PlaceLikeDistribution places={places} />
			</ViewPanel>
		</View>
	);
});

export default Overview;
