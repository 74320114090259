import { z } from 'zod';

export interface EventCredential {
	game_key: string;
	secret_key: string;
	place_id: number;
	disabled: boolean;
	comment: string; // Game name
}

export const EventCredentialSchema: z.ZodType<EventCredential> = z.object({
	game_key: z.string(),
	secret_key: z.string(),
	place_id: z.number(),
	disabled: z.boolean(),
	comment: z.string(),
});

export const EventCredentialListSchema: z.ZodType<EventCredential[]> = z.array(
	EventCredentialSchema
);
