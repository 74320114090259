export enum CurrencyEnum {
	USD = 'USD',
	EUR = 'EUR',
	SEK = 'SEK',
}

export const getCurrencySymbol = (currency: CurrencyEnum) => {
	switch (currency) {
		case CurrencyEnum.USD:
			return '$';
		case CurrencyEnum.EUR:
			return '€';
		case CurrencyEnum.SEK:
			return 'kr';
	}
};

const usdFormatter = new Intl.NumberFormat('en-EN', {
	style: 'currency',
	currency: 'USD',
});

const eurFormatter = new Intl.NumberFormat('en-EN', {
	style: 'currency',
	currency: 'EUR',
});

const sekFormatter = new Intl.NumberFormat('se-SE', {
	style: 'currency',
	currency: 'SEK',
});

export const toCurrencyString = (currency: CurrencyEnum, amount: number) => {
	switch (currency) {
		case CurrencyEnum.USD:
			return usdFormatter.format(amount);
		case CurrencyEnum.EUR:
			return eurFormatter.format(amount);
		case CurrencyEnum.SEK:
			return sekFormatter.format(amount);
	}
};
