import { observer } from 'mobx-react-lite';
import { ToastType } from '@components/service/toast/toast-enums.ts';
import { Fragment, ReactNode } from 'react';
import toastStore from '@store/toast-store.ts';
import ClassString from '@utils/class-string.ts';
import Icon from '@components/core/icon/icon.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';

export interface ToastMessage {
	message: string;
	type: ToastType;
	id: number;
	fadeOut: boolean;
}

const getToastTitle = (type: ToastType): string => {
	switch (type) {
		case ToastType.INFO:
			return 'Info';
		case ToastType.CONFIRM:
			return 'Success';
		case ToastType.WARNING:
			return 'Warning';
		case ToastType.ERROR:
			return 'Error';
	}
};

const getToastIcon = (type: ToastType): ReactNode => {
	switch (type) {
		case ToastType.INFO:
			return <Icon icon={IconEnum.ROUND_INFO} size={'3rem'} />;
		case ToastType.CONFIRM:
			return <Icon icon={IconEnum.ROUND_CHECK} size={'3rem'} />;
		case ToastType.WARNING:
			return <Icon icon={IconEnum.ROUND_WARNING} size={'3rem'} />;
		case ToastType.ERROR:
			return <Icon icon={IconEnum.ROUND_ERROR} size={'3rem'} />;
	}
};

const Toast = observer(function Toast() {
	return (
		<div
			className={
				'toast-container fixed bottom-0 left-0 max-w-full w-fit h-screen flex flex-col-reverse z-toast pointer-events-none'
			}
		>
			{toastStore.toasts.map((toast, index) => {
				return (
					<Fragment key={index}>
						<div
							className={ClassString({
								static: 'w-full flex items-center bg-white dark:bg-gray-550 p-4 m-4 rounded-lg pointer-events-auto cursor-pointer border-l-12 shadow-lg border',
								dynamic: {
									'border-blue-500 dark:border-blue-300':
										toast.type === ToastType.INFO,
									'border-green-500 dark:border-green-300':
										toast.type === ToastType.CONFIRM,
									'border-yellow-500 dark:border-yellow-300':
										toast.type === ToastType.WARNING,
									'border-red-500 dark:border-red-400':
										toast.type === ToastType.ERROR,
									'ml-toast-fade-out transition-toast-fade-out':
										toast.fadeOut,
								},
							})}
							onClick={() => {
								toastStore.dismiss(toast);
							}}
						>
							<div
								className={ClassString({
									static: 'rounded-full mr-4',
									dynamic: {
										'text-blue-500 dark:text-blue-300':
											toast.type === ToastType.INFO,
										'text-green-500 dark:text-green-300':
											toast.type === ToastType.CONFIRM,
										'text-yellow-500 dark:text-yellow-300':
											toast.type === ToastType.WARNING,
										'text-red-500 dark:text-red-400':
											toast.type === ToastType.ERROR,
									},
								})}
							>
								{getToastIcon(toast.type)}
							</div>
							<div>
								<div className={'font-semibold'}>
									{getToastTitle(toast.type)}
								</div>
								<div>{toast.message}</div>
							</div>
						</div>
					</Fragment>
				);
			})}
		</div>
	);
});

export default Toast;
