import { z } from 'zod';

export enum ThumbnailType {
	ICON = 'Icon',
	BANNER = 'Banner',
}

export interface Thumbnail {
	place_id: number;
	type: ThumbnailType;
	hash: string;
	time: string; // Date string
}

export const ThumbnailSchema: z.ZodType<Thumbnail> = z.object({
	place_id: z.number(),
	type: z.nativeEnum(ThumbnailType),
	hash: z.string(),
	time: z.string(), // Date string
});

export const ThumbnailListSchema: z.ZodType<Thumbnail[]> =
	z.array(ThumbnailSchema);
