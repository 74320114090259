import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import Button from '@components/core/button/button.tsx';
import modalService from '@services/modal-service.tsx';
import { BUTTON_STYLE } from '@components/core/button/button-style-enum.ts';
import Form from '@components/core/form/form.tsx';
import PlaceExpansionInput from '@components/data/place-expansion-input/place-expansion-input.tsx';
import { Experience } from '@/schemas/experience-schema.ts';

interface PlaceTagDetailsBulkAddProps {
	tagName: string;
	submit: (placeId: number) => unknown;
}

const PlaceTagDetailsBulk = observer(function PlaceTagDetailsBulkAdd(
	props: PlaceTagDetailsBulkAddProps
) {
	const [searchTerm, setSearchTerm] = useState('');
	const [place, setPlace] = useState<Experience | undefined>();
	const submitButtonRef = useRef<HTMLButtonElement>(null);
	const validPlaceId = searchTerm.length && /^\d+$/.test(searchTerm);

	return (
		<Form
			className={'flex items-center flex-col gap-4 w-full'}
			onSubmit={() => {
				props.submit(place?.place_id ?? +searchTerm);
				modalService.close();
			}}
		>
			<div
				className={'mb-4'}
			>{`Add experience to "${props.tagName}".`}</div>

			<PlaceExpansionInput
				searchTerm={searchTerm}
				setSearchTerm={setSearchTerm}
				place={place}
				setPlace={setPlace}
				elementToFocus={submitButtonRef}
			/>

			<div className={'w-full flex justify-evenly mt-2'}>
				<Button
					title={'Cancel'}
					onClick={() => {
						modalService.close();
					}}
				/>

				<Button
					title={`Add`}
					buttonStyle={BUTTON_STYLE.PRIMARY}
					disabled={!place && !validPlaceId}
					innerRef={submitButtonRef}
				/>
			</div>
		</Form>
	);
});

export default PlaceTagDetailsBulk;
