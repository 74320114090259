import { observer } from 'mobx-react-lite';
import React from 'react';

interface FormProps {
	children: React.ReactNode;
	onSubmit: () => unknown;
	className?: string;
}

const Form = observer(function Form(props: FormProps) {
	return (
		<form
			className={props.className}
			onSubmit={(event) => {
				event.preventDefault();
				props.onSubmit();
			}}
		>
			{props.children}
		</form>
	);
});

export default Form;
