import DashboardType from '@components/data/lightdash-dashboards/dashboard-type.ts';
import { Experience } from '@/schemas/experience-schema.ts';
import { PlaceTag, PopulatedPlaceTag } from '@/schemas/plage-tag-schema.ts';

export const DashboardInfo = (dashboard: DashboardInfoType) => {
	switch (dashboard.type) {
		case DashboardType.GAME_SPECIFIC_OVERVIEW:
			return {
				title: 'Game Specific Overview',
				url: `https://lightdash.thegang.io/projects/446ea0f3-6baa-4f25-a268-264cb3b99408/dashboards/8374e00e-e6ea-48d3-bdd8-3de191f4508e/view/?filters={"dimensions":[{"id":"7e640f23-15a9-4efd-ad8d-02ec4c0f11d3","target":{"fieldId":"places_place_id","fieldName":"place_id","tableName":"places"},"values":["${dashboard.placeId}"],"disabled":false,"operator":"equals"}],"metrics":[],"tableCalculations":[]}`,
				description:
					'The game specific overview dashboard offers a summary of key metrics for the experience. It includes rankings, revenue data (assuming monetisation is enabled and the game belongs to us), and performance metrics such as CCU, new visits and like percentage.',
			};
		case DashboardType.EXPERIENCE_INSIGHT_BY_TAG:
			return {
				title: 'Experience Insight by Tag',
				url: `https://lightdash.thegang.io/projects/446ea0f3-6baa-4f25-a268-264cb3b99408/dashboards/434b17ef-1fa3-4da0-86b7-746940f2464d/view?filters={"dimensions":[{"id":"b8b04e77-691d-475f-bab5-87d495dc6788","target":{"fieldId":"places_category","tableName":"places"},"values":["${dashboard.tagName}"],"disabled":false,"operator":"equals"}],"metrics":[],"tableCalculations":[]}`,
				description:
					'The dashboard offers a summary of key metrics for the experience tag. It includes rankings, and metrics such as CCU, released experiences and average top game age.',
			};
		case DashboardType.TOP_100_WEEKLY:
			return {
				title: 'Top 100 Weekly Experiences',
				url: 'https://lightdash.thegang.io/projects/446ea0f3-6baa-4f25-a268-264cb3b99408/dashboards/a83940ba-6286-4052-863a-896e14c7f532/view',
				description:
					'The dashboard offers a summary of key metrics for the top 100 experiences during the past week. It includes rankings for stats such as new entries, biggest winners/losers as well as metrics for top Avg. CCU.',
			};
		case DashboardType.COMPARE_EXPERIENCES:
			return {
				title: 'Compare Experiences',
				url: `https://lightdash.thegang.io/projects/446ea0f3-6baa-4f25-a268-264cb3b99408/dashboards/1238ab8c-d977-4896-af4b-b595141c0831?filters={"dimensions":[{"id":"5dd6fc81-102e-45cc-9f23-93b1f8ee7a9f","target":{"fieldId":"places_canonical_name","fieldName":"canonical_name","tableName":"places"},"values":["${dashboard.experience1.canonical_name}","${dashboard.experience2.canonical_name}"],"disabled":false,"operator":"equals","required":true}],"metrics":[],"tableCalculations":[]}`,
				description:
					"The dashboard offers a more detailed comparison between the two experiences. It includes avg. rank this week, more accurate daily and weekly CCU as well as revenue data for games where it's available.",
			};
		case DashboardType.EXPERIENCE_LOADING_TIMES:
			return {
				title: 'Game Loading Times',
				url: `https://lightdash.thegang.io/projects/a78e7960-86cb-466c-8f79-090697753ed8/dashboards/a51ee723-8e1a-4cd0-a521-d9dca153e961/view?filters={"dimensions":[{"id":"536bcaef-4e4b-475a-9cc1-c43d8d2726fd","target":{"fieldId":"events_loading_time_game","fieldName":"game","tableName":"events_loading_time"},"values":["${dashboard.experienceCanonicalName.replace(' ', '+')}"],"disabled":false,"operator":"equals"}],"metrics":[],"tableCalculations":[]}`,
				description:
					'The loading times dashboard offers more information about the experiences loading times. It includes median values, session numbers as well as info about users leaving during the loading period.',
				disclaimer:
					'This dashboard are only available for a select few experiences at this time.',
			};
		case DashboardType.TAGS_OVERVIEW:
			return {
				title: 'Tags Overview',
				url: 'https://lightdash.thegang.io/projects/446ea0f3-6baa-4f25-a268-264cb3b99408/dashboards/528872a3-c22c-43ad-b261-44022bf2e27d/view',
				description:
					'Key metrics for each selected tag such as their visits, CCU, like percentage, and more.',
			};
		case DashboardType.COMPARE_EXPERIENCE_AND_TAG:
			return {
				title: 'Compare Experience and Tag',
				url: `https://lightdash.thegang.io/projects/446ea0f3-6baa-4f25-a268-264cb3b99408/dashboards/61f2071e-c052-4959-8106-865093c25c4c/view?filters={"dimensions":[{"id":"ebbff6f3-5087-4fbf-9e72-b9a387bc76ef","label":"Game+Name","target":{"fieldId":"game_metrics_canonical_name","fieldName":"canonical_name","tableName":"game_metrics"},"values":["${dashboard.experience.canonical_name}"],"disabled":false,"operator":"equals"},{"id":"99bf1a2c-871d-4830-ad82-1744f2d4b9b3","label":"Tag+Name","target":{"fieldId":"tag_metrics_tag","fieldName":"tag","tableName":"tag_metrics"},"values":["${dashboard.tag.name}"],"disabled":false,"operator":"equals"}],"metrics":[],"tableCalculations":[]}`,
				description:
					'The dashboard offers a more detailed comparison between the experience and the tag. It includes CCU over time, visits over time like data etc.',
			};
	}
};

export interface GameSpecificOverviewInfo {
	type: DashboardType.GAME_SPECIFIC_OVERVIEW;
	placeId: number;
}

export interface ExperienceInsightByTagInfo {
	type: DashboardType.EXPERIENCE_INSIGHT_BY_TAG;
	tagName: string;
}

export interface Top100WeeklyInfo {
	type: DashboardType.TOP_100_WEEKLY;
}

export interface CompareExperiencesInfo {
	type: DashboardType.COMPARE_EXPERIENCES;
	experience1: Experience;
	experience2: Experience;
}

export interface ExperienceLoadingTimesInfo {
	type: DashboardType.EXPERIENCE_LOADING_TIMES;
	experienceCanonicalName: string;
}

export interface TagsOverviewInfo {
	type: DashboardType.TAGS_OVERVIEW;
}

export interface CompareExperiencesAndTagInfo {
	type: DashboardType.COMPARE_EXPERIENCE_AND_TAG;
	experience: Experience;
	tag: PlaceTag | PopulatedPlaceTag;
}

export type DashboardInfoType =
	| GameSpecificOverviewInfo
	| ExperienceInsightByTagInfo
	| Top100WeeklyInfo
	| CompareExperiencesInfo
	| ExperienceLoadingTimesInfo
	| TagsOverviewInfo
	| CompareExperiencesAndTagInfo;
