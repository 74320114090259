import { Experience } from '@/schemas/experience-schema.ts';
import { PlaceTag } from '@/schemas/plage-tag-schema.ts';
import { EventCredential } from '@/schemas/event-credential-schema.ts';

class SearchService {
	experience(place: Experience, searchTerm: string): boolean {
		const loweredSearchTerm = searchTerm.toLowerCase();
		return (
			place.name.toLowerCase().includes(loweredSearchTerm) ||
			place.canonical_name.toLowerCase().includes(loweredSearchTerm) ||
			place.universe_id
				.toString()
				.toLowerCase()
				.includes(loweredSearchTerm) ||
			place.place_id
				.toString()
				.toLowerCase()
				.includes(loweredSearchTerm) ||
			place.creator.name.toLowerCase().includes(loweredSearchTerm)
		);
	}

	tag(tag: PlaceTag, searchTerm: string): boolean {
		const loweredSearchTerm = searchTerm.toLowerCase();
		return tag.name.toLowerCase().includes(loweredSearchTerm);
	}

	eventCredential(credential: EventCredential, searchTerm: string) {
		const loweredSearchTerm = searchTerm.toLowerCase();
		return (
			credential.comment.toLowerCase().includes(loweredSearchTerm) ||
			credential.game_key.toLowerCase().includes(loweredSearchTerm) ||
			credential.place_id.toString() === searchTerm
		);
	}
}

const searchService = new SearchService();
export default searchService;
