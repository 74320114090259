import ReactDOM, { Root } from 'react-dom/client';
import { ActionsMenuProps } from '@components/core/actions-menu/actions-menu.tsx';
import { CSSProperties, LegacyRef, ReactNode } from 'react';
import ClassString from '@utils/class-string.ts';

const defaultMenuWidth = '10rem';

class ActionsMenuService {
	private actionsMenuContainer: Root | undefined;
	private menu: ReactNode | undefined;

	constructor() {
		this.setActionMenuContainer();
	}

	private setActionMenuContainer() {
		if (this.actionsMenuContainer) {
			return;
		}

		const actionsMenuWrapper = document.getElementById(
			'actions-menu-container'
		);
		if (actionsMenuWrapper) {
			this.actionsMenuContainer = ReactDOM.createRoot(actionsMenuWrapper);
		}
	}

	open(
		props: ActionsMenuProps,
		ref: LegacyRef<HTMLDivElement> | undefined,
		boundingReact: DOMRect | undefined,
		closeMenu: () => unknown,
		width?: string
	) {
		this.setActionMenuContainer();

		if (!this.actionsMenuContainer) {
			return;
		}

		const menuClasses = ClassString({
			static: 'actions-menu-menu shadow-xl h-0 w-40 bg-white dark:bg-gray-525 absolute overflow-hidden bottom outline outline-1 outline-gray-600 dark:outline-gray-500 rounded-md animate-actions-menu ease-transition-curve',
			dynamic: {
				'actions-menu-menu-visible': true,
			},
		});

		this.menu = (
			<div
				className={menuClasses}
				ref={ref}
				style={
					{
						top: boundingReact?.bottom
							? `calc(${boundingReact.bottom}px - 0.5rem`
							: 0,
						left: boundingReact?.right
							? `calc(${boundingReact.right}px - ${width ?? defaultMenuWidth}`
							: width ?? defaultMenuWidth,
						'--button-count': props.actions.length,
						width: width ?? defaultMenuWidth,
					} as CSSProperties
				}
			>
				{props.actions.map((action) => (
					<div
						onClick={() => {
							closeMenu();
							action.onClick();
						}}
						key={action.title}
						className={`h-button px-5 flex items-center cursor-pointer hover:text-white hover:bg-blue-500 ${props.actions.length === 1 ? 'justify-center' : 'justify-start'} [&:not(:last-child)]:border-b`}
					>
						{action.title}
					</div>
				))}
			</div>
		);

		this.actionsMenuContainer.render(this.menu);
	}

	close() {
		if (!this.actionsMenuContainer) {
			return;
		}

		this.actionsMenuContainer.render(<></>);
	}
}

const actionsMenuService = new ActionsMenuService();
export default actionsMenuService;
