import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import ViewPanelSizeEnum from '@components/view/view-panel-size-enum.ts';
import LoadingIndicator from '@components/core/loading-indicator/loading-indicator.tsx';
import ClassString from '@utils/class-string.ts';
import ExpansionPanel from '@components/core/expansion-panel/expansion-panel.tsx';

interface ViewPanelProps {
	children: React.ReactNode;
	title?: string;
	loading?: boolean;
	options?: {
		centerContent?: boolean;
		size?: ViewPanelSizeEnum;
		hideOverflow?: boolean;
		container?: boolean;
		fullHeight?: boolean;
		fullWidth?: boolean;
		noPadding?: boolean;
		noOutline?: boolean;
	};
	className?: string;
	expansionPanel?: {
		title: string;
		startExpanded?: boolean;
	};
}

const ViewPanel = observer(function ViewPanel(props: ViewPanelProps) {
	const [expended, setExpanded] = useState(
		!!props.expansionPanel?.startExpanded
	);

	const className = ClassString({
		static: 'p-4 rounded-lg shadow-md w-full bg-white dark:bg-gray-550 outline outline-1 outline-gray-300 dark:outline-gray-500',
		dynamic: {
			'flex flex-col justify-even items-center':
				props.options?.centerContent,
			'overflow-hidden': props.options?.hideOverflow,
			'lg:w-panel-1/2': props.options?.size === ViewPanelSizeEnum.HALF,
			'lg:w-panel-1/3': props.options?.size === ViewPanelSizeEnum.THIRD,
			'bg-transparent p-0 outline-0 rounded-none shadow-none':
				props.options?.container,
			relative: !!props.expansionPanel,
			'!h-full': props.options?.fullHeight,
			'!p-0': props.options?.noPadding,
			'!outline-0': props.options?.noOutline,
			'!w-full': props.options?.fullWidth,
		},
		custom: props.className,
	});

	return (
		<div className={className}>
			{!props.loading && !props.expansionPanel && (
				<>
					{!!props.title && (
						<div
							className={
								'text-2xl mb-4 flex justify-center items-center'
							}
						>
							{props.title}
						</div>
					)}
					{props.children}
				</>
			)}

			{!props.loading && !!props.expansionPanel && (
				<>
					<div
						className={
							'absolute w-full h-20 cursor-pointer top-0 left-0'
						}
						onClick={() => {
							setExpanded(!expended);
						}}
					></div>

					<ExpansionPanel
						title={props.expansionPanel.title}
						viewPanel
						overflowVisible
						state={expended ? 'expanded' : 'closed'}
						titleOptions={{
							padding: props.options?.noPadding,
							titleHeight: '62px',
						}}
					>
						{props.children}
					</ExpansionPanel>
				</>
			)}

			{props.loading && (
				<div className={'flex justify-center items-center'}>
					<LoadingIndicator />
				</div>
			)}
		</div>
	);
});

export default ViewPanel;
