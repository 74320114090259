import { observer } from 'mobx-react-lite';
import View from '@components/view/view.tsx';
import ViewPanel from '@components/view/view-panel.tsx';
import experienceStore from '@store/experience-store.ts';
import ExperienceSelector from '@components/core/experience-selector/experience-selector.tsx';
import { useEffect, useState } from 'react';
import { Experience } from '@/schemas/experience-schema.ts';
import PlaceStats from '@components/data/place-stats/place-stats.tsx';
import placeStatsStore, {
	PlaceStatGranularity,
	PlaceStatType,
} from '@store/place-stats-store.ts';
import LightdashDashboards from '@components/data/lightdash-dashboards/lightdash-dashboards.tsx';
import DashboardType from '@components/data/lightdash-dashboards/dashboard-type.ts';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import DataComparisonRow from '@components/data/data-comparison-row/data-comparison-row.tsx';
import formatNumberWithSuffix from '@utils/format-number-with-suffix.ts';
import Button from '@components/core/button/button.tsx';
import CopyToClipboard from '@utils/copy-to-clipboard.ts';
import toastStore from '@store/toast-store.ts';
import { ToastType } from '@components/service/toast/toast-enums.ts';
import postHogService from '@services/posthog-service.ts';
import { PostHogEventType } from '@/enums/posthog-event.ts';

const formatCCU = (ccu: number, approx?: boolean): string => {
	return ccu ? `${approx ? '~' : ''}${ccu.toFixed(2)}` : '?';
};

const getCUUDirection = (ccu1: number, ccu2: number) => {
	if (!ccu1 && !ccu2) {
		return 'none';
	}

	if (ccu1 > ccu2) {
		return 'left';
	} else {
		return 'right';
	}
};

const ExperienceComparison = observer(function ExperienceComparison() {
	const [experience1, setExperience1] = useState<Experience | undefined>();
	const [experience1DailyCCU, setExperience1DailyCCU] = useState(0);
	const [experience1WeeklyCCU, setExperience1WeeklyCCU] = useState(0);
	const [experience1MonthlyCCU, setExperience1MonthlyCCU] = useState(0);
	const [experience2, setExperience2] = useState<Experience | undefined>();
	const [experience2DailyCCU, setExperience2DailyCCU] = useState(0);
	const [experience2WeeklyCCU, setExperience2WeeklyCCU] = useState(0);
	const [experience2MonthlyCCU, setExperience2MonthlyCCU] = useState(0);

	const experiencesSelected = !!experience1 && !!experience2;

	const loading = !experienceStore.fullExperiences.length;

	useEffect(() => {
		const getDailyCCU = async () => {
			if (experience1 && experience2) {
				const dailyCCU = await placeStatsStore.getDailyCCU([
					experience1.place_id,
					experience2.place_id,
				]);
				setExperience1DailyCCU(dailyCCU[experience1.place_id] ?? 0);
				setExperience2DailyCCU(dailyCCU[experience2.place_id] ?? 0);
			} else {
				setExperience1DailyCCU(0);
				setExperience2DailyCCU(0);
			}
		};

		void getDailyCCU();
	}, [experience1, experience2]);

	useEffect(() => {
		const getMonthlyCCU = async () => {
			if (experience1 && experience2) {
				const weeklyCCU = await placeStatsStore.getWeeklyCCU([
					experience1.place_id,
					experience2.place_id,
				]);
				setExperience1WeeklyCCU(weeklyCCU[experience1.place_id] ?? 0);
				setExperience2WeeklyCCU(weeklyCCU[experience2.place_id] ?? 0);
			} else {
				setExperience1WeeklyCCU(0);
				setExperience2WeeklyCCU(0);
			}
		};

		void getMonthlyCCU();
	}, [experience1, experience2]);

	useEffect(() => {
		const getMonthlyCCU = async () => {
			if (experience1 && experience2) {
				const monthlyCCU = await placeStatsStore.getMonthlyCCU([
					experience1.place_id,
					experience2.place_id,
				]);
				setExperience1MonthlyCCU(monthlyCCU[experience1.place_id] ?? 0);
				setExperience2MonthlyCCU(monthlyCCU[experience2.place_id] ?? 0);
			} else {
				setExperience1MonthlyCCU(0);
				setExperience2MonthlyCCU(0);
			}
		};

		void getMonthlyCCU();
	}, [experience1, experience2]);

	useEffect(() => {
		if (experience1 && experience2) {
			postHogService.capture({
				type: PostHogEventType.CompareExperiences,
				experience_1: experience1.canonical_name,
				experience_2: experience2.canonical_name,
			});
		}
	}, [experience1, experience2]);

	const copyLink = () => {
		CopyToClipboard(window.location.href);
		toastStore.emit(
			'Comparison link has been copied to your clipboard.',
			ToastType.INFO
		);
	};

	return (
		<View
			viewInfo={{
				title: 'Compare Experiences',
				icon: IconEnum.COMPARE,
				breadCrumbs: [{ title: 'Compare' }],
			}}
			hideHeader
		>
			<ViewPanel loading={loading}>
				{!loading && (
					<div className={'w-full flex flex-wrap justify-evenly'}>
						<div
							className={
								'p-6 flex flex-col justify-center items-center max-w-96 lg:h-72 m-2'
							}
						>
							<div>
								Select two experiences to display comparative
								stats and dashboard links.
							</div>

							<div className={'mt-2'}>
								You can also bookmark a comparison to quickly
								return to it or share the comparison with
								someone else.
							</div>

							<div className={'mt-6'}>
								<Button
									title={'Share'}
									icon={{
										icon: IconEnum.SHARE,
										placement: 'left',
									}}
									onClick={copyLink}
								/>
							</div>
						</div>

						<ExperienceSelector
							experience={experience1}
							setExperience={setExperience1}
							index={1}
						/>

						<ExperienceSelector
							experience={experience2}
							setExperience={setExperience2}
							index={2}
						/>
					</div>
				)}
			</ViewPanel>

			{experiencesSelected && (
				<>
					<ViewPanel>
						<LightdashDashboards
							dashboards={[
								{
									type: DashboardType.COMPARE_EXPERIENCES,
									experience1,
									experience2,
								},
							]}
						/>
					</ViewPanel>

					<ViewPanel>
						<div className={'flex flex-col justify-center'}>
							<DataComparisonRow
								label={'Name'}
								dataLeft={experience1?.canonical_name}
								dataRight={experience2?.canonical_name}
								borderBottom
							/>

							<DataComparisonRow
								label={'Player Count'}
								dataLeft={`${experience1?.players_online}`}
								dataRight={`${experience2?.players_online}`}
								dataDifference={{
									direction:
										experience1.players_online >
										experience2.players_online
											? 'left'
											: 'right',
									data:
										experience1.players_online >
										experience2.players_online
											? `${experience1.players_online - experience2.players_online}`
											: `${experience2.players_online - experience1.players_online}`,
								}}
								borderBottom
							/>

							<DataComparisonRow
								label={'Like %'}
								dataLeft={`${(experience1?.like_percentage * 100).toFixed(2)}%`}
								dataRight={`${(experience2?.like_percentage * 100).toFixed(2)}%`}
								dataDifference={{
									direction:
										experience1?.like_percentage >
										experience2?.like_percentage
											? 'left'
											: 'right',
									data:
										experience1?.like_percentage >
										experience2?.like_percentage
											? `${((experience1?.like_percentage - experience2?.like_percentage) * 100).toFixed(2)}%`
											: `${((experience2?.like_percentage - experience1?.like_percentage) * 100).toFixed(2)}%`,
								}}
								borderBottom
							/>

							<DataComparisonRow
								label={'Visits'}
								dataLeft={formatNumberWithSuffix(
									experience1.total_visits
								)}
								dataRight={formatNumberWithSuffix(
									experience2.total_visits
								)}
								dataDifference={{
									direction:
										experience1.total_visits >
										experience2.total_visits
											? 'left'
											: 'right',
									data:
										experience1.total_visits >
										experience2.total_visits
											? formatNumberWithSuffix(
													experience1.total_visits -
														experience2.total_visits
												)
											: formatNumberWithSuffix(
													experience2.total_visits -
														experience1.total_visits
												),
								}}
								borderBottom
							/>

							<div title={'Last completed day.'}>
								<DataComparisonRow
									label={'Daily CCU'}
									dataLeft={formatCCU(
										experience1DailyCCU,
										true
									)}
									dataRight={formatCCU(
										experience2DailyCCU,
										true
									)}
									dataDifference={{
										direction: getCUUDirection(
											experience1DailyCCU,
											experience2DailyCCU
										),
										data:
											experience1DailyCCU >
											experience2DailyCCU
												? formatCCU(
														experience1DailyCCU -
															experience2DailyCCU
													)
												: formatCCU(
														experience2DailyCCU -
															experience1DailyCCU
													),
									}}
									borderBottom
								/>
							</div>

							<div title={'Last completed week.'}>
								<DataComparisonRow
									label={'Weekly CCU'}
									dataLeft={formatCCU(
										experience1WeeklyCCU,
										true
									)}
									dataRight={formatCCU(
										experience2WeeklyCCU,
										true
									)}
									dataDifference={{
										direction: getCUUDirection(
											experience1WeeklyCCU,
											experience2WeeklyCCU
										),
										data:
											experience1WeeklyCCU >
											experience2WeeklyCCU
												? formatCCU(
														experience1WeeklyCCU -
															experience2WeeklyCCU
													)
												: formatCCU(
														experience2WeeklyCCU -
															experience1WeeklyCCU
													),
									}}
									borderBottom
								/>
							</div>

							<div title={'Last completed month.'}>
								<DataComparisonRow
									label={'Monthly CCU'}
									dataLeft={formatCCU(
										experience1MonthlyCCU,
										true
									)}
									dataRight={formatCCU(
										experience2MonthlyCCU,
										true
									)}
									dataDifference={{
										direction: getCUUDirection(
											experience1MonthlyCCU,
											experience2MonthlyCCU
										),
										data:
											experience1MonthlyCCU >
											experience2MonthlyCCU
												? formatCCU(
														experience1MonthlyCCU -
															experience2MonthlyCCU
													)
												: formatCCU(
														experience2MonthlyCCU -
															experience1MonthlyCCU
													),
									}}
								/>
							</div>
						</div>
					</ViewPanel>

					<ViewPanel>
						<PlaceStats
							places={[experience1, experience2]}
							statType={PlaceStatType.CCUS}
							title={'Concurrent Users'}
							options={{
								showLegend: true,
								defaultGranularity: PlaceStatGranularity.WEEK,
								thickerLines: true,
							}}
						/>
					</ViewPanel>

					<ViewPanel>
						<PlaceStats
							places={[experience1, experience2]}
							statType={PlaceStatType.VISITS}
							title={'Visits'}
							options={{
								showLegend: true,
								defaultGranularity: PlaceStatGranularity.WEEK,
								thickerLines: true,
							}}
						/>
					</ViewPanel>

					<ViewPanel>
						<PlaceStats
							places={[experience1, experience2]}
							statType={PlaceStatType.RATING}
							title={'Like Rate'}
							options={{
								showLegend: true,
								yPercentage: true,
								defaultGranularity: PlaceStatGranularity.WEEK,
								thickerLines: true,
							}}
						/>
					</ViewPanel>
				</>
			)}
		</View>
	);
});

export default ExperienceComparison;
