import { z } from 'zod';

interface ExperienceRankingResponse {
	start: string; // Date string
	end: string; // Date string
	name: string;
	y: number;
}

export const ExperienceRatingSchema: z.ZodType<ExperienceRankingResponse> =
	z.object({
		start: z.string(),
		end: z.string(),
		name: z.string(),
		y: z.number(),
	});

export const ExperienceRatingListResponseSchema: z.ZodType<
	ExperienceRankingResponse[]
> = z.array(ExperienceRatingSchema);

export interface ExperienceRanking {
	start: Date;
	end: Date;
	name: string;
	y: number;
}
