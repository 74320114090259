import { useEffect, useRef } from 'react';

export default function useOnClickOutside<T extends HTMLElement>(
	onClickOutside: (event: Event) => unknown
) {
	const ref = useRef<T>(null);

	const handleHideDropdown = (event: KeyboardEvent) => {
		if (event.key === 'Escape') {
			onClickOutside(event);
		}
	};

	const handleClickOutside = (event: Event) => {
		if (ref.current && !ref.current?.contains(event.target as Node)) {
			onClickOutside(event);
		}
	};

	useEffect(() => {
		document.addEventListener('keydown', handleHideDropdown, true);
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('keydown', handleHideDropdown, true);
			document.removeEventListener('click', handleClickOutside, true);
		};
	});

	return { ref };
}
