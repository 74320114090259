import { useState } from 'react';
import ClassString from '@utils/class-string.ts';
import { observer } from 'mobx-react-lite';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import Icon from '@components/core/icon/icon.tsx';

interface SlideToggleProps {
	initialState?: boolean;
	onToggle?: (state: boolean) => void;
	className?: string;
	icons?: {
		off: IconEnum;
		on: IconEnum;
	};
}

const SlideToggle = observer(function SlideToggle(props: SlideToggleProps) {
	const [isOn, setIsOn] = useState(props.initialState);

	const toggle = () => {
		setIsOn(!isOn);
		if (props.onToggle) {
			props.onToggle(!isOn);
		}
	};

	return (
		<div
			className={ClassString({
				static: 'w-14 h-4 flex items-center bg-gray-300 dark:bg-gray-500 rounded-full p-1 cursor-pointer',
				dynamic: {},
				custom: props.className,
			})}
			onClick={toggle}
		>
			<div
				className={ClassString({
					static: 'dark:bg-gray-600 bg-white border border-gray-600 dark:border-gray-500 w-10 h-10 rounded-full shadow-md transform transition-transform duration-300 flex justify-center items-center',
					dynamic: {
						'translate-x-4': isOn,
						'-translate-x-2': !isOn,
					},
				})}
			>
				{!!props.icons && (
					<Icon
						icon={isOn ? props.icons.on : props.icons.off}
						size={isOn ? '2em' : '1.8em'}
					/>
				)}
			</div>
		</div>
	);
});

export default SlideToggle;
