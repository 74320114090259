import { observer } from 'mobx-react-lite';
import TGSTable, { CustomColDef } from '@components/core/table/table.tsx';
import { PlaceTag } from '@/schemas/plage-tag-schema.ts';
import ActionsMenu from '@components/core/actions-menu/actions-menu.tsx';
import modalService from '@services/modal-service.tsx';
import ModalBase from '@components/service/modal/modal-base.tsx';
import placeTagStore from '@store/place-tag-store.ts';
import toastStore from '@store/toast-store.ts';
import { ToastType } from '@components/service/toast/toast-enums.ts';
import { BUTTON_STYLE } from '@components/core/button/button-style-enum.ts';
import PlaceTagsRenameModal from '@views/place-tags/view-components/place-tags-rename-modal.tsx';
import { CustomCellRendererProps } from 'ag-grid-react';
import NavigationButton from '@components/navigation/navigation-button/navigation-button.tsx';
import { NavigationButtonEnum } from '@components/navigation/navigation-button/navigation-button-enum.ts';

interface ExperienceTagListProps {
	tags: PlaceTag[];
}

const ColumnDefs: CustomColDef<PlaceTag>[] = [
	{
		headerName: 'Name',
		field: 'name',
		cellRenderer: (params: CustomCellRendererProps) => {
			const tag = params.node.data as PlaceTag;
			return (
				<NavigationButton
					path={`/tags/${tag.id}`}
					direction={NavigationButtonEnum.FORWARD}
					title={tag.name}
				/>
			);
		},
		cellStyle: {
			display: 'flex',
			alignItems: 'center',
			paddingLeft: '1.5em',
		},
		resizable: false,
		minWidth: 250,
		sort: 'asc',
		context: {
			priority: 1,
		},
	},
	{
		headerName: 'Experiences',
		field: 'place_ids',
		valueFormatter: (params) => {
			const placeIds = params.value as string[];
			return placeIds.length.toString();
		},
		comparator: (a: string[], b: string[]) => {
			return a.length - b.length;
		},
		cellStyle: {
			display: 'flex',
			alignItems: 'center',
		},
		resizable: false,
		minWidth: 120,
		maxWidth: 200,
		context: {
			priority: 2,
		},
	},
];

const ExperienceTagList = observer(function ExperienceTagList(
	props: ExperienceTagListProps
) {
	return (
		<TGSTable
			columnDefs={[
				...ColumnDefs,
				{
					cellRenderer: (params: CustomCellRendererProps) => {
						const tag = params.node.data as PlaceTag;
						return (
							<ActionsMenu
								actions={[
									{
										title: 'Delete Tag',
										onClick: () => {
											modalService.open({
												content: (
													<ModalBase
														title={`Delete "${tag.name}"`}
														content={{
															children: (
																<>
																	<div
																		className={
																			'mb-3'
																		}
																	>
																		{`Are you sure you want to delete the tag "${tag.name}"?`}
																	</div>
																	<div
																		className={
																			'text-xs italic'
																		}
																	>
																		{
																			'This action is irreversible, and the tag cannot be restored once deleted.'
																		}
																	</div>
																</>
															),
														}}
														onConfirm={async () => {
															await placeTagStore.deletePlaceTag(
																tag.id
															);
															toastStore.emit(
																`Successfully deleted tag ${tag.name}`,
																ToastType.CONFIRM
															);
														}}
														options={{
															confirmButtonTitle:
																'Delete Tag',
															confirmButtonStyle:
																BUTTON_STYLE.WARNING,
														}}
													/>
												),
											});
										},
									},
									{
										title: 'Rename Tag',
										onClick: () => {
											modalService.open({
												content: (
													<PlaceTagsRenameModal
														placeTag={tag}
													/>
												),
											});
										},
									},
								]}
								width={'9rem'}
							/>
						);
					},
					minWidth: 70,
					maxWidth: 70,
					resizable: false,
					sortable: false,
					cellStyle: {
						display: 'flex',
						alignItems: 'center',
					},
					context: {
						priority: 1,
					},
				},
			]}
			rowData={props.tags}
			tableName={'place-tags-table'}
			rowHeight={50}
		/>
	);
});

export default ExperienceTagList;
