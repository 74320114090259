import placeTagStore from '@store/place-tag-store.ts';
import experienceStore from '@store/experience-store.ts';
import platformStatsStore from '@store/platform-stats-store.ts';

export function OverviewLoader() {
	// Due to the size of the place list, only load once per app init
	if (!experienceStore.initialized) {
		void experienceStore.listSlimExperiences();
	}

	void placeTagStore.listPlaceTags();
	void platformStatsStore.loadPlatformCCU();

	return 'ok';
}
