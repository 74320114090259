import Nav from '@components/navigation/nav/nav.tsx';
import Toast from '@components/service/toast/toast.tsx';
import { Outlet } from 'react-router-dom';
import { Suspense, useEffect } from 'react';
import NavSidebar from '@components/navigation/nav/nav-sidebar.tsx';
import { observer } from 'mobx-react-lite';
import ConditionalStrictMode from '@/conditional-strict-mode.tsx';
import usePageViewTracker from '@hooks/use-page-view-tracker.tsx';

const App = observer(function App() {
	usePageViewTracker();

	// Service worker update checker
	useEffect(() => {
		if ('serviceWorker' in navigator) {
			// Manual check for updates every 5 minutes
			const checkForUpdates = () => {
				void navigator.serviceWorker
					.getRegistration()
					.then((registration) => {
						if (registration) {
							void registration.update();
						}
					});
			};
			const intervalId = setInterval(checkForUpdates, 5 * 60 * 1000);

			return () => {
				clearInterval(intervalId);
			};
		}
	}, []);

	return (
		<ConditionalStrictMode>
			<NavSidebar />
			<Nav />
			<Suspense>
				<Outlet />
			</Suspense>
			<Toast />
			<div id={'modal-container'}></div>
			<div id={'actions-menu-container'}></div>
		</ConditionalStrictMode>
	);
});

export default App;
