import { observer } from 'mobx-react-lite';
import { StrictMode, ReactNode } from 'react';

/* ----------------------------
For an unknown reason strict mode was causing issues in production,
causing an additional re-render in non-development environments.
Conditionally add the strict mode wrapper instead.
------------------------------*/

interface ConditionalStrictModeProps {
	children: ReactNode;
}

const ConditionalStrictMode = observer(function ConditionalStrictMode(
	props: ConditionalStrictModeProps
) {
	const useStrictMode = import.meta.env.VITE_USE_STRICT_MODE === 'true';

	return (
		<>
			{useStrictMode && <StrictMode>{props.children}</StrictMode>}

			{!useStrictMode && <>{props.children}</>}
		</>
	);
});

export default ConditionalStrictMode;
