import placeTagStore from '@store/place-tag-store.ts';
import experienceStore from '@store/experience-store.ts';

export function PlacesLoader() {
	// Due to the size of the place list, only load once per app init
	if (!experienceStore.initialized) {
		void experienceStore.listSlimExperiences();
	}

	void placeTagStore.listPlaceTags();
	return 'ok';
}
