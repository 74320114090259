import tailwindConfig from '../tailwind.config.ts';
import { extendTailwindMerge } from 'tailwind-merge';

const getTailwindCustomClasses = (
	cssClass: string,
	prefix: string
): string[] => {
	if (tailwindConfig.theme?.extend) {
		for (const classGroup of Object.entries(tailwindConfig.theme.extend)) {
			const newClassGroupKey: string = classGroup[0];

			if (newClassGroupKey !== cssClass) {
				continue;
			}

			return Object.keys(classGroup[1] as Record<string, unknown>).map(
				(newClass) => `${prefix}-${newClass}`
			);
		}
	}

	return [];
};

const initCustomTailwindMerge = () => {
	return extendTailwindMerge({
		extend: {
			classGroups: {
				w: getTailwindCustomClasses('width', 'w'),
				h: getTailwindCustomClasses('height', 'h'),
				z: getTailwindCustomClasses('zIndex', 'z'),
			},
		},
	});
};

export default initCustomTailwindMerge;
